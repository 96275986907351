import React, { useEffect } from 'react'
import { Avatar, Chat, Divider } from '@fluentui/react-northstar'
import { AcceptIcon } from '@fluentui/react-icons-northstar'
import moment from 'moment';
import { DataServices } from "../../shared/data-services/DataServices";
import ImageLoader from './ImageLoader';
import { Persona, PersonaSize, PersonaPresence } from "office-ui-fabric-react/lib/Persona";
import Linkify from './Linkify';

const ChatExample = (props) => {

    const File = ({ file }) => {
        return (
            <ImageLoader
                width={318}
                height={196}
                file={file}
                borderRadius={2}
            />
        )
    };

    const onRenderCell = (comment, i, arr) => {
        var previousComment = null;
        var nextComment = null;
        var signedUrl = props.files?.find(f => f.key == comment.file)?.signedUrl;
        if (!signedUrl) signedUrl = comment.signedUrl;

        if (i >= 1) {
            previousComment = arr[i - 1];
        }
        if (i < arr.length) {
            nextComment = arr[i + 1];
        }

        const email = comment.contact?.email ? comment.contact.email : '';
        const fileKey = comment.file;

        var details = null;
        if (comment.type == 'create-card')
            details = 'hat diese Card erstellt';
        else if (comment.type == 'update-card')
            details = 'hat den Titel oder die Beschreibung geändert';
        else if (comment.type == 'plan-card')
            details = 'hat das Datum geändert';
        else if (comment.type == 'assign-card')
            details = 'hat die Card zugewiesen';
        else if (comment.type == 'close-card')
            details = 'hat die Card als erledigt markiert';
        else if (comment.type == 'open-card')
            details = 'hat die Card als offen markiert';

        const mine = props.profile.id === comment.createdBy;


        const avatar = {
            image: DataServices.ContactsService.getAvatar(comment.createdBy, null),
            /*             status: {
                            color: 'green',
                            icon: <AcceptIcon />,
                        }, */
        }

        const persona = {
            text: comment.name,
            size: PersonaSize.size32,
            imageUrl: DataServices.ContactsService.getAvatar(comment.createdBy, null),
            styles: {
                root: {
                    display: ''
                }
            }
        }

        const sameDay = previousComment && moment(previousComment.createdAt).format('L') === moment(comment.createdAt).format('L');
        const samePerson = previousComment && previousComment.createdBy == comment.createdBy;

        const sameTimeDate = previousComment && moment(previousComment.createdAt).add(5, 'minutes');
        const sameTime = previousComment && moment(comment.createdAt).isBetween(moment(previousComment.createdAt), sameTimeDate);

        const divider = {
            children: <Divider content={moment(comment.createdAt).format('L')} important />,
            key: 'divider-id-' + i,
        };
        const myMessage = {
            gutter: <Persona {...persona} />,
            message: (
                <Chat.Message
                    styles={{
                        background: comment.type ? 'transparent' : '#d4ebf2',
                    }}
                    content={<div>
                        {fileKey ? <File file={signedUrl} /> : null}
                        <Linkify value={comment.content} />
                        {details ? details : null}
                    </div>}
                    readStatus={!details ? { content: <AcceptIcon styles={{ color: 'green' }} disabled={!comment.createdAt} size="smaller" />, title: 'Sent' } : null}
                    author={comment.contact?.name}
                    timestamp={comment.createdAt ? moment(comment.createdAt).format('LT') : 'offline'}
                    mine
                //details={details}
                />),
            attached: samePerson && sameTime && !comment.type && !previousComment.type ? true : 'top',
            contentPosition: 'end',
            key: comment.id
        };
        const message = {
            gutter: <Persona {...persona} />,
            message: (
                <Chat.Message
                    styles={{
                        background: comment.type ? 'transparent' : 'WHITE',
                    }}
                    content={<div>
                        {fileKey ? <File file={signedUrl} /> : null}
                        <Linkify value={comment.content} />
                        {details ? details : null}
                    </div>}
                    author={comment.contact?.name + ' ' + email}
                    //details={details}
                    timestamp={comment.createdAt ? moment(comment.createdAt).format('LT') : ''}
                />),
            attached: samePerson && sameTime && !comment.type && !previousComment.type ? true : 'top',
            key: comment.id
        };

        if (sameDay)
            return ([mine ? myMessage : message]);
        else
            return ([divider, mine ? myMessage : message]);

    };

    const items = props.items.flatMap(onRenderCell);

    /*     return <div
            style={{
                height: 300,
                overflow: 'scroll'
            }}
        >
                <Chat 
                items={items}
                styles={{
                    minHeight: '100%',
                  }} />
        </div> */
    return <Chat items={items} />
}

export default ChatExample
