import { useBoolean } from '@uifabric/react-hooks';
//import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl.js';
import { ActionButton, DefaultButton, CommandBarButton, IconButton } from 'office-ui-fabric-react/lib/Button';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHistory, useParams } from 'react-router-dom';
//import ListData from "./GraphQL/listData";
import { DataServices } from "../../shared/data-services/DataServices";
import IssueCard from "./PreviewCard";
import './map-pupup.css';
import './map-floors.css';
import issueImage from './marker_issue.png';
import selectedImage from './marker_selected.png';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import center from '@turf/center'
import { Link, Text } from '@fluentui/react';
import { TextField, MaskedTextField } from '@fluentui/react/lib/TextField';


var StaticMode = require('@mapbox/mapbox-gl-draw-static-mode');


mapboxgl.accessToken = 'pk.eyJ1IjoibWljaGFlbGJyZWl0ZW5iZXJnZXIiLCJhIjoiY2trY2JucjgwMGZuaTJub2M4a3U2eGlyeCJ9.djsvp7Hf2Ed7SdLn0nT4jA';

let featureCollection = null;
let fcDraw = null;
let mapContainer = null;
let bounds = null;
let map = null;
let draw = null;
let popup = null;
let items = [];
let initLng = 10.7;
let initLat = 50.5;
let initZoom = 5;
const maxZoom = 19;
const flyToZoom = 19;


// floors
let visibleFloorId;
var floorButtonControl;
var floorIdForSelection;
var floorIds = [];


class ButtonControl {

    constructor(optionArray) {
        this._options = optionArray.map(options => ({
            className: options.className || '',
            title: options.title || '',
            eventHandler: options.eventHandler
        }));
    }

    onAdd(map) {
        const me = this;

        me._map = map;

        me._container = document.createElement('div');
        me._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';

        me._buttons = me._options.map(options => {
            const button = document.createElement('button'),
                icon = document.createElement('span'),
                { className, title, eventHandler } = options;

            button.className = className;
            button.type = 'button';
            button.title = title;
            button.setAttribute('aria-label', title);
            button.onclick = eventHandler;

            icon.className = 'mapboxgl-ctrl-icon';
            icon.setAttribute('aria-hidden', true);
            button.appendChild(icon);

            me._container.appendChild(button);

            return button;
        });

        return me._container;
    }

    onRemove() {
        const me = this;

        me._container.parentNode.removeChild(me._container);
        me._map = undefined;
    }

}



const Map = (props) => {

    const history = useHistory();
    const initSearch = new URLSearchParams(history.location.search).get("search");
    //const [searchParam, setSearchParam] = useState(initSearch ? initSearch : '');
    const [lng, setLng] = useState(10.7);
    const [lat, setLat] = useState(50.5);
    const [zoom, setZoom] = useState(4.5);
    const [contacts, setContacts] = useState(null);
    //const [items, setItems] = useState([]);
    const [hoverId, setHoverId] = useState(null);
    const [activityId, setActivityId] = useState(null);
    const [popupVisible, setPopupVisible] = useState(false);
    const [activity, setActivity] = useState(null);
    const [location, setLocation] = useState(null);
    const [locationUpdate, setLocationUpdate] = useState(false);
    const [showJson, setShowJson] = useState(false);
    const [json, setJson] = useState(null);
    const [padding, setPadding] = useState({
        top: 100,
        bottom: 100,
        left: props.fullscreen ? 100 : 500,
        right: 100
    })

    let { id } = useParams();
    let search = initSearch ? initSearch : '';

    function selectFloor(floorId) {
        visibleFloorId = floorId;
        if (!isNaN(floorId)) {
            for (const button of document.getElementsByClassName('mapboxgl-ctrl-layer-active')) {
                button.classList.remove('mapboxgl-ctrl-layer-active');
            }
            for (const button of document.getElementsByClassName('mapboxgl-ctrl-floor-active')) {
                button.classList.remove('mapboxgl-ctrl-floor-active');
            }
            for (const button of document.getElementsByClassName(`mapboxgl-ctrl-floor-${floorId}`)) {
                button.classList.add('mapboxgl-ctrl-floor-active');
            }
            updateFilter();
        } else {
            initialFilter();
        }
    }

    const initialFilter = () => {
        map.setFilter('outline', null);
        map.setFilter('outline1', null);
        map.setFilter('points',
            ['get', 'visible']
        );
        map.setFilter('selected',
            ['get', 'visible']
        );
    }

    const updateFilter = () => {

        const floorExpression = ["any",
            ['==', ["to-number", ['get', 'floor_id']], visibleFloorId],
            ['==', ["to-number", ['get', 'level', ['get', 'reltags', ['at', 0, ['get', 'relations']]]]], visibleFloorId],

        ];

        const pointsExpression = ["all",
            ["case",
                ['has', 'floor_id'], ['==', ['get', 'floor_id'], visibleFloorId],
                true
            ],
            ['get', 'visible']
        ];

        map.setFilter('outline', floorExpression);
        map.setFilter('outline1', floorExpression);
        map.setFilter('points', pointsExpression);
        map.setFilter('selected', pointsExpression);
    }


    const toUrl = (itemId) => {
        history.push({
            pathname: itemId,
            search: history.location.search ? history.location.search : ''
        })
    }

    useEffect(async () => {

        setPadding({
            top: 100,
            bottom: 100,
            left: props.fullscreen ? 100 : 500,
            right: 100
        })

        featureCollection = {
            type: "FeatureCollection",
            features: []
        };
        fcDraw = {
            type: "FeatureCollection",
            features: []
        };

        bounds = new mapboxgl.LngLatBounds();

        map = new mapboxgl.Map({
            container: mapContainer,
            //style: 'mapbox://styles/michaelbreitenberger/cjwkba3ga13u71cqtveob06l2',
            style: 'mapbox://styles/mapbox/light-v10',
            center: [initLng, initLat],
            zoom: initZoom
        });

        var modes = MapboxDraw.modes;
        modes.static = StaticMode;
        draw = new MapboxDraw({
            modes: modes,
            touchEnabled: true,
            controls: {
                point: false,
                polygon: true,
                trash: true,
                line_string: true
            },
            userProperties: true,
            displayControlsDefault: false,
            defaultMode: 'static'
            /*             styles: [
                            {
                                'id': 'highlight-active-points',
                                'type': 'circle',
                                'filter': ['all',
                                    ['==', '$type', 'Point'],
                                    ['==', 'meta', 'feature'],
                                    ['==', 'active', 'true']],
                                'paint': {
                                    'circle-radius': 7,
                                    'circle-color': '#000000'
                                }
                            },
                            {
                                'id': 'points-are-blue',
                                'type': 'circle',
                                'filter': ['all',
                                    ['==', '$type', 'Point'],
                                    ['==', 'meta', 'feature'],
                                    ['==', 'active', 'false']],
                                'paint': {
                                    'circle-radius': 5,
                                    'circle-color': '#000088'
                                }
                            }
                        ] */
        });
        map.addControl(draw, 'top-right');

        popup = new mapboxgl.Popup({
            offset: [0, -30]
        });

        initMap(map);
        //const params = new URLSearchParams(props.location.search);
        //search = params.get("search");
        await updateState();
    }, [props.fullscreen]);

    const getParams = (location) => {
        /*         //const searchParams = new URLSearchParams(location.search);
                return {
                    query: searchParams.get('query') || '',
                }; */
    }

    const updateState = async () => {


        //console.log('reset')
        var isCard = false;

        if (id.includes('activity_')) {
            items = await DataServices.CardsService.getCardsForActivity(id);
            const activityResult = await DataServices.ActivitiesService.getActivityById(id);
            setActivity(activityResult);
        } else if (id.includes('card_')) {
            isCard = true;
            items = [];
            const cardResult = await DataServices.CardsService.getCard(id);
            if (cardResult) items.push(cardResult);
        }

        // Clear floors
        floorIds = [];
        toggleFloorButtons();
        selectFloor(undefined)

        // Clear sources
        draw.deleteAll();
        featureCollection.features = [];
        fcDraw.features = [];
        const featureCollSelected = {
            type: "FeatureCollection",
            features: []
        }
        bounds = new mapboxgl.LngLatBounds();
        if (map.getSource('locations'))
            map.getSource('locations').setData(featureCollection);
        if (map.getSource('selected'))
            map.getSource('selected').setData(featureCollSelected);
        if (map.getSource('draw'))
            map.getSource('draw').setData(fcDraw);

        // Add items
        items.forEach(c => {
            addIssueToSource(c, featureCollection);
        });
        if (isCard) flyToLocation(id);

    }
    const setupFloors = (features) => {
        console.log(features)
        features.forEach(f => {
            if (f?.properties?.relations?.[0]?.reltags?.level) {
                var floorId = Number(f?.properties?.relations?.[0]?.reltags?.level);
                if (floorId != null) {
                    const exits = floorIds.some(id => id == floorId);
                    if (!exits) {
                        floorIds.push(floorId);
                        floorIds.sort((a, b) => a - b);
                        toggleFloorButtons();
                    }
                }
            }
            if (f?.properties?.floor_id) {
                var floorId = Number(f?.properties?.floor_id);
                if (floorId != null) {
                    const exits = floorIds.some(id => id == floorId);
                    if (!exits) {
                        floorIds.push(floorId);
                        floorIds.sort((a, b) => a - b);
                        toggleFloorButtons();
                    }
                }
            }
        });
    }

    const addIssueToSource = (issue, featureCollection) => {
        const boundsLocal = bounds;
        const mapLocal = map;
        const locationJSON = issue.location;
        if (locationJSON != null) {
            try {
                const location = JSON.parse(locationJSON);
                var fc = {
                    type: "FeatureCollection",
                    features: []
                };
                if (location.type == "FeatureCollection") {
                    // 2D
                    fc = location;
                } else {
                    try {
                        // 3D Arcgis
                        const lngLat = [
                            location.viewpoint.targetGeometryGeo.x,
                            location.viewpoint.targetGeometryGeo.y
                        ]
                        const feature = {
                            type: "Feature",
                            geometry: {
                                "type": "Point",
                                "coordinates": lngLat
                            }
                        };
                        fc.features.push(feature)
                    } catch (e1) {
                        console.log(e1);
                    }
                    try {
                        // 3D Autodesk
                        const lngLat = [
                            location.targetPointGeo[0],
                            location.targetPointGeo[1]
                        ]
                        const feature = {
                            type: "Feature",
                            geometry: {
                                "type": "Point",
                                "coordinates": lngLat
                            }
                        };
                        fc.features.push(feature)
                    } catch (e1) {
                        console.log(e1);
                    }
                }
                fcDraw.features.push(...fc.features);
                setupFloors(fc.features);
                if (mapLocal.getSource('draw'))
                    mapLocal.getSource('draw').setData(fcDraw);
                fc.id = id;
                //draw.add(fc);
                if (getPointFeature(fc)) {
                    const searchFilter = !search ||
                        search.trim().split(/[\s,]+/).some(w =>
                            issue.search?.toLowerCase().includes(w.toLowerCase()));

                    const feature = getPointFeature(fc);
                    feature.properties = {
                        ...feature.properties,
                        parentId: issue.id,
                        id: fc.id,
                        name: issue.name,
                        visible: searchFilter
                    }
                    featureCollection.features.push(feature);
                    boundsLocal.extend(feature.geometry.coordinates);
                    mapLocal.fitBounds(bounds, { padding, maxZoom: maxZoom });
                    if (mapLocal.getSource('locations'))
                        mapLocal.getSource('locations').setData(featureCollection);
                }
            } catch (e) {
                console.log(e);
            }
        }
    }

    const getPointFeature = (fc) => {
        let pointFeature = null;
        if (fc?.features.length > 0) {
            pointFeature = fc.features.find(f => f?.geometry?.type == 'Point');
        }
        return pointFeature;
    }

    const renderPopup = (coordinates, issueId) => {


        const Popup = () => {
            const item = items.find(x => x.id === issueId);
            if (item) {

                return (<Link
                    onClick={() => {
                        toUrl(issueId);
                        props.closeMap();
                    }}>
                    <IssueCard item={item} />
                </Link>);
            } else {
                return (<div></div>);
            }
        };

        const placeholder = document.createElement('div');
        ReactDOM.render(<Popup />, placeholder);
        popup
            .setLngLat(coordinates)
            .setDOMContent(placeholder)
            .addTo(map);
    }

    const initMap = async (newMap) => {

        showControls(id.includes('card_'))


        const { match } = props;
        const featureColl = featureCollection;

        // Add geolocate control to the map.
        newMap.addControl(
            new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true
                },
                trackUserLocation: true
            }),
            'bottom-right'
        );

        newMap.on('move', () => {
            const { lng, lat } = newMap.getCenter();
            setLng(lng.toFixed(4));
            setLat(lat.toFixed(4));
            setZoom(newMap.getZoom().toFixed(2));

        });

        map.on('draw.create', function (e) {
            setLocationUpdate(true);
        });
        map.on('draw.update', function (e) {
            setLocationUpdate(true);
        });
        map.on('draw.delete', function (e) {
            setLocationUpdate(true);
        });


        history.listen(async (location, action) => {

            id = location.pathname.substring(
                location.pathname.lastIndexOf('/') + 1);

            popup.remove();
            setLocationUpdate(false);
            setShowJson(false);
            setJson(null);

            if (id.includes('card_')) {
                draw.changeMode('simple_select');
                draw.deleteAll();
                flyToLocation(id);
                showControls(true);
            } else {
                showControls(false);
                draw.changeMode('static')
                setActivityId(location.pathname)
                // Clear selection
                const featureColl = {
                    type: "FeatureCollection",
                    features: []
                }
                if (newMap.getSource('selected'))
                    newMap.getSource('selected').setData(featureColl);

                const params = new URLSearchParams(location.search);
                search = params.get("search");
                await updateState();
            }


        });

        if (id.includes('card_'))
            flyToLocation(id);


        newMap.on('load', function () {

            // Add issue source and layer
            newMap.addSource('locations', {
                type: 'geojson',
                data: featureColl
            });

            newMap.addSource('draw', {
                type: 'geojson',
                data: fcDraw
            });
            newMap.addLayer({
                id: 'outline1',
                type: 'line',
                source: 'draw',
                layout: {},
                paint: {
                    'line-color': '#000',
                    'line-width': 1,
                    'line-opacity': 0.6
                }
            }, 'gl-draw-polygon-fill-inactive.cold');
            /*             map.addLayer({
                            'id': 'outline',
                            'type': 'fill-extrusion',
                            'source': 'draw',
                            "minzoom": 10,
                            "maxzoom": 19,
                            'paint': {
                                'fill-extrusion-color': '#6B6B6B',
                                'fill-extrusion-height': ["+", 3, ["*", 3, ["to-number", ['get', 'level', ['get', 'reltags', ['at', 0, ['get', 'relations']]]]]]],
                                'fill-extrusion-base': ["*", 3, ["to-number", ['get', 'level', ['get', 'reltags', ['at', 0, ['get', 'relations']]]]]],
                                'fill-extrusion-opacity': 0.5
                            }
                        }); */

            /*             map.addLayer({
                            'id': 'outline',
                            'type': 'fill-extrusion',
                            'source': 'draw',
                            'filter': ['==',['get', 'buildingpart', ['get', 'tags']], 'room'],
                            'paint': {
                                'fill-extrusion-color': '#6B6B6B',
                                'fill-extrusion-height': 3,
                                'fill-extrusion-base': 0,
                                'fill-extrusion-opacity': 0.5
                            }
                        }); */
            //['get', 'level', ['get', 'reltags', ['get', 'relations']]]

            /*             map.addLayer({
                            'id': 'maine',
                            'type': 'fill',
                            'source': 'draw',
                            'layout': {},
                            'paint': {
                                'fill-color': '#fafafa',
                                'fill-opacity': 0.3
                            },
                            'filter': ['==', '$type', 'Polygon']
                        }, 'gl-draw-polygon-fill-inactive.cold'); */

            // Add selected source and layer
            newMap.addSource('selected', {
                type: 'geojson',
                data: {
                    type: "FeatureCollection",
                    features: []
                }
            });


            /*             newMap.addLayer({
                            id: 'points',
                            interactive: true,
                            type: 'symbol',
                            source: 'locations',
                            layout: {
                                "icon-image": "issue",
                                "icon-size": 0.25
                            }
                        }); */
            /*             map.setLayoutProperty('points', 'icon-image', map.getLayoutProperty('bis-issue', 'icon-image'));
                        map.setLayoutProperty('points', 'icon-size', map.getLayoutProperty('bis-issue', 'icon-size')); */

            newMap.loadImage(issueImage, function (error, image) {
                if (error) throw error;
                newMap.addImage('issue', image);
                newMap.addLayer({
                    id: 'points',
                    interactive: true,
                    type: 'symbol',
                    source: 'locations',
                    layout: {
                        "icon-image": "issue",
                        "icon-size": 0.25,
                        "icon-offset": [0, -60]
                    }
                });
            });

            newMap.loadImage(selectedImage, function (error, image) {
                if (error) throw error;
                newMap.addImage('selected', image);
                newMap.addLayer({
                    id: 'selected',
                    interactive: true,
                    type: 'symbol',
                    source: 'selected',
                    layout: {
                        "icon-image": "selected",
                        "icon-size": 0.25,
                        "icon-offset": [0, -60]
                    }
                });
            });

            newMap.on('click', 'points', function (e) {
                //e.originalEvent.preventDefault();
                var issueId = e.features[0].properties.parentId;
                toUrl(issueId);
                props.closeMap();
                e.originalEvent.stopPropagation();
            });

            map.on('touchend', 'points', (e) => {
                var coordinates = e.features[0].geometry.coordinates.slice();
                var issueId = e.features[0].properties.parentId;
                toUrl(issueId);
                // Ensure that if the map is zoomed out such that multiple
                // copies of the feature are visible, the popup appears
                // over the copy being pointed to.
                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }

                renderPopup(coordinates, issueId);
                //console.log(popup)
                //e.originalEvent.stopPropagation();
            });

            map.on('touchstart', (e) => {
                popup.remove();
                //console.log(popup)
            });


            // Change the cursor to a pointer when the mouse is over the places layer.
            newMap.on('mouseenter', 'points', function (e) {
                newMap.getCanvas().style.cursor = 'pointer';
                var coordinates = e.features[0].geometry.coordinates.slice();
                var issueId = e.features[0].properties.parentId;

                // Ensure that if the map is zoomed out such that multiple
                // copies of the feature are visible, the popup appears
                // over the copy being pointed to.
                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }

                renderPopup(coordinates, issueId);


            });

            // Change it back to a pointer when it leaves.
            newMap.on('mouseleave', 'points', function () {
                newMap.getCanvas().style.cursor = '';
                popup.remove();
            });
        });

        newMap.addControl(new mapboxgl.ScaleControl({ position: 'bottom-right' }));



    }

    const onMapClick = async (e) => {
        if (id.includes('card_')) {

            // New feature
            const featurePoint = {
                id: id,
                geometry: {
                    coordinates: [e.lngLat.lng, e.lngLat.lat],
                    type: "Point"
                },
                properties: {},
                type: "Feature"
            };

            // Show selected feature
            const featureCollection = {
                type: "FeatureCollection",
                features: [featurePoint]
            }
            if (map.getSource('selected'))
                map.getSource('selected').setData(featureCollection);

            // Add feature to draw
            var data = draw.getAll();
            if (data.features.length > 0) {
                data.features.sort((a, b) => (b.geometry.type == "Point") - (a.geometry.type == "Point"));
                if (data.features[0].geometry.type != "Point") {
                    data.features.push(featurePoint);
                } else {
                    data.features[0] = featurePoint;
                }
                draw.deleteAll();
                draw.add(data);
            } else {
                draw.add(featureCollection);
            }
            setLocationUpdate(true);
            removeMapClick();
        }
    }

    const getDraw = async () => {
        // Extract GeoJson from featureGroup
        var data = draw.getAll();
        if (data.features.length > 0) {

            data.features.sort((a, b) => (b.geometry.type == "Point") - (a.geometry.type == "Point"));

            // Add floor
            if (!isNaN(visibleFloorId)) {
                data.features.forEach(f => {
                    if (f.properties)
                        f.properties.floor_id = visibleFloorId;
                    else
                        f.properties = { 'floor_id': visibleFloorId }
                });
            }

            // Add point if not exists
            if (data.features[0].geometry.type != "Point") {
                var centerFeature = center(data);
                data.features.unshift(centerFeature)
            }
            const result = await DataServices.CardsService
                .updateCardLocation(id, JSON.stringify(data));
            setLocationUpdate(false);
            updateState();
        } else {
            alert("Wouldn't you like to draw some data?");
        }
    }

    const flyToLocation = (cardId) => {

        const item = items.find(x => x.id == cardId);

        if (item && item.location) {
            try {
                const fc = JSON.parse(item.location);
                draw.add(fc);
                if (getPointFeature(fc)) {
                    const feature = getPointFeature(fc);

                    if (cardId.includes('card')) {
                        const featureCollection = {
                            type: "FeatureCollection",
                            features: [feature]
                        }
                        if (map.getSource('selected'))
                            map.getSource('selected').setData(featureCollection);
                    }

                    const bounds = new mapboxgl.LngLatBounds();
                    bounds.extend(feature.geometry.coordinates)
                    //console.log(feature)
                    //console.log(padding)
                    map.fitBounds(bounds, { padding, maxZoom: map.getZoom().toFixed(2) });
                    popup.setLngLat(feature.geometry.coordinates);
                }
            } catch (e) {
                console.log(e)
            }
        }
    }


    const addMapClick = () => {
        map.on('click', onMapClick);
        map.on('touchstart', onMapClick);
        setLocation(true);
        map.getCanvas().style.cursor = 'default'
    }

    const removeMapClick = () => {
        map.off('click', onMapClick);
        map.off('touchstart', onMapClick);
        setLocation(false);
        map.getCanvas().style.cursor = ''
    }

    const showControls = (show) => {

        var list = document.querySelectorAll(".mapboxgl-ctrl-top-right > .mapboxgl-ctrl-group");
        if (list.length > 0)
            if (show) {
                list[0].style.visibility = "inherit";
            } else {
                list[0].style.visibility = "hidden";
            }
    }

    const toggleFloorButtons = () => {
        if (floorButtonControl) {
            map.removeControl(floorButtonControl);
            //visibleFloorId = isNaN(visibleFloorId) ? floorIdForSelection || 0 : undefined;
        }

        floorButtonControl = new ButtonControl(
            [{
                className: 'mapboxgl-ctrl-layer' + (isNaN(visibleFloorId) ? ' mapboxgl-ctrl-layer-active' : ''),
                title: 'All floors',
                eventHandler: () => {
                    selectFloor(undefined);
                    toggleFloorButtons();
                    //this.updateMap();
                }
            }, ...floorIds.slice().reverse().map(floorId => ({
                className: `mapboxgl-ctrl-floor mapboxgl-ctrl-floor-${floorId}${floorId === visibleFloorId ? ' mapboxgl-ctrl-floor-active' : ''}`,
                title: floorId,
                eventHandler: () => {
                    if (floorId !== visibleFloorId) {
                        selectFloor(floorId);
                    }
                }
            }))]
        );
        map.addControl(floorButtonControl, 'bottom-right');
    }

    return (
        <div>
            {(location || locationUpdate) &&
                <DefaultButton
                    primary={true}
                    //iconProps={{ iconName: location ? '' : 'POISolid' }}
                    text={location ? 'Markiere eine Stelle' : 'Änderungen speichern'}
                    ariaLabel="Location"
                    disabled={location}
                    style={{
                        position: 'absolute',
                        zIndex: '100',
                        bottom: 40,
                        marginRight: -100,
                        right: props.fullscreen ? '50%' : 'calc((100% - 500px)/2)',
                        borderRadius: location ? 0 : 20,
                    }}
                    onClick={() => getDraw()}
                />}
            {id.includes('card_') && <CommandBarButton
                iconProps={{ iconName: 'POISolid' }}
                text="Marker"
                ariaLabel="Marker"
                onClick={() => addMapClick()}
                style={{
                    height: 30,
                    position: 'absolute',
                    zIndex: '100',
                    margin: '10px',
                    right: 0,
                    top: 135,
                    boxShadow: '0 0 0 2px rgb(0 0 0 / 10%)',
                    borderRadius: 4
                }} />}
            {id.includes('card_') && <CommandBarButton
                iconProps={{ iconName: 'Code' }}
                text={showJson && json ? "Speichern" : "Geojson"}
                onClick={() => {
                    if (showJson && json) {
                        draw.deleteAll();
                        draw.add(json);
                        setLocationUpdate(true);
                        setShowJson(false);
                    } else {
                        setShowJson(true);
                    }
                }}
                style={{
                    height: 30,
                    position: 'absolute',
                    zIndex: '100',
                    margin: '10px',
                    right: 0,
                    top: 100,
                    boxShadow: '0 0 0 2px rgb(0 0 0 / 10%)',
                    borderRadius: 4
                }} />}
            {id.includes('card_') && showJson && <TextField
                placeholder="Geojson einfügen..."
                onChange={(event, newValue) => setJson(JSON.parse(newValue))}
                multiline
                rows={10}
                styles={{
                    root: {
                        height: 30,
                        position: 'absolute',
                        zIndex: '100',
                        margin: '10px',
                        right: 100,
                        top: 100,
                        boxShadow: '0 0 0 2px rgb(0 0 0 / 10%)',
                        borderRadius: 4,
                        background: 'white'
                    }
                }} />}
            {/*             <ActionButton
                iconProps={{ iconName: 'Refresh' }}
                title="Zurücksetzen"
                ariaLabel="Reset"
                onClick={() => updateState()}
                style={{
                    position: 'absolute',
                    zIndex: '100',
                    margin: '10px',
                    right: 40,
                    top: 0,
                    background: 'white'
                }} /> */}
            <div
                ref={el => mapContainer = el}
                style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: '0'
                }}
            >
            </div>
        </div >
    );

}

export default Map;