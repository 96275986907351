import * as React from 'react';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { IButtonProps } from '@fluentui/react/lib/Button';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';

const overflowProps = { ariaLabel: 'More commands' };

export const CommandBarBasicExample = (props) => {

    const _items = [
        {
            key: 'Clear',
            iconProps: { iconName: 'Clear' },
            onClick: () => props.clear()
        },
        {
            key: 'highlight',
            text: 'Highlight',
            iconProps: { iconName: 'highlight' },
            onClick: () => props.selected('EditModeHighlight')
        },
        {
            key: 'text',
            text: 'Text',
            iconProps: { iconName: 'TextField' },
            onClick: () => props.selected('EditModeText'),
        },
        {
            key: 'arrow',
            text: 'Arrow',
            iconProps: { iconName: 'ArrowUpRight' },
            onClick: () => props.selected('EditModeArrow'),
        },
        {
            key: 'freehand',
            text: 'Freehand',
            iconProps: { iconName: 'Edit' },
            onClick: () => props.selected('EditModeFreehand'),
        },
        {
            key: 'save',
            onRender: () => {
                return <PrimaryButton
                    label="Speichern"
                    text={'Speichern'}
                    style={{
                        height: 'auto',
                        borderRadius: 0
                    }}
                    onClick={() => props.save()} />;
            },
        },
    ];

    return (
        <CommandBar
            items={_items}
            overflowButtonProps={overflowProps}
            ariaLabel="Markup actions"
            primaryGroupAriaLabel="Markup actions"
            farItemsGroupAriaLabel="More actions"
            style={{
                position: 'absolute',
                zIndex: '100',
                bottom: 15,
                right: 15,
                boxShadow: 'rgb(0 0 0 / 13%) 0px 1.6px 3.6px 0px, rgb(0 0 0 / 11%) 0px 0.3px 0.9px 0px',
            }}
            styles={{
                root: {
                    borderRadius: 4,
                    padding: 0
                }
            }}
        />
    );
};

export default CommandBarBasicExample

