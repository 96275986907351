import React, { useState, useEffect } from 'react';
import {
    AmplifyAuthenticator,
    AmplifySignUp,
    AmplifySignOut,
    AmplifySignIn,
    AmplifyForgotPassword,
    AmplifyToast
} from '@aws-amplify/ui-react';
import { withAuthenticator, Authenticator, SignIn, Greetings, ConfirmSignUp } from 'aws-amplify-react'; // or 'aws-amplify-react-native';
import Amplify from '@aws-amplify/core'
import { Auth } from '@aws-amplify/auth'
import { I18n, Translations } from '@aws-amplify/core';
import awsconfig from './data-services/aws-exports';
import { Image, Stack, PrimaryButton, ActionButton, Text, Link } from 'office-ui-fabric-react';
import flinkerLogo from "./images/flinker-icon-black.png";
import CustomTheme from "./theme.js";
import { useLocation, useParams } from "react-router-dom";
import { Hub, Logger } from "@aws-amplify/core";
import { Card } from '@uifabric/react-cards';
import LogoWithText from "./LogoWithText";
import '@aws-amplify/ui/dist/style.css';
import {
    onAuthUIStateChange, AuthState, UI_AUTH_CHANNEL,
    AUTH_STATE_CHANGE_EVENT, TOAST_AUTH_ERROR_EVENT
} from '@aws-amplify/ui-components'
import { MessageBar, MessageBarType, MessageBarButton } from '@fluentui/react';
import { ProgressIndicator } from '@fluentui/react/lib/ProgressIndicator';

if (awsconfig.oauth) {
    const isLocalhost = Boolean(
        window.location.hostname === "localhost" ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === "[::1]" ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(
            /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
    );
    // Assuming you have two redirect URIs, and the first is for localhost and second is for production
    const [
        localRedirectSignIn,
        productionRedirectSignIn,
    ] = awsconfig.oauth.redirectSignIn.split(",");

    const [
        localRedirectSignOut,
        productionRedirectSignOut,
    ] = awsconfig.oauth.redirectSignOut.split(",");

    awsconfig.oauth = {
        ...awsconfig.oauth,
        redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
        redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
    }

}

Amplify.configure(awsconfig);
Amplify.configure({
    graphql_headers: async () => {
        return { Authorization: (await Auth.currentSession()).getIdToken().getJwtToken() };
    },
    Storage: {
        AWSS3: {
            bucket: awsconfig.aws_content_delivery_bucket,
            region: awsconfig.aws_content_delivery_bucket_region,
        },
    }
});
Auth.configure(awsconfig);

I18n.setLanguage('de');

// Overwrite error messages
I18n.putVocabularies({
    de: {
        "Custom auth lambda trigger is not configured for the user pool.":
            "Passwort darf nicht leer sein",
        "Password cannot be empty": "Passwort darf nicht leer sein",
        "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
            "Passwort nicht lang genug",
        "Password did not conform with policy: Password not long enough":
            "Passwort nicht lang genug",
        "Invalid email address format.": "Email ungültig",
        "Attribute value for family_name must not be null": "Last Name required",
        "Username/client id combination not found.": "Nutzer nicht gefunden",
        "Incorrect username or password.": "Email oder Passwort sind ungültig",
        "Forgot your password?": "Passwort vergessen?",
        "No account?": "Keinen Account?",
        "Have an account?": "Hast du einen Account?",
        "Username *": "Email *",
        "Verification code": "Verifizierungs-Code",
        "Enter code": "Code aus Email eingeben",
        "New password": "Neues Passwort",
        "Enter your new password": "Neues Passwort eingeben",
        "Enter your email": "Gib deine E-Mail ein",
        "Geben Sie Ihr Passwort ein": "Gib dein Passwort ein",
        "Sign in with AWS": "Mit Microsoft anmelden",
        "PreSignUp failed with error An external provider account alreadys exists for that email address.": "Ein externer Microsoft Account exisitiert bereits für diese E-Mail-Adresse"

    },
});


const AuthenticatorWrapper = (props) => {

    const { renderApp: App } = props;
    const [authState, setAuthState] = React.useState();
    const [alertMessage, setAlertMessage] = useState('');

    const handleToastErrors = async ({ payload }) => {
        if (payload.event === TOAST_AUTH_ERROR_EVENT && payload.message) {
            setAlertMessage(payload.message);
        }
    };




    useEffect(() => {
        //Hub.listen(UI_AUTH_CHANNEL, handleToastErrors);
        return onAuthUIStateChange(async newAuthState => {
            console.log(newAuthState)
            if (window.location.search
                .includes('error_description=Already+found+an+entry+for+username'))
                signInWithMicrosoft();

            if (newAuthState === AuthState.VerifyContact) {
                Hub.dispatch(UI_AUTH_CHANNEL, {
                    event: AUTH_STATE_CHANGE_EVENT,
                    message: AuthState.SignedIn,
                    data: await Auth.currentAuthenticatedUser(),
                });
            }
            setAuthState(newAuthState)
        });
    }, []);

    const signInWithMicrosoft = async () => {
        Auth.federatedSignIn({
            customProvider: 'Microsoft'
        }).then(cred => {
            // If success, you will get the AWS credentials
            console.log(cred);
            return Auth.currentAuthenticatedUser();
        }).then(user => {
            // If success, the user object you passed in Auth.federatedSignIn
            console.log(user);
        }).catch(e => {
            console.log(e)
        });
    }


    const pathname = window.location.pathname;


    return (
        <>
            {alertMessage && (
                <MessageBar
                    delayedRender={false}
                    isMultiline={true}
                    messageBarType={MessageBarType.error}
                    dismissButtonAriaLabel="Close"
                    onDismiss={() => setAlertMessage(null)}
                    styles={{
                        root: {
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            paddingLeft: 50
                        },
                        content: { margin: 'auto' },
                        iconContainer: { fontSize: 'medium' },
                        text: { fontSize: 'medium' }
                    }}
                    actions={
                        alertMessage.includes('Ein externer Microsoft Account exisitiert bereits für diese E-Mail-Adresse') &&
                        <MessageBarButton
                            onClick={() => signInWithMicrosoft()}
                        >
                            Weiter mit Microsoft
                        </MessageBarButton>
                    }
                >
                    {alertMessage}
                </MessageBar>
            )}
            <AmplifyAuthenticator
                //hideToast
                initialAuthState={pathname.includes('signup') ? 'signup' : 'signin'}
                usernameAlias="email">
                {authState === "signedin" && <App />}
                <div slot="sign-up" style={{ textAlign: 'center' }}>
                    <LogoWithText />
                    <AmplifySignUp
                        slot="sign-up"
                        usernameAlias="email"
                        //haveAccountText={I18n.get(Translations.SIGN_UP_HAVE_ACCOUNT_TEXT)}
                        formFields={[
                            {
                                label: null,
                                key: "username",
                                required: true,
                                placeholder: "Email",
                                type: "email",
                                displayOrder: 1
                            },
                            {
                                label: null,
                                key: "password",
                                required: true,
                                placeholder: "Passwort",
                                type: "password",
                                displayOrder: 2
                            }
                        ]}
                    />
                </div>
                <div slot="confirm-sign-up" >
                    <LogoWithText />
                    <Card
                        aria-label="card"
                        vertical
                        styles={{
                            root: {
                                padding: 40,
                                backgroundColor: 'white'
                            }
                        }}>

                        <Text block>Überprüfe deine E-Mails, wir haben dir einen Link zur Verifizierung gesendet.</Text>

                        <PrimaryButton
                            text="Zurück zur Anmeldung"
                            onClick={() => window.location.reload()}
                            styles={{
                                root: {
                                    borderRadius: 50
                                }
                            }} />
                    </Card>
                </div>
                <div slot="forgot-password">
                    <LogoWithText />
                    <AmplifyForgotPassword
                        translate={"yes"}
                    />
                </div>
                <div slot="verify-contact"></div>
                <div slot="sign-in" >
                    <LogoWithText />
                    <AmplifySignIn
                        headerText="Anmeldung"
                        slot="sign-in"
                        submitButtonText="Anmelden"
                        formFields={[
                            {
                                label: null,
                                key: "username",
                                required: true,
                                placeholder: "E-Mail",
                                type: "email",
                                displayOrder: 1
                            },
                            {
                                label: null,
                                key: "password",
                                required: true,
                                placeholder: "Passwort",
                                type: "password",
                                displayOrder: 2
                            }
                        ]} >
                        {awsconfig.oauth && <div slot="federated-buttons">
                            <Stack
                                styles={{
                                    root: {
                                        border: '1px solid lightgray',
                                        width: '100%',
                                        marginBottom: 40,
                                        borderRadius: 2,
                                        background: '#fafafa'
                                    }
                                }}
                                fullWidth
                                horizontal
                                align="center"
                                childrenGap={12}>
                                <Stack.Item >
                                    <img style={{ height: 36, margin: 20 }} src="https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg" />
                                </Stack.Item>
                                <Stack.Item align="strech" grow>
                                    <ActionButton
                                        text="Weiter mit Microsoft"
                                        onClick={() => signInWithMicrosoft()}
                                        styles={{
                                            root: {
                                                width: '100%',
                                                height: '100%',
                                                fontWeight: 'bold'
                                            }
                                        }} />
                                </Stack.Item>
                            </Stack>
                        </div>}
                    </AmplifySignIn>
                </div>
            </AmplifyAuthenticator >
        </>
    );
};



export default AuthenticatorWrapper