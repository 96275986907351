import {API, graphqlOperation} from "aws-amplify";
import {listStacks, createStack, updateStack, deactivateStack} from "./graphql";
import {GraphQLResult} from "@aws-amplify/api-graphql";
import StackDto, {StackServerResponseDto, InputStack} from "./StackDto";
import {DataServices} from "./DataServices";

class StacksService {
    private stacksByActivityId: Record<string, StackDto[]> = {};
    private responseMessages: any[] = [];
    constructor() {}

    allLoaded() {
        return true;
    }

    clearCache() {
        this.stacksByActivityId = {};
    }

    getLoadedStackById(stackId: string): StackDto | null {
        for (const stackList of Object.values(this.stacksByActivityId)) {
            const foundStack = stackList.find(card => card.id === stackId);
            if (foundStack) {
                return foundStack;
            }
        }
        return null;
    }

    
    getLoadedStacksForCurrentActivity(): StackDto[] {
        const activeActivityId = DataServices.ActivitiesService.getActiveActivityId();
        return this.stacksByActivityId[activeActivityId] ?? [];
    }

    getStacksForCurrentActivity(): Promise<StackDto[]> {
        const activeActivityId = DataServices.ActivitiesService.getActiveActivityId();
        return this.getStacksForActivity(activeActivityId);
    }

    async getStacksForActivity(activityId: string): Promise<StackDto[]> {
        if (!this.stacksByActivityId[activityId]) {
            await this.loadStacksForActivity(activityId);
        }
        return this.stacksByActivityId[activityId];
    }

    async deleteStack(stackId: string) {
        return API.graphql(graphqlOperation(deactivateStack, {
            stackId: stackId
        }));
    }

    async createStack(newStack: InputStack) {
        return API.graphql(graphqlOperation(createStack, {
            activityId: DataServices.ActivitiesService.getActiveActivityId(),
            name: newStack.name,
        }));
    }

    async updateStack(stackId: string, updatedStack: InputStack) {
        return API.graphql(graphqlOperation(updateStack, {
            stackId: stackId,
            name: updatedStack.name,
        }));
    }

    getResponseMessages() {
        return this.responseMessages.slice();
    }

    private serverDataToInternalDto(stackResponseObject: StackServerResponseDto): StackDto {
        const contact = {
            id: stackResponseObject.id,
            name: stackResponseObject.name
        };
        return contact as StackDto;
    }

    private async loadStacksForActivity(activityId: string) {
        try {
            const result = (await API.graphql(graphqlOperation(listStacks, { activityId }))) as GraphQLResult<any>;
            if (result.data.listStacks) {
                var resultStacks: StackDto[]= [];
                for (const contact of result.data.listStacks.items) {
                    resultStacks.push(this.serverDataToInternalDto(contact));
                }
                this.stacksByActivityId[activityId] = resultStacks;
            }
        } catch (err) {
            console.log(err)
        }
    }
}

export default StacksService;