import React, { useEffect, useRef, useState } from "react";
import useScript from './useScript';
import loadScript from './loadScript';
import MarkupModes from './MarkupModes';
import { Auth } from 'aws-amplify';
import awsconfig from '../../shared/data-services/aws-exports';
import { DataServices } from "../../shared/data-services/DataServices";
import IssueCard from "./PreviewCard";
import { Link, Text } from '@fluentui/react';
import { TeachingBubble } from '@fluentui/react/lib/TeachingBubble';
import { DefaultButton, IButtonProps } from '@fluentui/react/lib/Button';
import { useHistory, useParams } from 'react-router-dom';
import proj4 from 'proj4'

var viewer;
var subToolbar;
var sectionTool;
var markup;
var geoExtension;

const MapAutodesk = props => {

    const viewerRef = useRef(null);
    const buttonRef = useRef(null);
    const media = window.matchMedia('(prefers-color-scheme: light)');
    const [markupVisible, setMarkupVisible] = useState(false);
    const [hoverCard, setHoverCard] = useState(null);
    const history = useHistory();


    const status = useScript('https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/viewer3D.min.js');
    useScript('https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js')


    const linkElement = document.createElement("link");
    linkElement.setAttribute("rel", "stylesheet");
    linkElement.setAttribute("type", "text/css");
    linkElement.setAttribute(
        "href",
        "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/style.min.css"
    );
    document.head.appendChild(linkElement);

    const linkElement1 = document.createElement("link");
    linkElement1.setAttribute("rel", "stylesheet");
    linkElement1.setAttribute("type", "text/css");
    linkElement1.setAttribute(
        "href",
        "https://static2.sharepointonline.com/files/fabric/office-ui-fabric-core/11.0.0/css/fabric.min.css"
    );
    document.head.appendChild(linkElement1);

    const toUrl = (itemId) => {
        history.push({
            pathname: itemId,
            search: history.location.search ? history.location.search : ''
        })
    }

    useEffect(() => {
        flyTo();
    }, [props.target]);

    const flyTo = async () => {
        console.log(props.target, viewer)
        if (props.target && viewer) {
            const markups = props.target.markup;
            delete props.target.markup;
            console.log(props.target)
            //viewer.restoreState(props.target);
            await restoreStatePromise(props.target);
            if (markups && markup) {
                setMarkupVisible(true);
                markup.show();
                markup.loadMarkups(markups, "layerName");
                markup.enterEditMode();
            }
        }
        if (!props.target && markup) {
            hideMarkupMode();
        }
    }

    async function restoreStatePromise(viewerState) {
        var restorePromise = new Promise(function (resolve, reject) {
            var listener = function (event) {
                if (event.value.finalFrame) {
                    viewer.removeEventListener(
                        window.Autodesk.Viewing.FINAL_FRAME_RENDERED_CHANGED_EVENT,
                        listener
                    );
                    resolve();
                }
            }

            // Wait for last render caused by camera changes
            viewer.addEventListener(
                window.Autodesk.Viewing.FINAL_FRAME_RENDERED_CHANGED_EVENT,
                listener
            );

            viewer.restoreState(viewerState);
        });
        await restorePromise;
    }

    useEffect(() => {
        if (window.Autodesk) {

            console.log(window.Autodesk)
            loadScript('./MeshSelectionExtension.js', () => {
                console.log(window)
                var options = {
                    env: 'AutodeskProduction2',
                    api: 'streamingV2',
                    getAccessToken: async (onGetAccessToken) => {
                        const currentSession = await Auth.currentSession();
                        const requestOptions = {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${currentSession.idToken.jwtToken}`
                            },
                            body: JSON.stringify({ title: 'React POST Request Example' })
                        };
                        try {
                            var expireTimeSeconds = 60 * 30;
                            const response = await fetch(awsconfig.aws_api_forge, requestOptions);
                            const data = await response.json();
                            onGetAccessToken(data.access_token, expireTimeSeconds);
                        } catch (e) {
                            console.log(e)
                        }
                    }

                };
                window.Autodesk.Viewing.Initializer(options, function onInitialized() {

                    viewer = new window.Autodesk.Viewing.GuiViewer3D(viewerRef.current, {});

                    viewer.prefs.set('openPropertiesOnSelect', true);

                    viewer.impl.setFPSTargets(16, 24, 30); // 16,24,30
                    //viewer.setDisplayEdges(true);
                    window.devicePixelRatio = 1 //1.5, 1.0, 0.75 ...
                    //viewer.loadExtension('Autodesk.Geolocation')
                    //viewer.loadExtension('MyAwesomeExtension')
                    //viewer.loadExtension('Autodesk.Viewing.MarkupsGui')

                    viewer.loadExtension('Autodesk.Viewing.MarkupsCore').then(function (markupsExt) {
                        markup = markupsExt;
                    });




                    //console.log('MeshExt',MeshExt)

                    const ext = viewer.getExtensionAsync('MyAwesomeExtension')

                    media.addListener(() => {
                        viewer.setTheme((media.matches ? 'dark' : 'light') + '-theme'); // if it changes after load
                    });
                    viewer.setTheme((media.matches ? 'dark' : 'light') + '-theme'); // first time

                    var startedCode = viewer.start();
                    if (startedCode > 0) {
                        console.error('Failed to create a Viewer: WebGL not supported.');
                        return;
                    }
                    window.Autodesk.Viewing.Document.load(props.documentId, onDocumentLoadSuccess, onDocumentLoadFailure);

                    /*                     var urns = [
                                            { urn: "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6YTM2MHZpZXdlci90MTYzNzIzMDUyOTQwMF9lMTAxYjI0ZS1kYzk1LTQ5NjgtODI5MS03MmFiYjM1YTRkM2RfMTYzNzIzMDUyOTUzNy5pZmM", xform: { x: 0, y: 0, z: 0 } },
                                            //{ urn: "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6YTM2MHZpZXdlci90MTYzNjcwOTIzNDI1Nl80MzYxYzU0Ny1lMDc2LTQ3YjQtYmVhOC0zOTFkODU0ODQ4YjBfMTYzNjcwOTIzNDY4NS5pZmM", xform: { x: 0, y: 0, z: 0 } }
                                        ];
                                        urns.map((m) => {
                                            try {
                                                window.Autodesk.Viewing.Document.load(`urn:${m.urn}`, (doc) => {
                                                    var viewables = doc.getRoot().getDefaultGeometry();
                                                    viewer.loadDocumentNode(doc, viewables, {
                                                        //preserveView: true,
                                                        keepCurrentModels: true,
                                                        //placementTransform: (new window.THREE.Matrix4()).setPosition(m.xform),
                                                        keepCurrentModels: true,
                                                        //: { x: 0, y: 0, z: 0 }
                                                    })
                                                        .then(onLoadFinished);
                                                });
                                            } catch (e) {
                                                console.log(e)
                                            }
                    
                                        }); */

                    getExtension();
                    addBuildingExtension();
                    addExtension();

                });

                //     var models = [
                //         { urn: "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6YTM2MHZpZXdlci90MTYzNjcwNjg0MDM1M19mZGIzM2NlOS1mNzJmLTQ1NmUtYWJhOS1mOTU4YzAyZDkxMjFfMTYzNjcwNjg0MDU5NS5pZmM", xform: { x: 0, y: 0, z: 0 } },
                //         { urn: "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6YTM2MHZpZXdlci90MTYzNjcwOTIzNDI1Nl80MzYxYzU0Ny1lMDc2LTQ3YjQtYmVhOC0zOTFkODU0ODQ4YjBfMTYzNjcwOTIzNDY4NS5pZmM", xform: { x: 0, y: 0, z: 0 } }
                //     ];

                //     window.Autodesk.Viewing.Initializer(options, function () {
                //         //initialize the viewer object
                //         const view = new window.Autodesk.Viewing.AggregatedView();
                //         view.init(viewerRef.current, options);

                //         const viewer = view.viewer;

                //         const tasks = [];
                //         models.forEach(md => {
                //             console.log(md)
                //             tasks.push(loadManifest(md.urn));
                //         });


                //         Promise.all(tasks)
                //             .then(docs => Promise.resolve(docs.map(doc => {
                //                 const bubbles = doc.getRoot().search({ type: 'geometry', role: '3d' });
                //                 const bubble = bubbles[0];
                //                 if (!bubble) return null;

                //                 return bubble;
                //             })))
                //             .then(bubbles => view.setNodes(bubbles));
                //     });
            });
        }

    }, [status]);

    function loadManifest(documentId) {
        return new Promise((resolve, reject) => {
            const onDocumentLoadSuccess = (doc) => {
                doc.downloadAecModelData(() => resolve(doc));
            };
            window.Autodesk.Viewing.Document.load(documentId, onDocumentLoadSuccess, reject);
        });
    }

    const getExtension = async () => {
        sectionTool = await viewer.getExtensionAsync('Autodesk.Section');
        console.log(sectionTool)
    }

    function onLoadFinished(doc) {
        console.log('loaded');
        DataServices.CardsService.getCardsForActivity(props.id).then(items => {
            viewer.loadExtension('Viewing.Extension.MeshSelection', {
                items,
                onHover: (card) => {
                    setHoverCard(card);
                },
                onClick: (card) => {
                    toUrl(card.id);
                }
            });
        });
/*         viewer.loadExtension('Autodesk.Geolocation').then(async function (ext) {
            geoExtension = ext;
        }); */
        viewer.prefs.set('displaySectionHatches', true);
        viewer.prefs.set('ghosting', false)
    }

    function onDocumentLoadSuccess(viewerDocument) {
        var defaultModel = viewerDocument.getRoot().getDefaultGeometry();
        const options = {
            applyScaling: 'meters'
        };
        viewer.loadDocumentNode(viewerDocument, defaultModel, options)
            .then(onLoadFinished);
    }

    function onDocumentLoadFailure(viewerErrorCode) {
        console.error('onDocumentLoadFailure() - errorCode:' + viewerErrorCode);
    }


    function ToolbarExtension(viewer, options) {
        window.Autodesk.Viewing.Extension.call(this, viewer, options);

    }

    function BuildingToolbarExtension(viewer, options) {
        window.Autodesk.Viewing.Extension.call(this, viewer, options);
    }

    const addExtension = async () => {

        ToolbarExtension.prototype = Object.create(window.Autodesk.Viewing.Extension.prototype);
        ToolbarExtension.prototype.constructor = ToolbarExtension;

        ToolbarExtension.prototype.load = function () {
            // Set background environment to "Infinity Pool"
            // and make sure the environment background texture is visible
            //viewer.setLightPreset(6);
            //viewer.setEnvMapBackground(true);
            viewer.fitToView();
            //viewer.impl.toggleVizBuffer(true)


            //viewer.prefs.set(window.Autodesk.Viewing.Private.Prefs3D.GHOSTING, true)

            return true;
        };

        ToolbarExtension.prototype.unload = function () {
            if (subToolbar) {
                viewer.toolbar.removeControl(subToolbar);
                subToolbar = null;
            }
        };

        window.Autodesk.Viewing.theExtensionManager.registerExtension('ToolbarExtension', ToolbarExtension);
        viewer.loadExtension('ToolbarExtension');

        ToolbarExtension.prototype.onToolbarCreated = function (toolbar) {


            const toolbarControls = [];
            for (var i = 0; i < toolbar.getNumberOfControls(); i++) {
                toolbarControls.push(viewer.toolbar.getControlId(i));
            }
            toolbarControls.forEach(control => {
                console.log(control)
                //if (control != 'modelTools')
                //toolbar.removeControl(control)
            });

            // Button 1
            var button1 = new window.Autodesk.Viewing.UI.Button('show-env-bg-button');
            button1.onClick = function (e) {
                saveView(viewer.getState());
            }
            button1.addClass('show-env-bg-button');
            button1.setToolTip('Neue Card');
            button1.icon.classList.add("ms-Icon", "ms-Icon--Camera");
            button1.container.innerText = 'Neue Card'

            // SubToolbar
            subToolbar = new window.Autodesk.Viewing.UI.ControlGroup('my-custom-toolbar');
            subToolbar.addControl(button1);

            toolbar.addControl(subToolbar);
        };

    }

    const addBuildingExtension = () => {
        BuildingToolbarExtension.prototype = Object.create(window.Autodesk.Viewing.Extension.prototype);
        BuildingToolbarExtension.prototype.constructor = BuildingToolbarExtension;

        BuildingToolbarExtension.prototype.load = function () {
            return true;
        };

        BuildingToolbarExtension.prototype.unload = function () {
            // nothing yet
            if (subToolbar) {
                viewer.toolbar.removeControl(subToolbar);
                subToolbar = null;
            }
        };

        BuildingToolbarExtension.prototype.onToolbarCreated = function (toolbar) {
            viewer = viewer;

            var button = new window.Autodesk.Viewing.UI.Button('hide-env-bg-button1');
            button.onClick = function (e) {
                markup.enterEditMode();
                setMarkupVisible(true);
                setMarkupMode('EditModeArrow');
            };
            button.addClass('hide-env-bg-button1');
            button.setToolTip('Markups');
            button.icon.classList.add("ms-Icon", "ms-Icon--Brush");

            // SubToolbar
            subToolbar = new window.Autodesk.Viewing.UI.ControlGroup('my-custom-toolbar1');
            subToolbar.addControl(button);
            toolbar.addControl(subToolbar);
        };

        window.Autodesk.Viewing.theExtensionManager.registerExtension('BuildingToolbarExtension', BuildingToolbarExtension);
        viewer.loadExtension('BuildingToolbarExtension');

    }

    const saveView = async (view) => {
        let w = viewer.container.clientWidth;
        let h = viewer.container.clientHeight;
        const geometry = viewer.clientToWorld(w / 2, h / 2);
        if (geometry) {
            view.targetPoint = geometry.intersectPoint;
            const offset = viewer.model.getData().globalOffset;
            const targetPointGeo = {};
            targetPointGeo.x = offset.x - geometry.intersectPoint.x;
            targetPointGeo.y = offset.y - geometry.intersectPoint.y;
            targetPointGeo.z = offset.z - geometry.intersectPoint.z;
            var utm = "+proj=utm +zone=32";
            var wgs84 = "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs";
            view.targetPointGeo = proj4(utm, wgs84, [targetPointGeo.x, targetPointGeo.y])
        }
        const blob = await generateSnapshot(viewer);
        await props.setLocation(view, blob);
    }

    const updateLocation = async () => {
        var markupsPersist = markup.generateData();
        var viewerStatePersist = markup.viewer.getState()
        if (markupsPersist) viewerStatePersist.markup = markupsPersist;
        await saveView(viewerStatePersist);
        hideMarkupMode();
    }

    const setMarkupMode = (modeName) => {
        const mode = new window["Autodesk"]["Viewing"]["Extensions"]["Markups"]["Core"][modeName](markup);
        markup.changeEditMode(mode);
        var styleAttributes = ['stroke-width', 'font-size'];
        var nsu = window.Autodesk.Viewing.Extensions.Markups.Core.Utils;
        var styleObject = nsu.createStyle(styleAttributes, markup);
        styleObject['stroke-width'] = 6;
        styleObject['font-size'] = 30;
        markup.setStyle(styleObject);
    }

    const hideMarkupMode = () => {
        setMarkupVisible(false);
        markup.leaveEditMode();
        markup.hide();
    }

    async function generateSnapshot(viewer) {
        return new Promise((resolve, reject) => {
            let w = viewer.container.clientWidth;
            let h = viewer.container.clientHeight;
            if (markupVisible) {
                let screenshot = new Image();
                screenshot.onload = async () => {
                    const markupCore = await viewer.loadExtension('Autodesk.Viewing.MarkupsCore');
                    markupCore.show();
                    let canvas = document.createElement('canvas');
                    canvas.width = w;
                    canvas.height = h;
                    let ctx = canvas.getContext('2d');
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    ctx.drawImage(screenshot, 0, 0, canvas.width, canvas.height);
                    markupCore.renderToCanvas(ctx, () => canvas.toBlob(blob => resolve(blob)), false);
                };
                viewer.getScreenShot(w, h, blobURL => screenshot.src = blobURL);
            } else {
                viewer.getScreenShot(w, h, async blobURL => {
                    let blob = await fetch(blobURL).then(r => r.blob());
                    resolve(blob);
                });
            }
        });
    }

    return (
        <div
            ref={viewerRef}
            style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
                zIndex: 0
            }}
            id="MyViewerDiv">
            {markupVisible && <MarkupModes
                clear={() => hideMarkupMode()}
                save={() => updateLocation()}
                selected={(mode) => setMarkupMode(mode)}
                style={{
                    position: 'absolute',
                    zIndex: '100',
                    bottom: 40,
                    right: 40,
                    borderRadius: 0,
                }} />}
            {hoverCard && <TeachingBubble
                target={`#${hoverCard.id}`}
                //onDismiss={toggleTeachingBubbleVisible}
                //headline="Discover what’s trending around you"
                styles={{
                    content: {
                        background: "white",
                        maxWidth: 'unset'
                    },
                    bodyContent: {
                        padding: 0
                    },
                    root: {
                        width: 'unset!important',
                        'max-width': '300px !important'
                    }
                }}
                style={{
                    width: 'unset!important'
                }}>
                <IssueCard
                    item={hoverCard}
                />
            </TeachingBubble>}
        </div>
    )
}

export default MapAutodesk