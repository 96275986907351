import React, { useState, useEffect } from 'react';
import { Nav } from 'office-ui-fabric-react/lib/Nav';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Label } from 'office-ui-fabric-react';
import { Icon } from '@fluentui/react/lib/Icon';
import { Stack, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { withRouter } from 'react-router-dom';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { TextField, ITextFieldStyles } from 'office-ui-fabric-react/lib/TextField';
import { Persona, PersonaSize } from "office-ui-fabric-react/lib/Persona";
import awsconfig from './data-services/aws-exports';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { mergeStyles } from 'office-ui-fabric-react/lib/Styling';
import { DataServices } from "./data-services/DataServices";
import { TooltipHost } from '@fluentui/react/lib/Tooltip';


const navStyles = {
    root: {
        width: '100%',
        overflowY: 'auto',
    }
};

const navLinkGroups = [
    {
        links: [],
    }
];

const modalPropsStyles = {
    main: {
        minHeight: 400
    }
};
const dialogContentProps = {
    type: DialogType.normal,
    title: 'Neue Aktivität',
};
const dialogContentPropsInvitation = {
    type: DialogType.normal,
    title: 'Einladung annehmen',
};
const modalProps = {
    isBlocking: true,
    styles: modalPropsStyles,
};

const Activities = (props) => {

    const [contacts, setContacts] = useState([]);
    //const [activities, setActivities] = useState([]);
    const [inviteContact, setInviteContact] = useState(false);
    const [hidden, setHidden] = useState(true);
    const [firstTextFieldValue, setFirstTextFieldValue] = useState('');

    const onChangeFirstTextFieldValue = (event, newValue) => {
        setFirstTextFieldValue(newValue || '');
    };

    const activities = props.activities
        .map(act => {
            const createdByMe = act.createdBy === props.profileId;
            const createdByPro = act.createdByContact ? act.createdByContact.pro : false;
            const numberOfContacts = act.contacts.items.length;
            const mainContact = act.contacts.items.find(c => c.id !== props.profileId)
            const avatarUrl = mainContact ?
                DataServices.ContactsService.getAvatar(mainContact.id, mainContact.updatedAt)
                : null;
            return {
                name: act.name,
                key: act.id,
                pro: act.pro,
                createdByPro,
                createdByMe,
                numberOfContacts,
                avatarUrl,
                invitation: act.type === "INVITATION",
                root: act.type === "ROOT",
                unreadCards: act.unreadCards,
                unreadAssignedCards: act.unreadAssignedCards
            }
        });

    const add = {
        key: 'new',
        name: ' Neue Aktivität',
        isExpanded: true,
        url: '',
    }

    if (props.web) activities.splice(0, 0, add);
    navLinkGroups[0].links = activities;

    function _onLinkClick(ev, item) {
        if (item) {
            if (item.key == 'new') {
                setHidden(false);
            } else {
                props.history.push('/' + item.key);
                props.onClick();
            }
        }
    }

    function setClose() {
        setHidden(true);
        props.onCreate(firstTextFieldValue);
        setFirstTextFieldValue('');
    }

    const _onRenderLink = (link) => {

        return (
            <Stack
                horizontal
                horizontalFill
                styles={{
                    root: {
                        width: '100%'
                    }
                }} >
                <Stack.Item grow>
                    {link.numberOfContacts != 2 ?
                        <Stack horizontal>
                            <FontIcon
                                iconName={link.invitation ? 'MailSolid' : link.key == 'new' ? "AddTo" : (link.numberOfContacts == 1 ? "LockSolid" : "Group")}
                                className={mergeStyles({
                                    fontSize: link.numberOfContacts == 1 ? 16 : 24,
                                    height: 32,
                                    width: 32,
                                    margin: '0 8px 0 0',
                                    color: link.invitation ? '#3771C8' : 'lightgray'
                                })} />
                            <Text block wrap styles={{
                                root: {
                                    float: 'left',
                                    maxWidth: 150,
                                    fontWeight: link.key == 'new' || link.invitation ? 'bold' : 'normal',
                                    overflow: 'hidden',
                                }
                            }}>
                                {link.root ? "Mir zugewiesen" : link.name}
                            </Text>
                        </Stack>
                        :
                        <Persona
                            text={link.root ? "Mir zugewiesen" : link.name}
                            size={PersonaSize.size32}
                            styles={{
                                root: {
                                    height: 'auto'
                                },
                                primaryText: {
                                    maxWidth: 150
                                }
                            }}
                            imageUrl={link.avatarUrl}
                        />

                    }
                </Stack.Item>
                <Stack.Item >
                    {(link.createdByPro || link.createdByMe) && link.pro && <Label
                        styles={{
                            root: {
                                display: 'inline',
                                paddingRight: 6,
                                paddingLeft: 6,
                                color: link.createdByPro ? 'gray' : '#eee',
                                fontSize: 'smaller',
                                fontWeight: 600
                            }
                        }}>
                        {link.createdByPro ? 'PRO' : 'PRO inaktiv'}
                    </Label>}

                    {/*                     {link.unreadCards > 0 && <Icon
                        iconName={"StatusCircleInner"}
                        styles={{
                            root: {
                                width: 20,
                                color: '#3771c8',
                                fontSize: 'smaller',
                            }
                        }}
                    />} */}
                    {link.unreadCards > 0 &&
                        <TooltipHost content={link.unreadAssignedCards > 0 ? 'ungelesene Cards für dich' :
                            'ungelesene Cards'}>
                            <Text
                                variant={'medium'}
                                nowrap
                                styles={{
                                    root: {
                                        width: 20,
                                        paddingLeft: 6,
                                        paddingRight: 6,
                                        borderRadius: 20,
                                        color: link.unreadAssignedCards > 0 ? 'white' : '#3771c8',
                                        background: link.unreadAssignedCards > 0 ? '#3771c8' : '#fafafa',
                                    }
                                }}>
                                {link.unreadCards}
                            </Text>
                        </TooltipHost>}
                    <Icon
                        iconName={(link.createdByMe && !link.root) ? "EditSolid12" : null}
                        styles={{
                            root: {
                                width: 20,
                                color: 'lightgray',
                                fontSize: 'smaller',
                            }
                        }}
                    />
                </Stack.Item>
            </Stack>
        );
    }

    const _onRenderGroupHeader = (group) => {
        return <h4>{group.name}</h4>;

    }

    return (
        //<Scrollbars style={{ width: 500, height: 300 }}>
        <div>
            <Dialog
                minWidth={400}
                hidden={hidden}
                onDismiss={setHidden}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}>
                <Stack verticalAlign="space-between" styles={{ root: { height: 300 } }}>
                    <TextField
                        placeholder="Name der Aktivität eingeben"
                        value={firstTextFieldValue}
                        onChange={onChangeFirstTextFieldValue}
                    />
                    <DialogFooter>
                        <PrimaryButton
                            text={"Erstellen"}
                            onClick={() => setClose()}
                            allowDisabledFocus
                            disabled={!firstTextFieldValue}
                            styles={{
                                root: {
                                    borderRadius: 20
                                }
                            }}
                        />
                    </DialogFooter>
                </Stack>
            </Dialog>
            <Nav
                onLinkClick={_onLinkClick}
                onRenderLink={_onRenderLink}
                selectedKey="ColorPicker"
                styles={navStyles}
                ariaLabel="Aktivitäten"
                groups={navLinkGroups} />
        </div>
        //</Scrollbars>
    );
};



export default withRouter(Activities);