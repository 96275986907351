import { IconButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { TextField } from '@fluentui/react/lib/TextField';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import React, { useState } from 'react';
import { DataServices } from "../../shared/data-services/DataServices";
import Dropzone from "./Dropzone";

const textFieldStyles = {
    root: {
        marginLeft: 50,
        marginRight: 50,
        marginTop: 20,
        marginBottom: 2
    },
    wrapper: {
        border: 'none'
    },
    fieldGroup: {
        borderRadius: 4
    },
};

const uuidv4 = require('uuid/v4');

const NewComment = (props) => {

    const [comment, setComment] = useState({ content: '' });
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [value, setValue] = useState(0); // integer state
    const [files, setFiles] = useState([]);

    const createComment = async () => {

        const { card, createComment, userAttributes } = props;

        const content = comment.content;
        const newFile = file;

        setFile(null);
        setComment({ content: '' });

        props.onCreate({
            content: comment.content,
            dataId: card.id,
            activityId: card.activityId,
            createdBy: props.profile.id,
            contact: {
                name: props.profile.name,
                email: props.profile.email
            },
            signedUrl: newFile ? URL.createObjectURL(newFile) : null,
            file: newFile ? URL.createObjectURL(newFile) : null
        });

        var result = '';
        if (newFile) {
            result = await DataServices.CardsService.createCommentWithFile(
                content, card.id, card.activityId, newFile);

        } else {
            result = await DataServices.CardsService.createComment(comment.content, card.id, card.activityId);
        }

    }

    const handleSubmit = async (e) => {
        e.stopPropagation();
        e.preventDefault();

        setLoading(true);

        await createComment();

        setValue(value => value + 1); // force update
        //setComment({ content: '' });
        setLoading(false);
        //setFile(null);
    }

    const handleChange = ({ target: { value: content } }) => {
        setComment({ content });
    }

    const { classes } = props;
    return (
        <Stack styles={{
            root: {
                background: '#f3f2f1'
            }
        }}>
            <TextField
                placeholder="Schreib eine Nachricht..."
                value={comment.content}
                //disabled={loading}
                onChange={(e) => handleChange(e)}
                styles={textFieldStyles}
                borderless
                underlined
                autoAdjustHeight
                multiline
            //rows={2}
            />
            <Stack
                horizontal
                tokens={{
                    childrenGap: 0
                }}
                styles={{
                    root: {
                        alignItems: 'center',
                        marginBottom: 50,
                        marginLeft: 50,
                        marginRight: 50
                    }
                }} >
                <Stack.Item grow>
                    <Dropzone
                        onLoad={(file) => setFile(file)}
                        selected={file ? true : false} />
                </Stack.Item>
                <Stack.Item align="center">
                    {/*                     <IconButton
                        iconProps={{ iconName: 'Send' }}
                        title="Send"
                        ariaLabel="Send"
                        disabled={loading || (!comment.content && !file)}
                        onClick={(e) => handleSubmit(e)}
                    /> */}
                    <PrimaryButton
                        text="Senden"
                        ariaLabel="Send"
                        disabled={loading || (!comment.content && !file)}
                        onClick={(e) => handleSubmit(e)}
                    />
                </Stack.Item>
            </Stack>
        </Stack>
    );

}

export default NewComment;