import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import MapEsri from "./MapEsri";
import Mapbox from "./Mapbox";
import MapAutodesk from "./MapAutodesk";
import { useHistory, useParams } from 'react-router-dom';
import { DataServices } from "../../shared/data-services/DataServices";
import { ActionButton, DefaultButton, CommandBarButton, IconButton } from 'office-ui-fabric-react/lib/Button';
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react/lib/ChoiceGroup';
import center from '@turf/center'

const Map = (props) => {


    let { id } = useParams();
    const history = useHistory();

    const initSearch = new URLSearchParams(history.location.search).get("search");
    let search = initSearch ? initSearch : '';
    const [activity, setActivity] = useState(null);
    const [card, setCard] = useState(null);
    const [selectedKey, setSelectedKey] = React.useState('default');
    const [target, setTarget] = React.useState(null);

    useEffect(() => {
        fetchData();
        history.listen(async (location, action) => {

            id = location.pathname.substring(
                location.pathname.lastIndexOf('/') + 1);
            console.log('currentId', id)

            fetchData();
            const params = new URLSearchParams(location.search);
            search = params.get("search");

        });
    }, []);



    const onChange = React.useCallback((ev, option) => {
        setSelectedKey(option.key);
    }, []);

    const toStream = (itemId) => {
        const streamParams = "applicationId=" + activity?.appendix?.stream?.applicationId;
        history.push({
            pathname: itemId,
            search: (search ?
                '?search=' + search + '&' + streamParams :
                '?' + streamParams)
        });
    }

    const fetchData = async () => {
        var activityResult = null;
        if (id.includes('activity_')) {
            activityResult = await DataServices.ActivitiesService.getActivityById(id);
            setActivity(activityResult);
            setTarget(null);
        } else if (id.includes('card_')) {
            const cardResult = await DataServices.CardsService.getCard(id);
            console.log(cardResult)
            activityResult = await DataServices.ActivitiesService.getActivityById(cardResult?.activityId);
            setActivity(activityResult);
            if (cardResult?.location) {
                const loc = JSON.parse(cardResult.location);
                //console.log(loc);
                //var centerFeature = center(loc);
                setTarget(loc);
            } else {
                setTarget(null);
            }
            setCard(cardResult);
        }

        if (!activityResult?.appendix?.arcgis?.itemId &&
            !activityResult?.appendix?.forge?.documentId) setSelectedKey('default')
    }

    const setLocation = async (location, file) => {
        var cardId = null;
        var activityId = null;
        if (id.includes('activity_')) {
            activityId = id;
            const resultCard = await DataServices.CardsService.createCardWithName(null, activityId);
            console.log(resultCard)
            if (resultCard?.id) history.push(resultCard.id)
            cardId = resultCard?.id;
        }
        if (id.includes('card_')) {
            console.log(card)
            activityId = card.activityId;
            cardId = id;
        }
        console.log('location', location)
        console.log('file', file)
        const result = await DataServices.CardsService
            .updateCardLocation(cardId, JSON.stringify(location));
        const result1 = await DataServices.CardsService.createCommentWithFile(
            '', cardId, activityId, file);

    }
    const optionsStyles = {
        innerField: {
            paddingLeft: 25,
            paddingRight: 25
        },
        labelWrapper: {
            height: 'auto'
        },
        iconWrapper: {
            fontSize: 'medium'
        },
        field: {
            padding: 0
        },
        choiceFieldWrapper: {
            boxShadow: 'rgb(0 0 0 / 13%) 0px 1.6px 3.6px 0px, rgb(0 0 0 / 11%) 0px 0.3px 0.9px 0px',
            background: 'white'
        }

    }

    const onRenderField = (props, render) => {
        return (
            <div >
                {'Hoi'}
            </div>
        );
    }

    const options = [
        {
            key: 'default',
            text: 'Standard',
            iconProps: { iconName: 'MapPin' },
            styles: optionsStyles,
        },
        {
            key: '3D',
            text: '3D',
            iconProps: { iconName: 'PanoIndicator' },
            styles: optionsStyles,
            disabled: !activity?.appendix?.arcgis?.itemId && !activity?.appendix?.forge?.documentId
        },
        {
            key: 'stream',
            text: 'Modell',
            iconProps: { iconName: 'cubeShape' },
            styles: optionsStyles,
            disabled: !activity?.appendix?.stream?.applicationId
        },
    ];

    return (
        <div>
            {/* {id.includes('activity_') && <ChoiceGroup */}
            {<ChoiceGroup
                selectedKey={selectedKey}
                options={options}
                onChange={onChange}
                styles={{
                    root: {
                        position: 'absolute',
                        zIndex: '100',
                        right: 10,
                        top: 10
                    },
                    icon: {
                        fontSize: 'xx-large'
                    }
                }} />}
            {selectedKey == 'default' && <Mapbox closeMap={() => props.closeMap()} />}
            {selectedKey == '3D' &&
                (activity?.appendix?.forge?.documentId ?
                    <MapAutodesk
                        setLocation={(l, s) => setLocation(l, s)}
                        documentId={activity?.appendix?.forge?.documentId}
                        target={target}
                        id={id}
                    />
                    :
                    <MapEsri
                        itemId={activity?.appendix?.arcgis?.itemId}
                        portalUrl={activity?.appendix?.arcgis?.portalUrl}
                        setLocation={async (l, s) => await setLocation(l, s)}
                        target={target}
                    />)
            }
            {/* 
            {activity?.appendix?.forge?.documentId ?
                <MapAutodesk
                    setLocation={(l, s) => setLocation(l, s)}
                    documentId={activity?.appendix?.forge?.documentId}
                    target={target}
                    id={id}
                />
                :
                activity?.appendix?.arcgis?.itemId ?
                    <MapEsri
                        itemId={activity?.appendix?.arcgis?.itemId}
                        portalUrl={activity?.appendix?.arcgis?.portalUrl}
                        setLocation={async (l, s) => await setLocation(l, s)}
                        target={target}
                    />
                    : <Mapbox></Mapbox>

            } */}
        </div>
    );

}

export default Map;