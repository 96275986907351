import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { loadStripe } from '@stripe/stripe-js';
import {
    getStripeSession,
    getStripeCheckoutSession,
    getStripeCustomer,
    createStripeSubscription,
    getStripePrice
} from "../../shared/data-services/graphql";
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';
import { DefaultButton, PrimaryButton, Stack, IStackTokens, Label } from 'office-ui-fabric-react';
import { ProgressIndicator } from 'office-ui-fabric-react/lib/ProgressIndicator';
import StripeCard from "./WebAppStripeCard";
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { Text } from 'office-ui-fabric-react/lib/Text';
import moment from 'moment';
moment().format();
moment.locale();

const Stripe = (props) => {

    useEffect(() => {
        loadStripeCustomer();
        loadStripePrice();
    }, []);

    const [showPayment, setShowPayment] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [plan, setPlan] = useState(null);
    const [loading, setLoading] = useState(false);

    const modalPropsStyles = {
        main: {
            //maxWidth: 1000,
            //minHeight: 400,
            //height: 400
        }
    };
    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Flinker PRO',
        subText: '',
        styles: {
            header: { textAlign: 'center' },
            content: { textAlign: 'center' }
        }
    };
    const modalProps = {
        isBlocking: true,
        styles: modalPropsStyles,
    };

    const loadStripeSession = async (add) => {
        try {
            const result = await API.graphql(graphqlOperation(getStripeSession));
            const currentSession = JSON.parse(result.data.getStripeSession);
            if (currentSession.url) {
                window.open(currentSession.url + add);
            }
        } catch (e) {
            console.log(e);
        }
    }


    const loadStripeCheckoutSession = async () => {
        try {
            const result = await API.graphql(graphqlOperation(getStripeCheckoutSession));
            console.log(result)
        } catch (e) {
            console.log(e);
        }
    }

    const loadStripePrice = async () => {
        try {
            const result = await API.graphql(graphqlOperation(getStripePrice, { country: 'DE' }));
            const parsed = JSON.parse(result.data.getStripePrice);
            console.log(parsed)
            if (parsed.price.object == 'price') setPlan(parsed);

        } catch (e) {
            console.log(e);
        }
    }

    const loadStripeCustomer = async () => {
        try {
            const result = await API.graphql(graphqlOperation(getStripeCustomer));
            const parsed = JSON.parse(result.data.getStripeCustomer);
            console.log(parsed)
            if (parsed.object == 'customer') setCustomer(parsed);
        } catch (e) {
            console.log(e);
        }
    }


    const showPlan = async (event) => {
        setLoading(true);
        await loadStripeSession('');
        setLoading(false);
    }
    const cancelPlan = async (event) => {
        setLoading(true);
        const add = '/subscriptions/' + customer.subscriptions.data[0].id + '/cancel';
        await loadStripeSession(add);
        setLoading(false);
    }
    const reactivatePlan = async (event) => {
        setLoading(true);
        const add = '/subscriptions/' + customer.subscriptions.data[0].id + '/reactivate';
        await loadStripeSession(add);
        setLoading(false);
    }


    const getPro = async (event) => {

    };

    function toDateTime(secs) {
        var t = new Date(1970, 0, 1); // Epoch
        t.setSeconds(secs);
        return t;
    }

    const activeSub = customer && customer.subscriptions &&
        customer.subscriptions.data.length > 0 ?
        customer.subscriptions.data[0] : null;

    const PriceDetails = (input) => {

        var pricePerUser = null;
        var tax = null;
        var user = null;
        if (plan && plan.taxRate) tax = plan.taxRate.percentage;
        if (activeSub) {
            pricePerUser = activeSub.plan.tiers[0].unit_amount;
            user = activeSub.quantity;
        } else if (customer && plan) {
            pricePerUser = plan.price.tiers[0].unit_amount;
            user = plan.team.length;
        } else {
            return null
        }

        return (
            <div>
                {activeSub &&
                    <Label
                        styles={{
                            root: {
                                display: 'inline-block',
                                borderRadius: 5,
                                paddingRight: 8,
                                paddingLeft: 8,
                                paddingBottom: 2,
                                paddingTop: 2,
                                marginTop: 4,
                                marginBottom: 4,
                                backgroundColor: activeSub.cancel_at_period_end ? '#eeeeee' : 'lightgreen',
                                fontSize: 'small'
                            }
                        }}
                    >
                        {activeSub.cancel_at_period_end ? 'Gekündigt' : 'Aktiv'}
                    </Label>
                }
                {plan && !activeSub &&
                    <Label
                        styles={{
                            root: {
                                display: 'inline-block',
                                borderRadius: 5,
                                paddingRight: 8,
                                paddingLeft: 8,
                                paddingBottom: 2,
                                paddingTop: 2,
                                marginTop: 4,
                                marginBottom: 4,
                                backgroundColor: '#eeeeee',
                                fontSize: 'small'
                            }
                        }}
                    >Jetzt Flinker PRO aktivieren - jederzeit kündbar</Label>
                }
                {
                    <div styles={{ display: 'block' }}>
                        <Text variant='xLarge'>
                            {(pricePerUser * user / 100).toFixed(2) + ' € '}
                        </Text>
                        <Text variant='small'>
                            {'zzgl. ' + (tax ? tax + '% ' : '') + 'MwSt.'}
                        </Text>
                    </div>
                }
                <Text block variant='medium' styles={{ root: { marginTop: 5, color: 'gray' } }} >
                    {user + ' Benutzer, '}
                    {(pricePerUser / 100).toFixed(2) +
                        ' € pro Benutzer im Monat'}
                </Text>
            </div >
        )
    };

    return (
        <div>
            <PriceDetails />
            {activeSub &&
                <Text block variant='medium' styles={{ root: { marginTop: 20, marginBottom: 20 } }} >
                    {
                        'Das Abonnement wird am ' +
                        moment.unix(activeSub.current_period_end).format('LL') +
                        (activeSub.cancel_at_period_end ? ' gekündigt.' : ' verlängert.')
                    }
                </Text>
            }
            {activeSub &&
                <div>
                    <PrimaryButton
                        text="Abrechnung"
                        onClick={showPlan}
                        styles={{
                            root: {
                                borderRadius: 50
                            }
                        }} >
                    </PrimaryButton>
                    <DefaultButton
                        text={activeSub.cancel_at_period_end ? 'Verlängern' : "Kündigen"}
                        onClick={activeSub.cancel_at_period_end ? reactivatePlan : cancelPlan}
                        styles={{
                            root: {
                                marginLeft: 10,
                                borderRadius: 50
                            }
                        }} />
                </div>}
            {customer && !activeSub &&
                <div>
                    <PrimaryButton
                        text="Jetzt PRO holen"
                        onClick={() => setShowPayment(true)}
                        styles={{
                            root: {
                                marginTop: 20,
                                borderRadius: 50
                            }
                        }} />
                    <DefaultButton
                        text="Abrechnung"
                        onClick={showPlan}
                        styles={{
                            root: {
                                marginTop: 20,
                                marginLeft: 10,
                                borderRadius: 50
                            }
                        }} />
                </div>
            }
            {loading && <ProgressIndicator />}
            <Dialog
                minWidth={500}
                hidden={!showPayment}
                onDismiss={() => setShowPayment(false)}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}>
                <PriceDetails style={{ padding: 20 }} />
                <StripeCard
                    pk={plan ? plan.publicKey : null}
                    profile={props.profile}
                    payment={plan ? plan.defaultPaymentMethod : null}
                    onSubscription={() => loadStripeCustomer()} />
            </Dialog>
        </div >
    );
}


export default Stripe
