import React, { useState, useEffect } from 'react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';
import {
    listContacts,
    listContactsInvited,
    inviteContact,
    removeContactFromActivity,
    disinviteContact
} from "../../shared/data-services/graphql";
import Strings from "./strings";
import {
    DetailsList,
    DetailsListLayoutMode,
    IColumn,
    IDetailsFooterProps,
    DetailsRow,
    SelectionMode,
    DetailsRowCheck,
    IDetailsRowBaseProps,
    IDetailsRowCheckStyles,
    Selection
} from 'office-ui-fabric-react/lib/DetailsList';
import PeoplePicker from './WebAppPeoplePicker'
import {
    MessageBarButton,
    Link,
    MessageBar,
    MessageBarType,
} from 'office-ui-fabric-react';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { DataServices } from "../../shared/data-services/DataServices";


const buttonStyles = { root: { marginRight: 8 } };

const _columns = [
    { key: 'column1', name: 'E-Mail-Adresse', fieldName: 'name', minWidth: 150, maxWidth: 200, isResizable: true, isMultiline: true },
    { key: 'column2', name: 'Status', fieldName: 'value', minWidth: 60, maxWidth: 200, isResizable: true },
];

const Contacts = (props) => {

    const [contacts, setContacts] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);


    useEffect(() => {
    }, []);


    const dismissPanel = async () => {
        setSelectedItem(null);
        props.onClose();
    }

    const items = props.contacts.map(contact => {
        return {
            name: contact.email,
            key: contact.id
        }
    });

    const modalPropsStyles = {
        main: {
            minHeight: 400
        }
    };
    
    const iconProps = { iconName: 'IncreaseIndentLegacy' };
    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Zuweisen an',
    };
    const modalProps = {
        isBlocking: true,
        styles: modalPropsStyles,
    };

    const _selection = new Selection({
        onSelectionChanged: () => {
            setSelectedItem(_selection.getSelection()[0]);
            console.log(_selection.getSelection()[0])
        },
    });

    const _onRenderRow = props => {
        const customStyles = {};
        if (props) {
            if (props.itemIndex % 2 === 0) {
                // Every other row renders with a different background color
                //customStyles.root = { backgroundColor: theme.palette.themeLighterAlt };
            }

            return (
                <DetailsRow {...props} styles={customStyles} />

            );
        }
        return null;
    };

    return (
        <Dialog
            minWidth={400}
            //maxWidth={400}
            hidden={!props.show}
            onDismiss={dismissPanel}
            dialogContentProps={dialogContentProps}
            modalProps={modalProps}>
            <Stack verticalAlign="space-between" styles={{ root: { minHeight: 300 } }}>
                <DetailsList
                    items={items}
                    columns={_columns}
                    setKey="set"
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionPreservedOnEmptyClick={false}
                    selection={_selection}
                    onRenderRow={_onRenderRow}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    checkButtonAriaLabel="Row checkbox"
                    isHeaderVisible={false}
                    selectionMode={SelectionMode.single}
                />
                <DialogFooter>
                    <PrimaryButton
                        text={"Zuweisen"}
                        onClick={() => props.onSelection(selectedItem?.key)}
                        allowDisabledFocus
                        styles={{
                            root: {
                                borderRadius: 20
                            }
                        }}
                    />
                </DialogFooter>
            </Stack>
        </Dialog>
    );
}

export default Contacts