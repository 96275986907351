import { TextField, ITextFieldStyles } from 'office-ui-fabric-react/lib/TextField';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { IconButton } from '@fluentui/react/lib/Button';
import { DataServices } from "../../shared/data-services/DataServices";
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import React, { useEffect, useState } from 'react';
import {
    useHistory, useParams,
    useRouteMatch
} from "react-router-dom";

var timer;


const Searchbar = (props) => {

    const history = useHistory();
    let { id } = useParams();
    const initSearch = new URLSearchParams(history.location.search).get("search");
    const [searchParam, setSearchParam] = useState(initSearch ? initSearch : '');
    const [searchValue, setSearchValue] = useState(initSearch ? initSearch : '');

    useEffect(() => {
        const initSearch = new URLSearchParams(history.location.search).get("search");
        setSearchValue(initSearch ? initSearch : '');
    }, [id]);

    const _onChangeText = (ev, text) => {

        setSearchValue(text || '');
        clearTimeout(timer);
        timer = setTimeout(() => history.push({
            pathname: id,
            search: text ? '?search=' + text : ''
        }), 300);

    };

    return (
        <TextField
            defaultValue={initSearch ? initSearch : ''}
            value={searchValue}
            placeholder="Suche nach Titel, Personen, Ids ..."
            onChange={_onChangeText}
            iconProps={{ iconName: 'Search' }}
            styles={{
                fieldGroup: {
                    width: '100%',
                    background: searchValue ? '#3771c840' : '#fafafa',
                },
                root: {
                    marginLeft: 10,
                    marginRight: 10,
                    marginBottom: 10,
                }
            }} />
    );
};

export default Searchbar