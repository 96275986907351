import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { IconButton } from '@fluentui/react/lib/Button';
import { filteredAssign } from 'office-ui-fabric-react';
import imageCompression from 'browser-image-compression';
import { Link } from '@fluentui/react';

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    marginRight: 8,
    width: 60,
    height: 40,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
    borderRadius: 4
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};


function Previews(props) {
    const [files, setFiles] = useState([]);
    const { getRootProps, getInputProps, open } = useDropzone({
        accept: 'image/*',
        maxFiles: 1,
        onDrop: async acceptedFiles => processFiles(acceptedFiles)
    });


    async function processFiles(acceptedFiles) {
        const compressedFiles = [];
        for (let acceptedFile of acceptedFiles) {
            const compressedFile = await handleImageUpload(acceptedFile);
            compressedFiles.push(compressedFile);
        }

        setFiles(compressedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
        props.onLoad(compressedFiles[0]);
    }

    document.onpaste = async function (event) {
        const files = [];
        var items = (event.clipboardData || event.originalEvent.clipboardData).items;
        for (let index in items) {
            var item = items[index];
            if (item.kind === 'file') {
                console.log(item.getAsFile())
                files.push(item.getAsFile());
                return processFiles(files);
            }
        }
    }


    async function handleImageUpload(imageFile) {

        const options = {
            maxSizeMB: props.maxSize ? props.maxSize : 1,
            maxWidthOrHeight: props.maxWidth ? props.maxWidth : 1024,
            useWebWorker: true
        }
        try {
            const compressedFile = await imageCompression(imageFile, options);
            return compressedFile;
        } catch (error) {
            console.log(error);
            return error;
        }

    }

    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    src={file.preview}
                    style={img}
                />
            </div>
        </div>
    ));

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    return (
        <section className="container">
            {props.selected &&
                <aside style={thumbsContainer}>
                    {thumbs}
                </aside>
            }
            {!props.selected &&
                <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    {props.text ?
                        <Link >{props.text}</Link>
                        :
                        <IconButton
                            iconProps={{ iconName: 'Attach' }}
                            title="Attach"
                            ariaLabel="Attach"
                        />
                    }
                </div>
            }
        </section>
    );
}

export default Previews