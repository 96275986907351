import { Card } from '@uifabric/react-cards';
import moment from 'moment';
import 'moment/locale/de'; // without this line it didn't work
import { ActionButton, CommandBarButton, PrimaryButton } from 'office-ui-fabric-react';
import { Persona, PersonaPresence, PersonaSize } from "office-ui-fabric-react/lib/Persona";
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import React, { useEffect, useState } from 'react';
import {
    Link, useHistory,
    useParams
} from "react-router-dom";
import awsconfig from '../../shared/data-services/aws-exports';
import { DataServices } from "../../shared/data-services/DataServices";
import CardComments from "./CardComments";
import ImageGallery from "./ImageGallery";
import Linkify from './Linkify';
import SelectContact from "./SelectContact";
import { Scrollbars } from 'react-custom-scrollbars';

moment.locale('de')

const cardTokens = { childrenMargin: 0, width: '100%', maxWidth: '100%' };
const narrowTextFieldStyles = { fieldGroup: { width: '100%' } };
const stackTokens = { childrenGap: 20, padding: 40 };

const name = '';
const content = '';

const CardView = (props) => {

    const history = useHistory();
    const params = new URLSearchParams(history.location.search);
    const search = params.get("search");
    let { id } = useParams();

    const [url, setURL] = useState(null);
    const [images, setImages] = useState([]);
    const [card, setCard] = useState(null);
    const [error, setError] = useState(null);
    const [name, setName] = useState('');
    const [content, setContent] = useState('');
    const [assignee, setAssignee] = useState('');
    const [creator, setCreator] = useState('');
    const [showContacts, setShowContacts] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [editContent, setEditContent] = useState(false);
    const [fileKey, setFilekey] = useState(null);
    const [cardId, setCardId] = useState(id);
    const [activity, setActivity] = useState(null);
    const [searchParam, setSearchParam] = useState(search ? search : '');
    const [files, setFiles] = useState([]);

    useEffect(() => {
        fetchData()
    }, [cardId]);



    const toUrl = (itemId) => {
        history.push({
            pathname: itemId,
            search: history.location.search ? history.location.search : ''
        })
    }

    const onChangeName = React.useCallback(
        (event, newValue) => {
            setName(newValue || '');
        },
        [],
    );

    const onChangeContent = React.useCallback(
        (event, newValue) => {
            setContent(newValue || '');
        },
        [],
    );

    const updateCard = async (assigneeId) => {
        await DataServices.CardsService
            .updateCardText(card?.id, name, content, assigneeId);
        setShowContacts(false);
        fetchData();
    }



    history.listen(async (location, action) => {

        const result = location.pathname.substring(
            location.pathname.lastIndexOf('/') + 1);
        setCardId(result);

        const params = new URLSearchParams(location.search);
        const search = params.get("search");
        setSearchParam(search);

    });

    const fetchData = async () => {
        setEditContent(false);
        const resultCard = await DataServices.CardsService.getCard(id);
        if (resultCard) {
            setCard(resultCard);
            setName(resultCard.name);
            setContent(resultCard.content);

            const resultContacts = await DataServices.ContactsService
                .getContactsForActivity(resultCard.activityId);
            setContacts(resultContacts)
            setAssignee(resultContacts
                .find(c => c.id == resultCard.assigneeId));
            setCreator(resultContacts
                .find(c => c.id == resultCard.createdBy));

            const resultActivity = await DataServices.ActivitiesService.getActivityById(resultCard.activityId);
            setActivity(resultActivity);

            const resultFiles = await DataServices.CardsService.getFiles(id);
            console.log('comments files', resultFiles)
            setFiles(resultFiles);
        }

        console.log(resultCard)
    }

    const { classes } = props;

    const match = props.match;

    if (card)
        return (
            <Card
                aria-label=""
                vertical
                tokens={cardTokens}
                styles={{
                    root: {
                        height: '100%',
                        backgroundColor: 'white',
                        overflowY: 'auto',
                        background: 'white'
                    }
                }}>
                <Scrollbars autoheight>
                    <Card.Section>
                        <CommandBarButton
                            text={activity?.name}
                            iconProps={{ iconName: 'Back' }}
                            title="Back"
                            ariaLabel="Back"
                            styles={{
                                root: {
                                    paddingLeft: 20,
                                    marginLeft: 100,
                                    height: 50
                                }
                            }}
                            onClick={() => {
                                if (card.activityId) toUrl(card.activityId)
                            }}
                        />
                        <PrimaryButton
                            text="Speichern"
                            title="Save"
                            ariaLabel="Save"
                            //disabled={}
                            styles={{
                                root: {
                                    margin: '0 auto',
                                    borderRadius: 50,
                                    minHeight: 32,
                                    visibility: (!card || (card.name == name && card.content == content)) ? 'hidden' : 'visible'
                                }
                            }}
                            onClick={async () => {
                                await DataServices.CardsService
                                    .updateCardText(card.id, name, content);
                                fetchData();
                            }}
                        />
                        <Stack tokens={stackTokens}>
                            {card && !card.open && <ActionButton
                                iconProps={{ iconName: 'Delete' }}
                                text={"Card löschen"}
                                title="Card löschen"
                                ariaLabel="Löschen"
                                onClick={async () => {
                                    await DataServices.CardsService.deleteCard(card.id);
                                    if (card.activityId) toUrl(card.activityId);
                                }}
                            />}
                            <Persona
                                text={(assignee ? (assignee.name ? assignee.name : assignee.email) : '')}
                                size={PersonaSize.size40}
                                secondaryText={
                                    <span>
                                        {(card && card.assigneeId) ? (card.viewedByAssignee ? 'gesehen ' : 'zugewiesen ') : ''}
                                        <Link onClick={() => setShowContacts(true)}>
                                            {!assignee ? 'Zuweisen an' : 'bearbeiten'}
                                        </Link>
                                    </span>
                                }
                                presence={(card && card.assigneeId) ?
                                    (!card.viewedByAssignee ? PersonaPresence.offline : PersonaPresence.online) :
                                    PersonaPresence.none}
                                imageUrl={DataServices.ContactsService.getAvatar(assignee?.id, assignee?.updatedAt)}
                            />
                            <SelectContact
                                contacts={contacts}
                                show={showContacts}
                                onSelection={(id) => updateCard(id)}
                                onClose={() => setShowContacts(false)}
                            />
                            <TextField
                                placeholder="Titel"
                                borderless
                                multiline
                                autoAdjustHeight
                                value={name}
                                onChange={onChangeName}
                                styles={{
                                    field: {
                                        fontSize: 'xx-large',
                                        lineHeight: 'normal',
                                        paddingLeft: 0,
                                        textDecoration: card && !card.open ? 'line-through' : 'undefined'
                                    },
                                    fieldGroup: { width: '100%' }
                                }}
                            />
                            <div>
                                <ActionButton
                                    menuIconProps={{ iconName: editContent ? 'ChromeClose' : 'EditSolid12' }}
                                    text={editContent ? 'Abbrechen' : "Beschreibung"}
                                    title="Bearbeiten"
                                    ariaLabel="Bearbeiten"
                                    styles={{
                                        root: {
                                            color: 'gray',
                                            paddingLeft: 0
                                        },
                                        label: {
                                            marginLeft: 0
                                        },
                                    }}
                                    onClick={() => setEditContent(!editContent)}
                                />
                                {editContent ?
                                    <TextField
                                        autoAdjustHeight
                                        multiline
                                        borderless
                                        value={content}
                                        placeholder="Gib eine Beschreibung ein..."
                                        onChange={onChangeContent}
                                        styles={narrowTextFieldStyles}
                                    />
                                    :
                                    <Text
                                        multiline
                                        autoAdjustHeight
                                        borderless
                                        block={true}
                                        nowrap={false}
                                        placeholder="Beschreibung"
                                        onChange={onChangeContent}
                                        styles={narrowTextFieldStyles}
                                        style={{ whiteSpace: "pre-line" }}
                                    >
                                        <Linkify value={content} />
                                    </Text>
                                }
                            </div>
                        </Stack >
                        {card && card.files && (card.files.length > 0) &&
                            <ImageGallery
                                files={card.files}
                                signedFiles={files}
                            />
                        }
                    </Card.Section>
                    {card && <CardComments
                        card={card}
                        profile={props.profile}
                        files={files}
                    />}
                </Scrollbars>
            </Card >

        );
    else
        return (
            <Card
                aria-label="Clickable horizontal card "
                vertical
                tokens={cardTokens}
                styles={{
                    root: {
                        height: '100%',
                        backgroundColor: 'white',
                        overflow: 'scroll'
                    }
                }}>
                <Text variant="xxLarge" styles={{
                    root: {
                        margin: 'auto',
                        color: 'lightGray',
                    }
                }}>
                    {'Card nicht gefunden'}
                </Text>
            </Card>
        )


}


export default CardView;