
import React, { useEffect, useState } from 'react';
import useScript from './useScript';
import { CompoundButton, IconButton, CommandBarButton, ActionButton } from '@fluentui/react/lib/Button';
import { MessageBar, MessageBarType, Toggle, Text, mergeStyles } from '@fluentui/react';
import { Spinner } from '@fluentui/react/lib/Spinner';
import { Label } from '@fluentui/react/lib/Label';
import { ProgressIndicator } from '@fluentui/react/lib/ProgressIndicator';
import { Progress } from './Progress';
import { AppstreamPreview } from './AppstreamPreview';
import computer from "./computer-rocket.png";
import enscape from "./images/enscape.png";
import navisworks from "./images/navisworks.png";
import { DataServices } from "../../shared/data-services/DataServices";
import { Image, Stack } from 'office-ui-fabric-react';
import { useHistory, useParams } from 'react-router-dom';

const Appstream = (props) => {
    useScript('./appstream_embed.js');

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const { id } = useParams();

    const [streamButton, setStreamButton] = useState(true);
    const [info, setInfo] = useState('');
    const [appstream, showAppstream] = useState(false);
    const [percentComplete, setPercentComplete] = React.useState(0);
    const [timer, showTimer] = React.useState(false);
    const [profile, setProfile] = React.useState(null);
    const [activity, setActivity] = React.useState(null);
    const [sessionContext, setSessionContext] = React.useState(params.sessionContext);
    const [activityId, setActivityId] = React.useState(id.includes("activity_") ? id : null);
    var applicationId = params.applicationId;



    if ('SecurityPolicyViolationEvent' in window) { // Check browser support
        window.addEventListener('securitypolicyviolation', function (e) {
            console.log(e.violatedDirective, e.originalPolicy);
        });
    }

    const isLocalhost = Boolean(
        window.location.hostname === "localhost" ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === "[::1]" ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(
            /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
    );

    useEffect(() => {
        getData();
    }, [props.update]);

    var appstreamEmbed;

    const getData = async () => {
        setProfile(null);
        const resultProfile = await DataServices.ContactsService.getProfile();
        setProfile(resultProfile);
        if (activityId) {
            const activityResult = await DataServices.ActivitiesService.getActivityById(activityId);
            setSessionContext(activityResult?.appendix?.stream?.sessionContext)
            setActivity(activityResult);
        }
    }

    const getFileName = () => {
        try {
            const donwloadURL = sessionContext.split(';').pop();
            const decodedURL = decodeURIComponent(donwloadURL);
            return decodedURL.split('/').pop();
        } catch (e) {
            return 'Unbekannt'
        }
    }

    const getPreview = () => {
        try {
            return getFileName().toLowerCase().includes('.exe') ? enscape :
                (getFileName().toLowerCase().includes('.nwd') ? navisworks : '');
        } catch (e) {
            return null
        }
    }

    const launchAppStreamSession = async () => {

        destroyAppStreamFrame();
        setStreamButton(false);
        showTimer(true);

        checkCookie();

        const data = await getStreamingURL();
        const hiddenItems = getHiddenElements();
        var userInterfaceConfig = {};
        userInterfaceConfig[window.AppStream.Embed.Options.HIDDEN_ELEMENTS] =
            hiddenItems;

        if (data && data.StreamingURL && data.StreamingURL.length > 0) {
            var newUrl = data.StreamingURL.replace('appstream2.eu-central-1.aws.amazon.com', 'stream.s.flinker.app');
            console.log(newUrl)
            var appstreamOptions = {
                sessionURL: newUrl,
                userInterfaceConfig: userInterfaceConfig,
            };
            appstreamEmbed = new window.AppStream.Embed(
                "appstream-container",
                appstreamOptions
            );
            appstreamEmbed.addEventListener(
                window.AppStream.Embed.Events.SESSION_STATE_CHANGE,
                updateSessionStateCallback
            );
            appstreamEmbed.addEventListener(
                window.AppStream.Embed.Events.SESSION_INTERFACE_STATE_CHANGE,
                updateUserInterfaceStateCallback
            );
            appstreamEmbed.addEventListener(
                window.AppStream.Embed.Events.SESSION_ERROR,
                errorCallback
            );
            console.log(appstreamEmbed)
        } else if (data.message) {
            showTimer(false);
            setStreamButton(true)
            setInfo(data.message);
        } else {
            showTimer(false);
            setStreamButton(true)
            setInfo('Failed to get streaming URL');
        }
    };

    const destroyAppStreamFrame = () => {
        if (appstreamEmbed) {
            appstreamEmbed.destroy();
            appstreamEmbed = null;
        }
        showAppstream(false);
        setStreamButton(true);
        setInfo(false);
        showTimer(false);
        /*         document.getElementById("in-stream-functions").style.visibility =
                    "collapse";
                document.getElementById("in-stream-functions").style.display = "none"; */
    }

    const updateSessionStateCallback = (event) => {
        let status = event[window.AppStream.Embed.EventParams.STATUS];
        console.log(status)
        switch (window.AppStream.Embed.SessionStatus[status]) {
            case window.AppStream.Embed.SessionStatus.Unknown:
                break;
            case window.AppStream.Embed.SessionStatus.Started:
                setStreamButton(false);
                showTimer(false)
                showAppstream(true);
                document.getElementById("in-stream-functions").style.display =
                    "block";
                break;
            default:
                break;
        }
        console.log(
            "embed-sample.html listened to updateSessionState event: " +
            JSON.stringify(event)
        );
    }

    function updateUserInterfaceStateCallback(event) {
        console.log(
            "embed-sample.html listened to updateUserInterfaceState event: " +
            JSON.stringify(event)
        );
    }

    function errorCallback(event) {
        if (isLocalhost && event.errorCode == '500') {
            setStreamButton(false);
            showTimer(false)
            showAppstream(true);
            document.getElementById("in-stream-functions").style.display =
                "block";
        } else {
            showAppstream(false);
            showTimer(false)
            setStreamButton(true);
            console.log(
                "embed-sample.html listened to appstreamEmbedError event: " +
                JSON.stringify(event)
            );
            setInfo(event.errorMessage);
        }
    }

    const getStreamingURL = async () => {
        let userId = profile.id.replace('contact_', '');
        var encodedSessionContext = sessionContext;
        let url =
            "https://kx8tiqn8i7.execute-api.eu-central-1.amazonaws.com/" +
            "?userId=" +
            userId +
            (applicationId && sessionContext
                ? "&applicationId=" +
                applicationId +
                "&sessionContext=" +
                encodedSessionContext
                : "");
        let response = await fetch(url);
        let data = await response.json();
        console.log("appstream", data.sessionContext);
        console.log(data);
        if (data) {
            return data;
        } else {
            return null;
        }
    };

    function getHiddenElements() {
        var hiddenItems = [];
        hiddenItems.push(window.AppStream.Embed.Elements.TOOLBAR);
        return hiddenItems;
    }

    function as2LaunchAppSwitcher() {
        appstreamEmbed.launchAppSwitcher();
    }

    const as2EndSession = () => {
        appstreamEmbed.endSession();
        destroyAppStreamFrame();
    }

    function as2LaunchApp() {
        const appId = getAppId();
        appstreamEmbed.launchApp(appId);
    }

    function getAppId() {
        return document.getElementById("app-id-input").value;
    }
    function getCookie(c_name) {
        var c_value = document.cookie;
        var c_start = c_value.indexOf(" " + c_name + "=");
        if (c_start == -1) {
            c_start = c_value.indexOf(c_name + "=");
        }
        if (c_start == -1) {
            c_value = null;
        } else {
            c_start = c_value.indexOf("=", c_start) + 1;
            var c_end = c_value.indexOf(";", c_start);
            if (c_end == -1) {
                c_end = c_value.length;
            }
            c_value = unescape(c_value.substring(c_start, c_end));
        }
        return c_value;
    }

    function setCookie(c_name, value) {
        var exdate = new Date();
        exdate.setTime(exdate.getTime() + (15 * 60 * 1000)); // expires after 15 minutes
        var c_value =
            escape(value) +
            (exdate == null ? "" : "; expires=" + exdate.toUTCString());
        console.log(c_value)
        document.cookie = c_name + "=" + c_value;
    }
    function checkCookie() {
        var username = getCookie("stream");
        if (username != null && username != "") {
            return username;
        } else {
            username = profile.id.replace('contact_', '');;
            if (username != null && username != "") {
                setCookie("stream", username);
                return username;
            }
        }
    }
    function deleteCookie(name, path, domain) {
        if (getCookie(name)) {
            document.cookie = name+'=; Max-Age=-99999999;';  
/*             document.cookie = name + "=" +
                ((path) ? ";path=" + path : "") +
                ((domain) ? ";domain=" + domain : "") +
                ";expires=Thu, 01 Jan 1970 00:00:01 GMT"; */
        }
    }

    return (
        <Stack
            verticalFill
            styles={{
                root: {
                    flex: 'auto',
                    height: '100vh',
                    overflowX: 'hidden'
                }
            }}>
            {info && <MessageBar
                styles={{
                    root: { paddingLeft: 50 },
                    content: { margin: 'auto' },
                    iconContainer: { fontSize: 'medium' },
                    text: { fontSize: 'medium' }
                }}
                dismissButtonAriaLabel="Close"
                delayedRender={false}
                onDismiss={() => setInfo(false)}
                messageBarType={MessageBarType.info}
            >
                {info}
            </MessageBar>}
            <Stack
                tokens={{ padding: 0 }}
                styles={{
                    root: {
                        margin: "auto",
                        width: 'max',
                        position: 'absolute',
                        right: 0,
                        left: props.showContent && false ? 500 : 0,
                        maxWidth: 480,
                        paddingTop: 100
                    }
                }}
                vertical
                verticalAlign='center'>
                {!appstream && <Image
                    styles={{
                        root: {
                            margin: "auto"
                        }
                    }}
                    src={computer}
                    alt="Flinker"
                    width={100}
                />}
                {!appstream && <ActionButton
                    text={profile ? 'Aktualisieren' : 'Lädt...'}
                    iconProps={{ iconName: 'Sync' }}
                    onClick={() => getData()}
                    styles={{
                        root: {
                            margin: 'auto'
                        },
                    }}
                />}
                {timer ?
                    <Progress /> :
                    (!appstream && <Text
                        styles={{
                            root: {
                                textAlign: 'center',
                                color: 'gray',
                                margin: 10
                            }
                        }}>
                        {applicationId && sessionContext ?
                            'Wähle die Datei und starte den Rechner' :
                            activityId && !activity ?
                                'Aktivität nicht gefunden' :
                                'Noch keine Dateien vorhanden'
                        }
                    </Text>)}
                {streamButton && applicationId && sessionContext && <AppstreamPreview
                    name={profile && profile.name ? profile.name : 'Unbekannt'}
                    title={getFileName()}
                    activity={profile && profile.email ? profile.email : ''}
                    preview={getPreview()}
                    onClick={() => {
                        launchAppStreamSession();
                    }}
                />}
            </Stack>
            <div id="in-stream-functions"></div>
            {/*             <!-- < button type="button" onclick="destroyAppStreamFrame()" > Destroy Frame</button > -->
 */}

            {(appstream || timer) &&
                <Stack
                    styles={{
                        root: {
                            position: 'absolute',
                            top: 0,
                            left: props.showActivity ? 500 : 0,
                            right: 0,
                            zIndex: 10,
                            background: 'white',
                            width: 100,
                            height: 30,
                            margin: 'auto',
                            boxShadow: 'rgb(0 0 0 / 13%) 0px 1.6px 3.6px 0px, rgb(0 0 0 / 11%) 0px 0.3px 0.9px 0px'
                        }
                    }}>
                    <IconButton
                        iconProps={{ iconName: 'System' }}
                        menuProps={{
                            items: [
                                {
                                    key: 'Refresh',
                                    text: 'Aktualisieren',
                                    iconProps: { iconName: 'Refresh' },
                                    onClick: () => {
                                        deleteCookie("stream", null, null);
                                        launchAppStreamSession();
                                    }
                                },
                                {
                                    key: 'PowerButton',
                                    text: 'Ausschalten',
                                    iconProps: { iconName: 'PowerButton' },
                                    onClick: () => destroyAppStreamFrame()
                                },
                            ],
                            directionalHintFixed: true,
                        }}
                        title="Rechner"
                        ariaLabel="Rechner"
                    />
                </Stack>}
            <div
                style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    left: 0,
                    top: 0,
                    visibility: appstream ? 'visible' : 'hidden'
                }}
                id="appstream-container">
            </div>
        </Stack >
    );

}


export default Appstream;