import * as React from 'react';
import {
    DocumentCard,
    DocumentCardActivity,
    DocumentCardDetails,
    DocumentCardPreview,
    DocumentCardTitle,
    IDocumentCardPreviewProps,
    DocumentCardType,
} from '@fluentui/react/lib/DocumentCard';
import { getTheme } from '@fluentui/react/lib/Styling';

const theme = getTheme();

export const AppstreamPreview = (props) => {

    const previewProps = {
        getOverflowDocumentCountText: (overflowCount) => `+${overflowCount} more`,
        previewImages: [
            {
                name: 'Revenue stream proposal fiscal year 2016 version02.pptx',
                linkProps: {
                    href: 'http://bing.com',
                    target: '_blank',
                },
                previewImageSrc: props.preview,
                //iconSrc: TestImages.iconPpt,
                //imageFit: 'contain',
                width: 64,
                height: 'auto'
            }
        ],
        styles: { root: { paddingLeft: 30, paddingRight: 30, paddingTop: 20 } },
    };

    return (
        <DocumentCard
            style={{ boxShadow: theme.effects.elevation8, border: 'none' }}
            aria-label={
                ''
            }
            type={DocumentCardType.compact}
            onClickHref=""
            onClick={() => props.onClick()}
        >
            <DocumentCardPreview {...previewProps} />
            <DocumentCardDetails>
                <DocumentCardTitle title={props.title} shouldTruncate />
                <DocumentCardActivity
                    activity={props.activity}
                    people={[{ name: props.name, profileImageSrc: '' }]} />
            </DocumentCardDetails>
        </DocumentCard>
    );
};
