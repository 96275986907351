import React, { useEffect, useState } from 'react';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import { IPersonaProps } from 'office-ui-fabric-react/lib/Persona';
import { IBasePickerSuggestionsProps, NormalPeoplePicker, ValidationState } from 'office-ui-fabric-react/lib/Pickers';
import { DataServices } from "../../shared/data-services/DataServices";

const suggestionProps = {
    //suggestionsHeaderText: 'Suggested People',
    mostRecentlyUsedHeaderText: 'Suggested Contacts',
    noResultsFoundText: 'No results found',
    loadingText: 'Loading',
    showRemoveButtons: true,
    suggestionsAvailableAlertText: 'People Picker Suggestions available',
    suggestionsContainerAriaLabel: 'Suggested contacts',
};

const checkboxStyles = {
    root: {
        marginTop: 10,
    },
};

const PeoplePickerNormalExample = (props) => {
    const [currentSelectedItems, setCurrentSelectedItems] = React.useState([]);
    const [delayResults, setDelayResults] = React.useState(false);
    const [isPickerDisabled, setIsPickerDisabled] = React.useState(false);
    const [peopleList, setPeopleList] = React.useState([]);

   /*  useEffect(() => {
        listContacts();
    }, []);


    const listContacts = async () => {
        const result = await DataServices.ContactsService.listAllContacts();
        console.log(result)
        setPeopleList(result.map(c => {
            return {
                text: c.name,
                secondaryText: c.email
            }
        }));
    }
 */
    const picker = React.useRef(null);

    const onItemsChange = (items) => {
        setCurrentSelectedItems(items);
        setPeopleList([]) //remove all suggestions
        //setPeopleList(peopleList.filter(p => p.key !== 0)) //remove suggestions
        props.onChange(items)
    };

    const onFilterChanged = (filterText, currentPersonas, limitResults) => {
        console.log(filterText)
        if (filterText) {
            //let filteredPersonas = filterPersonasByText(filterText);
            //console.log(filteredPersonas)
            return convertResultsToPromise(peopleList)
        } else {
            return [];
        }
    };


    const filterPersonasByText = filterText => {
        return peopleList.filter(item => doesTextStartWith(item.text, filterText));
    };

    /**
* Takes in the picker input and modifies it in whichever way
* the caller wants, i.e. parsing entries copied from Outlook (sample
* input: "Aaron Reid <aaron>").
*
* @param input The text entered into the picker.
*/
    function onInputChange(input) {
        const outlookRegEx = /<.*>/g;
        const emailAddress = outlookRegEx.exec(input);

        if (emailAddress && emailAddress[0]) {
            return emailAddress[0].substring(1, emailAddress[0].length - 1);
        }
        // Email suggestion
        if (input.indexOf('@') !== -1) {
            
/*             const suggestion = { text: input, secondaryText: "Diese Adresse verwenden", key: 0 };
            const newPeopleList = peopleList.push(suggestion);
            setPeopleList(newPeopleList); */
            setPeopleList([{ text: input, secondaryText: "Diese Adresse verwenden" }])

        }
        return input;
    }

    const onRemoveSuggestion = item => {
        const indexPeopleList = peopleList.indexOf(item);

        if (indexPeopleList >= 0) {
            const newPeople = peopleList
                .slice(0, indexPeopleList)
                .concat(peopleList.slice(indexPeopleList + 1));
            setPeopleList(newPeople);
        }
    };

    const onDisabledButtonClick = () => {
        setIsPickerDisabled(!isPickerDisabled);
    };

    const onToggleDelayResultsChange = () => {
        setDelayResults(!delayResults);
    };

    return (
        <div>
            <NormalPeoplePicker
                onResolveSuggestions={onFilterChanged}
                //onEmptyInputFocus={returnMostRecentlyUsed}
                getTextFromItem={getTextFromItem}
                pickerSuggestionsProps={suggestionProps}
                className={'ms-PeoplePicker'}
                key={'normal'}
                onRemoveSuggestion={onRemoveSuggestion}
                onValidateInput={validateInput}
                selectedItems={currentSelectedItems}
                removeButtonAriaLabel={'Remove'}
                onChange={onItemsChange}
                inputProps={{
                    onBlur: (ev) => console.log('onBlur called'),
                    onFocus: (ev) => console.log('onFocus called'),
                    'aria-label': 'People Picker',
                    placeholder: 'Gib eine E-Mail-Adresse ein',
                    height: 500
                }}
                componentRef={picker}
                onInputChange={onInputChange}
                resolveDelay={300}
                disabled={isPickerDisabled}
                styles={{ height: 300 }}
            />
        </div>
    );
};

function doesTextStartWith(text, filterText) {
    return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
}

function removeDuplicates(personas, possibleDupes) {
    return personas.filter(persona => !listContainsPersona(persona, possibleDupes));
}

function listContainsPersona(persona, personas) {
    if (!personas || !personas.length || personas.length === 0) {
        return false;
    }
    return personas.filter(item => item.text === persona.text).length > 0;
}

function convertResultsToPromise(results) {
    return new Promise((resolve, reject) => resolve(results));
}

function getTextFromItem(persona) {
    return persona.text;
}


function validateInput(input) {
    if (input.indexOf('@') !== -1) {
        return ValidationState.valid;
    } else {
        return ValidationState.invalid;
    }
}




export default PeoplePickerNormalExample