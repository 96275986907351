import React, { Suspense } from 'react'
import { useImage } from 'react-image'
import { Img } from 'react-image'
import { Shimmer, ShimmerElementType, IShimmerElement, ThemeProvider, mergeStyles, IconButton, Link } from '@fluentui/react';
import Viewer from 'react-viewer';
import {
    DocumentCard, DocumentCardPreview
} from '@fluentui/react/lib/DocumentCard';
import { Image, IImageProps, ImageLoadState } from '@fluentui/react/lib/Image';
import { ImageFit } from '@fluentui/react/lib/Image';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { ProgressIndicator } from '@fluentui/react/lib/ProgressIndicator';
const ImageLoader = (props) => {

    const [visible, setVisible] = React.useState(false);
    const [state, setState] = React.useState(false);

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [count, setCount] = React.useState(0);

    const setupImage = (state) => {
        console.log('image', count + ' ' + state);
        if (state == ImageLoadState.error)
            setTimeout(function () {
                setState(state);
                setCount(count + 1);
            }, 5000);
        else
            setState(state)
    }
    return (
        <div>
            <Viewer
                zoomSpeed={0.1}
                noImgDetails
                noNavbar
                noToolbar
                noClose
                downloadable
                downloadInNewWindow
                visible={visible}
                onMaskClick={() => setVisible(false)}
                onClose={() => setVisible(false)}
                images={[{ src: props.file, alt: '' }]}
            />
            {/* <DocumentCard
                aria-label=""
                onClick={() => setVisible(true)}
                styles={{
                    root: {
                        border: 'none',
                        maxWidth: ''
                    }
                }}>
                <Img
                    style={{
                        width: props.width ? props.width : '100%',
                        height: props.height ? props.height : '100%',
                        minHeight: props.minHeight,
                        borderRadius: props.borderRadius ? props.borderRadius : 0,
                        objectFit: 'cover'
                    }}
                    src={props.file}
                    loader={
                        <Shimmer
                            styles={{
                                shimmerWrapper: {
                                    borderRadius: props.borderRadius ? props.borderRadius : 0,
                                    height: props.height ? props.height : '100%',
                                    minHeight: props.minHeight
                                }
                            }}
                            width={props.width ? props.width : '100%'}
                            shimmerElements={[{
                                type: ShimmerElementType.line,
                                height: props.height ? props.height : '100%',
                            }]} />
                    }
                    unloader={
                        <IconButton
                            onClick={() => props.refresh()}
                            styles={{
                                root: {
                                    width: props.width ? props.width : '100%',
                                    height: props.height ? props.height : '100%',
                                    background: '#fafafa',
                                    borderRadius: props.borderRadius ? props.borderRadius : 0,
                                    minHeight: props.minHeight,
                                },
                                icon: {
                                    fontSize: 40,
                                    color: 'lightgray'
                                }
                            }}
                            iconProps={{ iconName: 'Refresh' }}
                            title="Reload"
                            ariaLabel="Reload"
                        />
                    }
                />
                <DocumentCardPreview
                    styles={{ root: { borderRadius: 10 } }}
                    previewImages={[
                        {
                            linkProps: {
                                underline: props.file,
                                target: '_blank',
                            },
                            previewImageSrc: props.file,
                            //iconSrc: TestImages.iconPpt,
                            imageFit: ImageFit.cover,
                            width: props.width ? props.width : '100%',
                            height: props.height ? props.height : '100%',
                        }
                    ]} />
            </DocumentCard > */}
            <Link
                onClick={() => setVisible(true)}
                styles={{
                    root: {
                        minHeight: props.minHeight,
                        width: props.width ? props.width : '100%',
                        height: props.height ? props.height : '100%',
                        position: 'relative'
                    }
                }}>
                <Image
                    imageFit={ImageFit.cover}
                    shouldStartVisible
                    src={(state == ImageLoadState.error && count < 10) ? '' : props.file}
                    onLoadingStateChange={(s) => setupImage(s)}
                    styles={{
                        root: {
                            borderRadius: props.borderRadius ? props.borderRadius : 0,
                            minHeight: props.minHeight,
                            background: '#fafafa'
                        }
                    }}
                    //styles: props => ({root: {border: '1px solid ' + props.theme.palette.neutralSecondary } }),
                    alt=''
                    width={props.width ? props.width : '100%'}
                    height={props.height ? props.height : '100%'}
                />
                {(state == ImageLoadState.notLoaded && count < 10 && count > 0) && <Spinner
                    size={SpinnerSize.xSmall}
                    styles={{
                        root: {
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            hieght: 12,
                            width: 12,
                            margin: 'auto'
                        }
                    }}
                />}
            </Link>
        </div >
    )
};

export default ImageLoader