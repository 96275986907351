import React from 'react';
import { Image, Stack, Text } from 'office-ui-fabric-react';
import flinkerLogo from "./images/flinker-icon-black.png";

const Logo = (props) => {

    return (
        <Stack
            horizontal
            styles={{
                root: {
                    placeContent: "center",
                    margin: 20,
                    opacity: 0.4,
                    cursor: "pointer"
                }
            }}
            onClick={() => window.location.replace('https://flinker.app/')}
        >
            <Image
                src={flinkerLogo}
                alt="Flinker"
                width={24}
                height={24}
            />
            
            <Text
                variant='mediumPlus'
                styles={{ root: { fontWeight: 500, paddingLeft: 4 } }}
            >Flinker</Text>
        </Stack>
    )


};

export default Logo;