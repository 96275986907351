
import React, { useEffect, useRef, useState } from "react";
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import WebScene from "@arcgis/core/WebScene";
import Viewpoint from "@arcgis/core/Viewpoint";
import Slide from "@arcgis/core/webscene/Slide";
import SceneView from "@arcgis/core/views/SceneView";
import FloorFilter from "@arcgis/core/widgets/FloorFilter";
import Slice from "@arcgis/core/widgets/Slice";
import esriConfig from "@arcgis/core/config";
import PortalItem from "@arcgis/core/portal/PortalItem";
import Portal from "@arcgis/core/portal/Portal";
import Expand from "@arcgis/core//widgets/Expand";
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import SpatialReference from "@arcgis/core/geometry/SpatialReference";
import Point from "@arcgis/core/geometry/Point";
import * as webMercatorUtils from "@arcgis/core/geometry/support/webMercatorUtils";
import Camera from "@arcgis/core/Camera";

var webscene = null;
var view = null;

const MapEsri = (props) => {

    const slidesDivRef = useRef(null);
    const createSlideDivRef = useRef(null);
    const createSlideTitleInputRef = useRef(null);
    const createSlideButtonRef = useRef(null);
    const sliceContainerRef = useRef(null);
    const sliceOptionsRef = useRef(null);
    const clearPlaneBtnRef = useRef(null);
    const menuRef = useRef(null);
    esriConfig.apiKey = "AAPKd1243b426471482eb0c4a80cb1cbe6a3YEdJJ-0GLsNpekgVzeFeqCRzoR0lCnmnYkaYiuim2i06qUgNOHHQvcvqdja69YkM"

    const [saveLocation, setSaveLocation] = useState(false);

    useEffect(() => {
        flyTo();
    }, [props.target]);

    const flyTo = () => {
        if (view && props.target) {
            view.when(() => {
                console.log(props.target)
                if (props.target.viewpoint) {
                    //3D
                    const viewpoint = Viewpoint.fromJSON(props.target.viewpoint);
                    view.goTo(viewpoint);
                } else {
                    // 2D
                    try {
                        const latlon = props.target.features[0].geometry.coordinates;
                        // Initialize the view with a specific camera
                        const cam = new Camera({
                            position: [...latlon, 100]  // creates a point instance (x,y,z)
                        });
                        view.goTo(cam);
                    } catch (e) {
                        console.log(e)
                    }

                }


                // const slideProps = {
                //     title: {
                //         text: 'Test'
                //     },
                //     viewpoint: viewpoint
                // };
                // var newSlide = new Slide(slideProps);
                //newSlide.applyTo(view);
            });
        }
    }

    useEffect(() => {

        let portal = new Portal({
            url: props.portalUrl
        });

        let item = new PortalItem({
            id: props.itemId,
            portal: portal
        });
        console.log(item)

        webscene = new WebScene({
            portalItem: item
        });
        view = new SceneView({
            container: "viewDiv",
            map: webscene,
            qualityProfile: "low"
        });

        view.ui.remove("zoom");
        view.ui.remove("navigation-toggle");
        view.ui.remove("compass");
        //view.ui.move(["zoom", "navigation-toggle", "compass"], "bottom-right");
        // const floorFilter = new FloorFilter({
        //     view: view
        // });
        // view.ui.add(floorFilter, "bottom-trailing");

        // Slice widget
        const sliceWidget = new Slice({
            view: view,
            container: sliceContainerRef.current
        });
        var bgExpand = new Expand({
            view: view,
            content: menuRef.current,
            expandIconClass: "esri-icon-slice"
        });
        view.ui.add(bgExpand, "bottom-right");
        clearPlaneBtnRef.current.addEventListener("click", () => {
            sliceWidget.viewModel.clear();
        });


        view.ui.add(createSlideDivRef.current, "bottom-right");

        view.when(() => {
            //document.getElementById("slidesDiv").style.visibility = "visible";
            const slides = webscene.presentation.slides;
            console.log(slides.items)
            //slides.forEach(createSlideUI);

            flyTo();

        });
    }, [props.itemId]) // <-- empty dependency array

    const updateLocation = () => {
        Slide.createFrom(view).then(async (slide) => {
            const viewpoint = slide.viewpoint;
            const viewpointJson = viewpoint.toJSON();
            const targetGeometry = viewpoint.targetGeometry;
            const targetGeometryGeo = webMercatorUtils.webMercatorToGeographic(targetGeometry);
            viewpointJson.targetGeometryGeo = targetGeometryGeo.toJSON();
            const location = {
                viewpoint: viewpointJson,
                //thumbnail: slide.thumbnail.url
            };
            setSaveLocation(true);
            console.log(location);
            view.takeScreenshot().then(async function (screenshot) {
                const blob = await (await fetch(screenshot.dataUrl)).blob();
                const result = await props.setLocation(location, blob);
                setSaveLocation(false);
            });
            /*             const blob = await (await fetch(slide.thumbnail.url)).blob();
                        const result = await props.setLocation(viewpointJson, blob);
                        setSaveLocation(false); */

        });
    }

    return (
        <div>
            <div
                id="viewDiv"
                style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: '0'
                }}
            >
                <div
                    ref={createSlideDivRef}
                    id="createSlideDiv">
                    <PrimaryButton
                        text={saveLocation ? "Ansicht wird gespeichert" : "Ansicht speichern"}
                        onClick={() => updateLocation()}
                        disabled={saveLocation} />
                </div>
                <div id="menu" ref={menuRef} class="esri-widget">
                    <div id="sliceOptions" ref={sliceOptionsRef}>
                        <button
                            class={"esri-widget--button esri-interactive esri-icon-trash"}
                            id="clearPlaneBtn"
                            ref={clearPlaneBtnRef}
                            title="Clear plane"
                        ></button>
                    </div>
                    <div id="sliceContainer" ref={sliceContainerRef}></div>
                </div>
            </div>
        </div >
    );

}

export default MapEsri;