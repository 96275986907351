import * as React from 'react';
import { ProgressIndicator } from '@fluentui/react/lib/ProgressIndicator';

const intervalDelay = 100;
const intervalIncrement = 0.001;

export const Progress = (props) => {
  const [percentComplete, setPercentComplete] = React.useState(null);

  setTimeout(() => setPercentComplete(0), 20000);

  React.useEffect(() => {
/*     if (percentComplete !== null) {
      const id = setInterval(() => {
        setPercentComplete((intervalIncrement + percentComplete) % 1);
      }, intervalDelay);
      return () => {
        clearInterval(id);
      };
    } */
  });

  return (
    <ProgressIndicator
      label="Rechner wird gestartet..."
      description={percentComplete !== null ? 'Dies sollte weninger als 2 Minuten dauern' : ''}
      percentComplete={null} />
  );
};