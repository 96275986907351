import {API, Auth, graphqlOperation} from "aws-amplify";
import {
    listCards,
    closeCard,
    createCard,
    deactivateCard,
    openCard,
    updateCard,
    updateCardDate,
    addCardFiles,
    updateCardLocation,
    changeCardStack,
    getData,
    commentOnData,
    listCardFiles,
    listComments,
    subscribeToDataComments
} from "./graphql";
import {GraphQLResult} from "@aws-amplify/api-graphql";
import CardDto, {CardServerResponseDto, InputCard} from "./CardDto";
import { v4 as uuidv4 } from 'uuid';
import {DataServices, FilterSettings} from "./DataServices";
import Amplify, { Storage } from 'aws-amplify';

class CardsService {
    private cardsByActivityId: Record<string, CardDto[]> = {};
    private nextTokenByActivityId: Record<string, string> = {};
    private responseMessages: any[] = [];
    private commentsCache : any[] = [];

    constructor(private readonly maxCardLimit: number = maxCardLimit) {}

    allLoadedInActivity(activityId: string) {
        return this.nextTokenByActivityId[activityId] !== undefined;
    }

    clearCache() {
        this.cardsByActivityId = {};
    }

    getLoadedCardById(cardId: string): CardDto | null {
        for (const cardList of Object.values(this.cardsByActivityId)) {
            const foundCard = cardList.find(card => card.id === cardId);
            if (foundCard) {
                return foundCard;
            }
        }
        return null;
    }

    getLoadedCardsForCurrentActivity(): CardDto[] {
        const activeActivityId = DataServices.ActivitiesService.getActiveActivityId();
        return this.cardsByActivityId[activeActivityId];
    }

    async getCardsForCurrentActivity(filter?: FilterSettings): Promise<CardDto[]> {
        const activeActivityId = DataServices.ActivitiesService.getActiveActivityId();
        return this.getCardsForActivity(activeActivityId, filter);
    }

    async getCardsForActivity(activityId: string, filters?: FilterSettings): Promise<CardDto[]> {
        //if (!this.cardsByActivityId[activityId] || filters !== undefined) {
            await this.loadCardsForActivity(activityId, filters);
        //}
        return this.cardsByActivityId[activityId];
    }

    async loadMoreCardsForActivity(activityId: string, filters?: FilterSettings) {
        await this.loadCardsForActivity(activityId, filters);
    }

    async deleteCard(cardId: string) {
        try {
            const result = await API.graphql(graphqlOperation(deactivateCard, {cardId}));
            return result;
        }
        catch (e) {
            return e;
        }
    }

    async getCard(id: string) {
        try {
            const result = await API.graphql(graphqlOperation(getData, {id})) as GraphQLResult<any>;
            return result.data.getCard;
        }
        catch (e) {
            console.log(e);
            return null;
        }
    }

    async createCard(card: InputCard) : Promise<any> {
       try {
           const activityId = DataServices.ActivitiesService.getActiveActivityId();
           var input = {
                id: "card_" + uuidv4(),
                name: card.name,
                stackId: card.stackId ? card.stackId : activityId.replace("activity","stack"),
                content: card.content,
                startAt: card.startAt,
                createdAt: (new Date()).toISOString(),
                activityId: activityId,
            };
            if (card.parameters) input.parameters = JSON.stringify(card.parameters);
            console.log(input)
            if (card.assigneeId) input.assigneeId = card.assigneeId;
            const result = await API.graphql(graphqlOperation(createCard, { input: input}));
           return result;
       }
       catch (e) {
        return e;
       }
    }

    async createCardWithName(name: string, activityId: string) : Promise<any> {
        try {
            var input = {
                 id: "card_" + uuidv4(),
                 name: name,
                 stackId: activityId.replace("activity","stack"),
                 createdAt: (new Date()).toISOString(),
                 activityId: activityId,
             };
             const result = await API.graphql(graphqlOperation(createCard, { input: input}));
            return result?.data?.createCard;
        }
        catch (e) {
         return e;
        }
     }

     private commentToClient(comment: any) : any {
         try {
            if (comment?.contact?.appendix)
                comment.contact.email = JSON.parse(comment.contact.appendix).email;
            if (comment?.appendix) {
                const file = JSON.parse(comment.appendix).file;
                comment.file = file ? file : JSON.parse(comment.appendix);
            }
        } catch(e){
            console.log(e);
        }
        return comment;
     }

     async listComments(cardId: string){
        try {
            const result = await API.graphql({ query: listComments, variables: { dataId: cardId } });
            const resultComments : any[]= [];
            const items = result?.data?.listComments?.items;
            this.commentsCache = [];
            if (items) {
                for (const comment of items) {
                    resultComments.push(this.commentToClient(comment));
                    this.commentsCache.push(this.commentToClient(comment));
                }
            }
            resultComments.sort((a, b) => a.receivedAt.localeCompare(b.receivedAt));
            return resultComments;
        } catch (e) {
            console.log(e);
        }
     }

     async createComment(content: string, dataId: string, activityId: string, fileId: string) : Promise<any> {
        try {
            const result = await API.graphql({
                query: commentOnData,
                variables: {
                    content: content,
                    dataId: dataId,
                    id: "comment_"+uuidv4(),
                    createdAt: (new Date()).toISOString(),
                    appendix: fileId ? JSON.stringify({ file: fileId }) : null,
                    activityId: activityId
                }
            });
            return result;
        }
        catch (e) {
         return e;
        }
     }

     async createCommentWithFile(content: string, dataId: string, activityId: string, newFile: any) : Promise<any> {
        try {
            console.log('newFile',newFile)
            const fileId = await this.getFullKey("image_"+uuidv4());
            const result1 = await this.createComment(content, dataId, activityId, fileId);
            const inputFiles = [];
            inputFiles.push({key: fileId, status: 'ONLINE', localUri: newFile});
            const result2 = await this.updateCardFiles(dataId, inputFiles);
            return result1;
        }
        catch (e) {
         return e;
        }
     }

     async addCommentToCache(data: any): Promise<any> {
        const resultComment = this.commentToClient(data?.value?.data?.subscribeToDataComments);
        if (resultComment)  {
            if (resultComment.file) {
                const signedUrl = await this.getSignedUrl(resultComment.dataId, resultComment.file);
                resultComment.signedUrl = signedUrl;
            }
            if (this.commentsCache.some(c => c.id === resultComment.id)) {

            } else {
                this.commentsCache.push(resultComment)
            }
        }
        return this.commentsCache;
     }

     async getComment(cardId): Promise<any> {
        return  new Promise( async (resolve, reject)  => {
            try {
                await API.graphql({
                    query: subscribeToDataComments,
                    variables: {
                        dataId: cardId,
                        owner: await Auth.currentAuthenticatedUser()
                    }
                }).subscribe({
                    next: async data => {
                        const result = await this.addCommentToCache(data);
                        resolve(result);
                    },
                    error: error => {
                        console.log(error);
                        reject(error);
                    }
                });
            } catch (e) {
                console.log(e)
            }
        });
    }

     async updateCardFiles(cardId: string, files: [any]) : Promise<any> {
        try {
            await API.graphql(
                graphqlOperation(addCardFiles, {cardId, files }));
            files.forEach(f => f.status = 'ONLINE');
            var result = null;
            for (let file of files) {
                if (/* file.status == 'OFFLINE' && */ file.key && file.localUri) {
                     result = await Storage.put(await this.getSimpleKey(file.key), file.localUri, {level: 'private'});
                }
            }
/*             files.forEach(f => f.status = 'ONLINE');
            const result = await API.graphql(
                graphqlOperation(updateCardFiles, {cardId, files })); */
            return result;
        }catch (e) {
            console.log(e);
        }
    }

    private async getSimpleKey(key:string) {
        const credentials = await Auth.currentUserCredentials();
        const privateFileId = key.replace("private/" + credentials.identityId+'/', '');
        return privateFileId;
    }

    private async getFullKey(key:string) {
        const credentials = await Auth.currentUserCredentials();
        const privateFileId = "private/" + credentials.identityId+'/' + key;
        return privateFileId;
    }

    async getSignedUrl(cardId: string, fileId: string) : Promise<any> {
        try {
            const result = await API.graphql(
                graphqlOperation(listCardFiles, {cardId }));
            const files = result?.data?.listCardFiles;
            const file = files.find(f => f.key == fileId);
            //const result = await Storage.get(await this.getSimpleKey(fileId), { download: false, level: 'private' });
            //console.log(result.Body) // blob
            return file.signedUrl;
        }catch (e) {
            console.log(e);
        }
    }

    async getFiles(cardId: string) : Promise<any> {
        try {
            const result = await API.graphql(
                graphqlOperation(listCardFiles, {cardId }));
            const files = result?.data?.listCardFiles;
            return files;
        }catch (e) {
            console.log(e);
        }
    }

    async updateCard(cardId: string, updatedCard: InputCard) : Promise<any> {
        const oldCard = this.getLoadedCardById(cardId);
        if (!oldCard) {
            throw new Error(`Card with id ${cardId} either doesn't exist or hasn't yet been loaded!`)
        }
        await this.updateIfStackChanged(oldCard, updatedCard);
        await this.updateCardStatus(oldCard, updatedCard);
        await this.updateCardDate(oldCard, updatedCard);
        const result = await this.updateGenericCardFields(oldCard, updatedCard);
        return result;
    }

    async openCard(cardId: string, open: boolean) : Promise<any> {
        try {
            if (open) {
                const result = await API.graphql(graphqlOperation(openCard, {cardId}));
                return result;
            } else {
                const result = await API.graphql(graphqlOperation(closeCard, {cardId}));
                return result;
            }
        } catch (e) {
            return e;
        }
    }

    addMessage(message: any) {
        this.responseMessages.push(message);
    }

    getResponseMessages() {
        return this.responseMessages.slice();
    }

    clearResponseMessages() {
        this.responseMessages = [];
    }

    async updateCardText(cardId: string, name: string, content: string, assigneeId: string) : Promise<any> {
        try {
            var input = {
                cardId: cardId,
                name: name,
                content: content,
                assigneeId: assigneeId ? assigneeId : null
            };
            const result = await API.graphql(graphqlOperation(updateCard, input));
            return result;
        }
        catch (e) {
            return e;
        }
   }

    private async updateGenericCardFields(oldCard: CardDto, updatedCard: InputCard) : Promise<any> {
         var input = {
            cardId: oldCard.id,
            name: updatedCard.name,
            content: updatedCard.content,
            assigneeId: updatedCard.assigneeId ? updatedCard.assigneeId : null
        };
        if (updatedCard.parameters) input.parameters = JSON.stringify(updatedCard.parameters);
        const result = await API.graphql(graphqlOperation(updateCard, input));
        return result;
    }

    private async updateIfStackChanged(oldCard: CardDto, updatedCard: InputCard) : Promise<any> {
        if (oldCard.stackId !== updatedCard.stackId) {
            try {
                var input = {
                    activityId: this.findActivityIdByCardId(oldCard.id),
                    cardId: oldCard.id,
                    from: oldCard.stackId,
                    to: updatedCard.stackId,
                };
                console.log(input)
                const result = await API.graphql(graphqlOperation(changeCardStack, input));
                return result;
            }
            catch (e) {
                return e;
            }
        } else {
            return null;
        }
    }

    private async updateCardDate(oldCard: CardDto, updatedCard: InputCard) : Promise<any> {
        try {
            const result = await API.graphql(
                graphqlOperation(updateCardDate, {cardId: oldCard.id, startAt: updatedCard.startAt}));
        }
        catch (e) {
            return e;
        }
    }

    async updateCardLocation(cardId: String, location: String) : Promise<any> {
        try {
            const result = await API.graphql(
                graphqlOperation(updateCardLocation, {cardId, location}));
        }
        catch (e) {
            console.log('updateCardLocation error');
            console.log('cardId', cardId);
            console.log('location', location);
            console.log(e);
        }
    }

    private async updateCardStatus(oldCard: CardDto, updatedCard: InputCard) : Promise<any> {
        try {
            if (updatedCard.open && !oldCard.open) {
                const result = await API.graphql(graphqlOperation(openCard, {cardId: oldCard.id}));
                return result;
            } else if (!updatedCard.open && oldCard.open) {
                const result = await API.graphql(graphqlOperation(closeCard, {cardId: oldCard.id}));
                return result;
            }
        } catch (e) {
            return e;
        }
    }

    private findActivityIdByCardId(cardId: string): string | null {
        for (const [activityId, cardList] of Object.entries(this.cardsByActivityId)) {
            if (cardList.some(card => card.id === cardId)) {
                return activityId;
            }
        }
        return null;
    }

    private serverDataToInternalDto(cardServerResponseDto: CardServerResponseDto): CardDto {
        const card: CardDto = {
            assigneeId: cardServerResponseDto.assigneeId,
            content: cardServerResponseDto.content ?? "",
            updatedAt: cardServerResponseDto.updatedAt,
            createdAt: cardServerResponseDto.createdAt,
            remindAt: cardServerResponseDto.remindAt,
            startAt: cardServerResponseDto.startAt,
            createdBy: cardServerResponseDto.createdBy,
            id: cardServerResponseDto.id,
            name: cardServerResponseDto.name,
            open: cardServerResponseDto.open,
            parentId: cardServerResponseDto.parentId,
            stackId: cardServerResponseDto.stackId,
            counterId: cardServerResponseDto.counterId,
            location: cardServerResponseDto.location,
            files: cardServerResponseDto.files,
            viewedByAssignee: cardServerResponseDto.viewedByAssignee,
            viewedByMe: cardServerResponseDto.viewedByMe,
            news: cardServerResponseDto.news,
            assignee: cardServerResponseDto.assignee,
        };
        if (cardServerResponseDto.parameters) {
            const parsedParameters = JSON.parse(cardServerResponseDto.parameters)
            card.parameters = parsedParameters;
        }
        card.search =
            String(card.name) + ' ' +
            String(card.content) + ' ' +
            String(card.news) + ' ' +
            '#' + String(card.counterId) + ' ' +
            String(card.assignee?.name) + ' ' +
            String(card.assignee?.email) + ' ';
        return card;
    }

    private async loadCardsForActivity(activityId: string, filters: FilterSettings = {}) {
        this.cardsByActivityId[activityId] = [];
        try {

            var resultCardItems: any[] = [];
            var nextToken = null;

            const result = (await API.graphql(graphqlOperation(listCards, {
                activityId: activityId,
                startAt: filters.startAt,
                endAt: filters.endAt,
                limit: filters.limit ?? 500,
                stackId: filters.stackId,
                assigneeId: filters.contactId,
                nextToken: this.nextTokenByActivityId[activityId] ?? nextToken,
            }))) as GraphQLResult<any>;

            if (result.data.listCards) {
                this.nextTokenByActivityId[activityId] = result.data.listCards.nextToken;
                nextToken = result.data.listCards.nextToken;
                resultCardItems.push(...result.data.listCards.items)
            } 

            while (nextToken) {
                const result = (await API.graphql(graphqlOperation(listCards, {
                    activityId: activityId,
                    startAt: filters.startAt,
                    endAt: filters.endAt,
                    limit: filters.limit ?? 500,
                    stackId: filters.stackId,
                    assigneeId: filters.contactId,
                    nextToken: this.nextTokenByActivityId[activityId] ?? nextToken,
                }))) as GraphQLResult<any>;

                if (result.data.listCards) {
                    console.log(result.data.listCards)
                    this.nextTokenByActivityId[activityId] = result.data.listCards.nextToken;
                    nextToken = result.data.listCards.nextToken;
                    resultCardItems.push(...result.data.listCards.items)
                } else {
                    nextToken = null;
                }
            }

            if (resultCardItems) {

                var resultCards: CardDto[]= [];
                //const sortedByCreatedAt =  resultCardItems.sort((a, b) => a.createdAt.localeCompare(b.createdAt));
                const sortedByOpen =  resultCardItems
                    .sort((a, b) => a.updatedAt.localeCompare(b.updatedAt))
                    .sort((a, b) => a.open-b.open).reverse();
                
                const resultContacts = await DataServices.ContactsService.getContactsForActivity(activityId);
                for (const card of sortedByOpen) {
                    //if (filters.closed || card.open){
                    if (card.assigneeId) 
                        card.assignee = resultContacts.find(c => c.id == card.assigneeId);
                    resultCards.push(this.serverDataToInternalDto(card));
                    //}
                }
                this.cardsByActivityId[activityId] = resultCards;
                console.log(resultCards)
            }
        }
        catch (e) {
            console.log(e);
            this.responseMessages.push(e);
        }
    }

}



export default CardsService;