import { ActivityItem, mergeStyleSets } from '@fluentui/react';
import moment from 'moment';
import React, { useEffect } from 'react';
import awsconfig from '../../shared/data-services/aws-exports';
import { DataServices } from "../../shared/data-services/DataServices";
import Linkify from './Linkify';
import {
  Link, useHistory,
  useParams
} from "react-router-dom";
import ImageLoader from './ImageLoader';

const classNames = mergeStyleSets({
  exampleRoot: {
    marginTop: 10,
  },
  nameText: {
    fontWeight: 'bold',
  },
});

const Comment = (props) => {

  const [loading, setLoading] = React.useState(true);
  const [url, setURL] = React.useState(null);
  const [viewFile, setViewFile] = React.useState(false);
  const { classes, comment, previousComment, nextComment, profile } = props;

  const myComment = (profile.id === comment.createdBy) ? true : false;
  const email = comment.contact?.email ? comment.contact.email : '';
  const fileKey = comment.file;

  useEffect(() => {
    loadFile();
  }, [props.cardId]);

  const loadFile = async () => {
    //const result = await DataServices.CardsService.getFile(props.cardId, fileKey);
    //setURL(result);
  }


  const File = ({ file }) => {
    return (
      <ImageLoader
        width={318}
        height={196}
        file={file}
        borderRadius={10}
        refresh={() => loadFile()}
      />
    )
  };

  return (
    <div>
      {
        (previousComment && moment(previousComment.createdAt).format('L') === moment(comment.createdAt).format('L'))
          ?
          null
          :
          <p style={{ color: "gray", textAlign: "center" }}>
            {moment(comment.createdAt).format('L')}
          </p>
      }

      <ActivityItem
        key={1}
        activityDescription={[
          <span key={1} className={classNames.nameText}>{comment.contact?.name}</span>,
          <span key={2}>, </span>,
          <span key={3} >{email}</span>,
          fileKey ? <File file={props.url} /> : null
        ]}
        activityPersonas={[{ imageUrl: DataServices.ContactsService.getAvatar(comment.createdBy, null) }]}
        comments={comment.content ?
          <div
            style={{
              backgroundColor: myComment ? '#d4ebf2' : '#eaeaea',
              color: 'black',
              wordBreak: 'break-word',
              textAlign: myComment ? 'right' : 'left',
              padding: 8,
              borderRadius: 4
            }}
            elevation={0}>
            <Linkify value={comment.content} />
          </div>
          : null}
        timeStamp={comment.createdAt ? moment(comment.createdAt).format('LT') : 'offline'}
        className={classNames.exampleRoot}
        styles={{
          root: {
            flexDirection: myComment ? 'row-reverse' : 'initial',
            width: '100%',
            paddingRight: 10
          }
        }}
      />

    </div >
  );
}


export default Comment;
