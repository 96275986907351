import * as React from 'react';
import { TextField, ITextFieldStyles } from 'office-ui-fabric-react/lib/TextField';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { IconButton } from '@fluentui/react/lib/Button';
import { DataServices } from "../../shared/data-services/DataServices";
import {
    useHistory
} from "react-router-dom";
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';

const stackTokens = { childrenGap: 15, padding: 10 };

const TextFieldControlledExample = (props) => {

    const history = useHistory();

    const [textFieldValue, setTextFieldValue] = React.useState('');
    const [creatingCard, setCreatingCard] = React.useState(false);

    const onChangeTextFieldValue = React.useCallback(
        (event, newValue) => {
            setTextFieldValue(newValue || '');
        },
        [],
    );

    return (
        <Stack
            horizontal
            tokens={stackTokens}
            styles={{ root: { padding: '10px 50px' } }} >
            <Stack.Item grow>
                <TextField
                    placeholder={creatingCard ? "Neue Card wird erstellt..." : "Neue Card erstellen..."}
                    value={textFieldValue}
                    onChange={onChangeTextFieldValue}
                    styles={{
                        fieldGroup: {
                            width: '100%',
                            background: creatingCard ? 'white' : (textFieldValue ? 'lightblue' : '#fafafa'),
                            borderRadius: 20
                        }
                    }}
                    borderless
                />
            </Stack.Item>
            <Stack.Item>
                <IconButton
                    disabled={creatingCard}
                    iconProps={{ iconName: textFieldValue ? 'Send' : 'Add' }}
                    title={textFieldValue ? 'Erstellen' : 'Neue Card'}
                    ariaLabel={textFieldValue ? 'Erstellen' : 'Neue Card'}
                    style={{
                        borderRadius: 20,
                        backgroundColor: '#3771c8',
                        color: 'white'
                    }}
                    onClick={async () => {
                        setCreatingCard(true);
                        const result = await DataServices.CardsService
                            .createCardWithName(textFieldValue, props.activityId);
                        setCreatingCard(false)
                        setTextFieldValue('');
                        console.log(result)
                        if (!textFieldValue) {
                            if (result?.id) history.push(result.id)
                        } else {
                            props.fetchData();
                        }
                    }}
                />
            </Stack.Item>
        </Stack >
    );
};

export default TextFieldControlledExample