import ContactsService from "./ContactsService";
import ActivitiesService from "./ActivitiesService";
import CardsService from "./CardsService";
import StacksService from "./StacksService";

export interface FilterSettings {
    stackId?: string;
    contactId?: string;
    limit?: number;
    startAt?: Date;
    endAt?: Date;
    date?: string;
    nextToken?: string;
    closed?: Boolean;
}

interface IServicesList {
    ActivitiesService: ActivitiesService,
    CardsService: CardsService,
    StacksService: StacksService,
    ContactsService: ContactsService,
    ProfileService: any,
}

export let DataServices: IServicesList;

export function bootstrapDataServices(settings: {cardLimit: number}): void  {
    DataServices = {
        ActivitiesService: new ActivitiesService(),
        CardsService: new CardsService(settings.cardLimit),
        StacksService: new StacksService(),
        ContactsService: new ContactsService(),
        ProfileService: {},
    };
}
