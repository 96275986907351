import { API, graphqlOperation } from 'aws-amplify';
import {
    MessageBar,
    MessageBarType
} from 'office-ui-fabric-react';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import {
    DetailsList,
    DetailsListLayoutMode, DetailsRow, Selection, SelectionMode
} from 'office-ui-fabric-react/lib/DetailsList';
import { Dialog, DialogFooter, DialogType } from 'office-ui-fabric-react/lib/Dialog';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import React, { useEffect, useState } from 'react';
import {
    disinviteContact, listContacts, removeContactFromActivity
} from "../../shared/data-services/graphql";
import PeoplePicker from './WebAppPeoplePicker';


const buttonStyles = { root: { marginRight: 8 } };

const _columns = [
    { key: 'column1', name: 'E-Mail-Adresse', fieldName: 'name', minWidth: 150, maxWidth: 200, isResizable: true, isMultiline: true },
    { key: 'column2', name: 'Status', fieldName: 'value', minWidth: 60, maxWidth: 200, isResizable: true },
];

const Contacts = (props) => {

    const [contacts, setContacts] = useState([]);
    const [invitations, setInvitations] = useState([]);
    const [contactsToInvite, setContactsToInvite] = useState([]);
    //const [hidden, setHidden] = useState(true);
    const [inviteContact, setInviteContact] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);


    useEffect(() => {
        loadContacts()
    }, [props.activity.id]);

    const inviteContacts = async () => {
        for (const contactToInvite of contactsToInvite) {
            await props.onInvite(contactToInvite.text)
        }
        setInviteContact(false);
        loadContacts();
    };

    const removeContact = async (selectedItem) => {
        setSelectedItem(false);
        if (selectedItem.key.startsWith("email_")) {
            const result = await API.graphql(
                graphqlOperation(disinviteContact, {
                    activityId: props.activity.id,
                    email: selectedItem.key.replace("email_", "")
                }));
            console.log(result);
        } else {
            const result = await API.graphql(
                graphqlOperation(removeContactFromActivity, {
                    activityId: props.activity.id,
                    contactId: selectedItem.key
                }));
            console.log(result);
        }
        _selection.setKeySelected(selectedItem.key, false, false);
        loadContacts();
    };

    const loadContacts = async () => {
        try {
            const result = await API.graphql(
                graphqlOperation(listContacts, { activityId: props.activity.id }));
            console.log(result)
            if (result && result.data.listContacts) {
                const contacts = result.data.listContacts.items;
                setContacts(contacts);
                setInvitations(result.data.listContacts.invitations);
                props.onLoad(result.data.listContacts)
            } else {

            }
        } catch (err) { console.log(err) };
    }

    const onInvitationChange = (invitations) => setContactsToInvite(invitations);

    //const openPanel = async () => setHidden(false);
    const dismissPanel = async () => {
        //setHidden(true);
        setInviteContact(false);
        setSelectedItem(null);
        props.onClose();
    }

    const contactsAndInvitations = contacts.concat(invitations);
    const items = contactsAndInvitations.map(contact => {

        var invitation = contact.id.startsWith('contact') ? false : true;
        var email = "";
        if (!invitation)
            email = JSON.parse(contact.appendix).email;
        else
            email = contact.parentId.replace('email_', '')

        return {
            name: email,
            value: contact.id.startsWith('contact') ? "Aktiv" : "Eingeladen",
            key: contact.id
        }
    });

    const modalPropsStyles = {
        main: {
            //maxWidth: 1000,
            //minWidth: 1000,
            minHeight: 400
        }
    };
    const iconProps = { iconName: 'IncreaseIndentLegacy' };
    const dialogContentProps = {
        type: DialogType.normal,
        title: inviteContact ? 'Mitglieder hinzufügen' : 'Mitglieder',
        //subText: inviteContact ? 'Lade neue Teammitglieder ein, um loszulegen.' : 'Dein Team',
    };
    const modalProps = {
        isBlocking: true,
        styles: modalPropsStyles,
    };

    // This panel doesn't actually save anything; the buttons are just an example of what
    // someone might want to render in a panel footer.
    const onRenderFooterContent = () => (
        <div>
            <PrimaryButton onClick={this.dismissPanel} styles={buttonStyles}>
                Erstellen</PrimaryButton>
            <DefaultButton onClick={this.dismissPanel}>Abbrechen</DefaultButton>
        </div>
    );

    const _selection = new Selection({
        onSelectionChanged: () => {
            setSelectedItem(_selection.getSelection()[0]);
            console.log(_selection.getSelection()[0])
        },
    });

    const _onRenderRow = props => {
        const customStyles = {};
        if (props) {
            if (props.itemIndex % 2 === 0) {
                // Every other row renders with a different background color
                //customStyles.root = { backgroundColor: theme.palette.themeLighterAlt };
            }

            return (
                <DetailsRow {...props} styles={customStyles} />

            );
        }
        return null;
    };

    return (
        <Dialog
            minWidth={400}
            //maxWidth={400}
            hidden={!props.show}
            onDismiss={dismissPanel}
            dialogContentProps={dialogContentProps}
            modalProps={modalProps}>
            {!inviteContact ?
                <Stack verticalAlign="space-between" styles={{ root: { minHeight: 300 } }}>
                    <DetailsList
                        items={items}
                        columns={_columns}
                        setKey="set"
                        layoutMode={DetailsListLayoutMode.justified}
                        selectionPreservedOnEmptyClick={false}
                        selection={_selection}
                        onRenderRow={_onRenderRow}
                        //styles={{ root: {maxHeight: 400}}}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        checkButtonAriaLabel="Row checkbox"
                        isHeaderVisible={false}
                        selectionMode={SelectionMode.single}
                    />
                    <DialogFooter>
                        {selectedItem ?
                            <DefaultButton
                                text={"Löschen"}
                                onClick={() => removeContact(selectedItem)}
                                allowDisabledFocus
                                iconProps={{ iconName: 'Remove' }}
                                styles={{
                                    root: {
                                        borderRadius: 20
                                    }
                                }}
                            />
                            :
                            <PrimaryButton
                                text={"Neu"}
                                onClick={() => setInviteContact(true)}
                                allowDisabledFocus
                                iconProps={{ iconName: 'Add' }}
                                styles={{
                                    root: {
                                        borderRadius: 20
                                    }
                                }}
                            />
                        }
                    </DialogFooter>
                </Stack>
                :
                <Stack verticalAlign="space-between" styles={{ root: { height: 300 } }}>
                    <div>
                        <PeoplePicker
                            onChange={onInvitationChange}
                        />
                        <MessageBar
                            messageBarType={MessageBarType.info}
                            isMultiline={true}
                            styles={{ root: { backgroundColor: 'white', color: 'gray' } }}>
                            {props.activity.pro ?
                                'Es wird eine anteilige Mitgliedergebühr berechnet. Teammitglieder können jederzeit wieder entfert werden.' :
                                ''}
                        </MessageBar>
                    </div>
                    <DialogFooter>
                        <PrimaryButton
                            text={"Einladen"}
                            onClick={inviteContacts}
                            allowDisabledFocus
                            disabled={contactsToInvite.length == 0}
                            styles={{
                                root: {
                                    borderRadius: 20
                                }
                            }}
                        />
                    </DialogFooter>
                </Stack>
            }
        </Dialog>
    );
}

export default Contacts