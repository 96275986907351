import { API, Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
//import QueryListComments from "../GraphQL/QueryListComments";
//import SubsriptionEventComments from "../GraphQL/SubsriptionEventComments";
import {
    subscribeToDataComments
} from "../../shared/data-services/graphql";
import Comment from "./Comment";
import NewComment from "./NewComment";
import { DataServices } from "../../shared/data-services/DataServices";
import { FocusZone } from '@fluentui/react/lib/FocusZone';
import { List } from '@fluentui/react/lib/List';
import TeamsChat from "./TeamsChat";
import { Attachment, Button, Provider, teamsTheme } from '@fluentui/react-northstar'

var messagesEnd = null;

const CardComments = (props) => {

    const [comments, setComments] = useState([]);

    const fetchData = React.useCallback(async () => {
        try {
            const resultComments = await DataServices.CardsService.listComments(props.card.id);
            setComments(resultComments);
        } catch (e) {
            console.log(e)
        }
    });

    const fetchDataAndScroll = async (data) => {
        //await fetchData();
        const resultComments = await DataServices.CardsService.addCommentToCache(data);
        setComments([...resultComments]);
        //console.log(resultComments)
        setTimeout(function(){ scrollToBottom(); }, 10);

        
    }

    const addOfflineComment = async (data) => {
        setComments([...comments, data]);
        setTimeout(function(){ scrollToBottom(); }, 10);
    }


    const subscribe = async () => {
        /*         DataServices.CardsService.getComment(props.card.id).then(
                    data => {
                        //console.log('resultComments', data)
                        //setComments(data);
                        //scrollToBottom();
                        fetchDataAndScroll();
                    },
                    err => { console.log(err) },
                    () => console.log('Complete!')
                ); */

        try {
            const subscription = await API.graphql({
                query: subscribeToDataComments,
                variables: {
                    dataId: props.card.id,
                    owner: await Auth.currentAuthenticatedUser()
                }
            }).subscribe({
                next: async data => {
                    await fetchDataAndScroll(data);
                },
                error: error => console.log(error)
            });
        } catch (e) {
            console.log(e)
        }

    }

    useEffect(() => {
        subscribe();
        fetchData();
    }, [props.card.id]);

    const scrollToBottom = () => {
        if (messagesEnd)
            messagesEnd.scrollIntoView({ block: 'nearest', inline: 'start' })
    }

    const onRenderCell = (comment, i, arr) => {
        var previousComment = null;
        var nextComment = null;
        var signedUrl = props.files?.find(f => f.key == comment.file)?.signedUrl;
        if (!signedUrl) signedUrl = comment.signedUrl;

        if (i >= 1) {
            previousComment = arr[i - 1];
        }
        if (i < arr.length) {
            nextComment = arr[i + 1];
        }
        return (
            <Comment
                cardId={props.card.id}
                onFileClick={props.onFileClick}
                profile={props.profile}
                previousComment={previousComment}
                nextComment={nextComment}
                comment={comment}
                url={signedUrl}
                key={comment.commentId}
                scrollToBottom={scrollToBottom} />
        );
    };

    const { card } = props;


    return (
        <div >
            {/*             <FocusZone>
                <List
                    //className={classNames.listGridExample}
                    items={comments}
                    //getItemCountForPage={getItemCountForPage}
                    //getPageHeight={getPageHeight}
                    renderedWindowsAhead={4}
                    onRenderCell={onRenderCell}
                />
            </FocusZone>     */}
            {/*             {comments.map(onRenderCell)} */}
            <Provider theme={teamsTheme} >
                <TeamsChat
                    files={props.files}
                    profile={props.profile}
                    items={comments ? comments : []} />
            </Provider>

            {<NewComment
                profile={props.profile}
                card={card}
                onCreate={(data) => addOfflineComment(data)}
            />}
            <div
                ref={(el) => { messagesEnd = el; }}>
            </div>

        </div>
    );

}

export default CardComments;

