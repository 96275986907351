import {
  DocumentCard,
  DocumentCardActivity,
  DocumentCardDetails,
  DocumentCardPreview,
  DocumentCardTitle, DocumentCardType
} from '@fluentui/react/lib/DocumentCard';
import moment from 'moment';
import 'moment/locale/de'; // without this line it didn't work
import React, { useEffect } from 'react';
import { DataServices } from "../../shared/data-services/DataServices";
import { IImageProps, Image, ImageFit } from '@fluentui/react/lib/Image';

moment.locale('de');

const PreviewCard = (props) => {

  useEffect(() => {
    loadFile();
  }, []);

  const [url, setURL] = React.useState(null);

  const loadFile = async () => {
    if (props.item.files?.length > 0 && props.item.files[0].key) {
      const result = await DataServices.CardsService.getSignedUrl(props.item.id, props.item.files[0].key);
      console.log(result)
      setURL(result);
    }
  }

  const item = props.item;

  var name = null;

  return (
    <DocumentCard
      aria-label=""
      //type={DocumentCardType.compact}
      styles={{
        root: {
          width: 300
        }
      }}
    >
      {props.item?.files?.length > 0 && <DocumentCardPreview

        previewImages={[{
          name: '',
          previewImageSrc: url,
          imageFit: ImageFit.centerCover,
          //iconSrc: TestImages.iconPpt,
          width: 300,
          height: 196,
        }]} />}
      <DocumentCardDetails>
        <DocumentCardTitle
          title={'#' + props.item.counterId + ': ' + props.item.name}
          shouldTruncate={false}
          showAsSecondaryTitle />
        <DocumentCardActivity
          activity={moment(props.item?.createdAt).format('LL') +
            ', ' +
            moment(props.item?.createdAt).format('LT')}
          people={props.item?.assignee ? [
            {
              name: props.item?.assignee?.email,
              profileImageSrc: DataServices.ContactsService.getAvatar(props.item.assignee?.id, null)
            }
          ] : []} />
      </DocumentCardDetails>
    </DocumentCard>

  );
}

export default PreviewCard