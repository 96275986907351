import { mergeStyles } from '@uifabric/merge-styles';
import { Card } from '@uifabric/react-cards';
import { FontWeights } from '@uifabric/styling';
import { API, graphqlOperation } from 'aws-amplify';
import { DefaultButton, PrimaryButton, Text, TextField, Stack, Label, IconButton } from 'office-ui-fabric-react';
import { Dialog, DialogFooter, DialogType } from 'office-ui-fabric-react/lib/Dialog';
import { Facepile, OverflowButtonType } from 'office-ui-fabric-react/lib/Facepile';
import React, { useEffect, useState } from 'react';
import {
    useHistory, useParams,
    useRouteMatch
} from "react-router-dom";
import awsconfig from '../../shared/data-services/aws-exports';
import { DataServices } from "../../shared/data-services/DataServices";
import {
    acceptInvitation,
    inviteContact, removeContactFromActivity, updateActivity, updateActivityParameters
} from "../../shared/data-services/graphql";
import CardsList from "./CardsListAdvanced";
import Contacts from "./WebAppContacts";
import Parameters from "./WebAppParameters";
import Premium from "./WebAppPremium";
import { Scrollbars } from 'react-custom-scrollbars';
import { Toggle } from '@fluentui/react/lib/Toggle';
import { ActionButton, CommandBarButton } from '@fluentui/react/lib/Button';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import Searchbar from "./Searchbar"
import { Persona, PersonaSize, PersonaPresence } from '@fluentui/react/lib/Persona';

const menuProps = {
    items: [
        {
            key: 'emailMessage',
            text: 'Email message',
            iconProps: { iconName: 'Mail' },
        },
        {
            key: 'calendarEvent',
            text: 'Calendar event',
            iconProps: { iconName: 'Calendar' },
        },
    ],
    directionalHintFixed: true,
};

const dialogContentProps = {
    type: DialogType.normal,
    title: 'Aktivität verlassen',
    closeButtonAriaLabel: 'Close',
    subText: 'Die Aktivitat und alle Cards dieser Aktivitat werden gelöscht!',
};

const nameDialogContentProps = {
    type: DialogType.normal,
    title: 'Einstellungen',
    closeButtonAriaLabel: 'Close'
};

const actionButtonStyles = {
    root: {
        border: 'none',
        color: '#333333',
        height: 'auto',
        minHeight: 0,
        minWidth: 0,
        padding: 0,
        selectors: {
            ':hover': {
                color: '#0078D4',
            },
        },
    },
    textContainer: {
        fontSize: 16,
        fontWeight: FontWeights.semibold,
    },
};


function _onChange(ev, checked) {
    console.log('toggle is ' + (checked ? 'checked' : 'not checked'));
}

const stackTokens = { childrenGap: 30 };

const Activity = (props) => {

    const [contacts, setContacts] = useState([]);
    const [invitations, setInvitations] = useState([]);
    const [showContacts, setShowContacts] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showName, setShowName] = useState(false);
    const [activityName, setActivityName] = useState(null);
    const [activity, setActivity] = useState(null);
    const [invitation, setInvitation] = useState(null);

    let { id } = useParams();
    let { path, url } = useRouteMatch();

    const fetchData = async () => {
        setActivity(null);
        setInvitation(null);
        const result = await DataServices.ActivitiesService.getActivityById(id);
        if (result) {
            setActivity(result);
        } else {
            const resultActivities = await DataServices.ActivitiesService.getAllActivities();
            if (resultActivities?.length > 0) {
                const foundInvitation = resultActivities.find(a => a.id == id);
                setInvitation(foundInvitation);
                console.log(foundInvitation)

            }
        }
    }

    useEffect(() => {
        fetchData();
    }, [id]);

    const onContactsLoad = async (listContacts) => {
        setContacts(listContacts.items);
        setInvitations(listContacts.invitations);
    }

    const inviteContactQuery = async (email) => {
        try {
            await API.graphql(graphqlOperation(inviteContact,
                { activityId: activity.id, email: email }))
        } catch (e) {
            console.log(e);
        }
    }

    const updateParameters = async (params) => {
        try {
            console.log(params)
            await API.graphql(graphqlOperation(updateActivityParameters,
                { activityId: activity.id, parameters: params }));
            fetchData();
            props.onUpdate();
        } catch (e) {
            console.log(e);
        }
    }

    const updateActivityName = async () => {
        setShowName(false);
        if (activityName)
            try {
                await API.graphql(graphqlOperation(updateActivity,
                    { activityId: activity.id, name: activityName }));
                fetchData();
                props.onUpdate();
            } catch (e) {
                console.log(e);
            }
    }

    const deleteActivity = async () => {
        setShowDelete(false);
        try {
            const result = await API.graphql(
                graphqlOperation(removeContactFromActivity, {
                    activityId: activity.id,
                    contactId: activity.createdBy
                }));
            fetchData();
            props.onUpdate();
            console.log(result);
        } catch (e) {
            console.log(e);
        }
    }

    const acceptInvitation = async () => {
        await DataServices.ActivitiesService.acceptInvitation(id);
        fetchData();
        await props.onUpdate();
        if (window.location.search.includes("applicationId"))
            props.hide();
    }

    const siteTextStyles = {
        root: {
            color: '#025F52',
            fontWeight: FontWeights.semibold,
        },
    };
    const helpfulTextStyles = {
        root: {
            color: '#333333',
            fontWeight: FontWeights.semibold,
        },
    };
    const iconStyles = {
        root: {
            color: '#0078D4',
            fontSize: 16,
            fontWeight: FontWeights.regular,
        },
    };
    const footerCardSectionStyles = {
        root: {
            alignSelf: 'flex-end',
            borderLeft: '1px solid #F3F2F1',
        },
    };

    const cardTokens = { width: '100%', maxWidth: '100%' };
    const cardHeaderTokens = { childrenMargin: 40 };

    const contactsAndInvitations = contacts.concat(invitations);
    const personas = contactsAndInvitations.map(contact => {
        var invitation = contact.id.startsWith('contact') ? false : true;
        var email = "";
        if (!invitation)
            email = JSON.parse(contact.appendix).email;
        else
            email = contact.parentId.replace('email_', '')

        return {
            personaName: email,
            imageUrl: DataServices.ContactsService.getAvatar(contact.id, contact.updatedBy)
        }
    });

    if (activity || invitation) {
        const isRoot = activity?.type == "ROOT";
        const createdByMe = activity?.createdBy == props.profile.id;
        const createdByName = !activity ? "" : JSON.parse(activity.createdByContact.appendix).email;
        const parameters = activity?.appendix ? activity.appendix.parameters : null;
        const reportURL = activity?.appendix ? activity.appendix.reportURL : null;

        const addButtonProps = {
            ariaLabel: 'Add a new person',
            onClick: (ev) => setShowContacts(true),
        };
        const overflowButtonProps = {
            ariaLabel: 'More users',
            onClick: (ev) => setShowContacts(true),
        };
        return (
            activity ?
                <Card
                    aria- label="Activity"
                    vertical
                    tokens={cardTokens}
                    styles={{
                        root: {
                            height: '100%',
                            backgroundColor: 'white',
                            overflowY: 'auto',
                            background: 'white'
                        }
                    }
                    }>
                    <Scrollbars autoheight>
                        <Card.Section styles={{ root: { paddingTop: 50 } }} tokens={{ padding: 40, childrenGap: 10 }} >
                            <Searchbar></Searchbar>
                            <Card.Section horizontal horizontalAlign="end" syles={{ root: { height: 44 } }}>
                                <Premium
                                    profile={props.profile}
                                    activityToUpgrade={activity}
                                    onUpdate={() => props.onUpdate()} />
                                {/*                     <IconButton
                        iconProps={{ iconName: 'Settings' }}
                        menuProps={menuProps}
                        title="Einstellungen"
                        ariaLabel="Einstellungen" /> */}
                            </Card.Section>
                            <Card.Section>
                                <TooltipHost content={'Einstellungen der Aktivität'}>
                                    <ActionButton
                                        menuIconProps={{ iconName: 'ChevronDown' }}
                                        onClick={() => setShowName(true)}
                                        text={isRoot ? 'Mir zugewiesen' : activity.name}
                                        styles={{
                                            root: {
                                                color: '#333333',
                                                fontWeight: '600',
                                                fontSize: '1.6em',
                                            },
                                            menuIcon: {
                                                fontSize: '0.6em'
                                            }
                                        }}
                                    />
                                </TooltipHost>
                                <Dialog
                                    hidden={!showName}
                                    onDismiss={() => setShowName(false)}
                                    modalProps={{ isBlocking: true }}
                                    dialogContentProps={nameDialogContentProps}>
                                    <Stack
                                        vertical
                                        tokens={{
                                            childrenGap: 20
                                        }} >
                                        <div>
                                            <Label>Name der Aktivitat</Label>
                                            <Stack horizontal>
                                                <TextField
                                                    defaultValue={activity.name}
                                                    onChange={(ev, text) => setActivityName(text || "")} />
                                                <PrimaryButton
                                                    onClick={updateActivityName}
                                                    text="Speichern" />
                                            </Stack>
                                        </div>
                                        <div>
                                            <Label>Benachrichtungen</Label>
                                            <Toggle onText="An" offText="Aus" inlineLabel />
                                        </div>
                                        {/*                                     <div>
                                        <Label>Parameter</Label>
                                        <Parameters
                                            createdByMe={createdByMe}
                                            activity={activity}
                                            parameters={Array.isArray(parameters) ? parameters : null}
                                            onInvite={inviteContactQuery}
                                            onLoad={onContactsLoad}
                                            onChange={updateParameters}
                                        />
                                    </div> */}
                                        <Dialog
                                            hidden={!showDelete}
                                            onDismiss={() => setShowDelete(false)}
                                            dialogContentProps={dialogContentProps}
                                        >
                                            <DialogFooter>
                                                <DefaultButton
                                                    onClick={() => setShowDelete(false)}
                                                    text="Abbrechen" />
                                                <PrimaryButton
                                                    onClick={deleteActivity}
                                                    text="Verlassen" />
                                            </DialogFooter>
                                        </Dialog>
                                        {!isRoot &&
                                            <DefaultButton
                                                text={"Aktivität verlassen"}
                                                onClick={() => setShowDelete(true)}
                                                disabled={createdByMe && personas.length > 1}
                                                allowDisabledFocus
                                                styles={{
                                                    root: {
                                                        alignSelf: "flex-start"
                                                    }
                                                }}
                                            />
                                        }
                                    </Stack>
                                </Dialog>
                            </Card.Section>
                            <Text variant="smallPlus" styles={{ root: { color: 'gray' } }}>
                                {isRoot ? '' : 'von ' + createdByName}
                            </Text>
                            <Facepile
                                personas={personas}
                                maxDisplayablePersonas={5}
                                overflowButtonProps={overflowButtonProps}
                                overflowButtonType={OverflowButtonType.descriptive}
                                showAddButton={(!createdByMe || isRoot) ? false : true}
                                addButtonProps={addButtonProps}
                                ariaDescription="To move through the items use left and right arrow keys."
                            />
                            <Contacts
                                activity={activity}
                                show={showContacts}
                                onClose={() => setShowContacts(false)}
                                onInvite={inviteContactQuery}
                                onLoad={onContactsLoad}
                            />
                            {
                    /*
                    <Stack tokens={stackTokens}>
                        <Toggle label="Cardnummern anzeigen" disabled inlineLabel onChange={_onChange} />
                        <Toggle label="Nur zugewiese Cards für andere sichtbar" disabled inlineLabel onChange={_onChange} />
                        <Toggle label="Historie in Cards anzeigen" disabled inlineLabel onChange={_onChange} />
                        <Toggle label="Benachrichtigungen zulassen" disabled inlineLabel onChange={_onChange} />
                        <Toggle label="Actions aktivieren" disabled inlineLabel onChange={_onChange} />
                        <Toggle label="Vorlage definieren" disabled inlineLabel onChange={_onChange} />
                    </Stack> 
                    */}
                        </Card.Section>
                        <CardsList profile={props.profile} />
                        <Card.Section tokens={{ padding: 40, childrenGap: 20 }}>

                        </Card.Section>
                    </Scrollbars>
                </Card >
                :
                <Card
                    aria-label="Clickable horizontal card "
                    vertical
                    tokens={cardTokens}
                    styles={{
                        root: {
                            height: '100%',
                            backgroundColor: 'white'
                        }
                    }}>
                    <Stack
                        tokens={stackTokens}
                        verticalFill vertical
                        verticalAlign="center"
                        horizontalAlign="center">
                        <Stack.Item>
                            <Text variant="medium">
                                {'Einladung zur Aktivität ' + invitation.name + '!'}
                            </Text>
                        </Stack.Item>
                        <Stack.Item>
                            <Persona
                                text={invitation.name}
                                size={PersonaSize.size72}
                                secondaryText={invitation.contacts?.items?.length + ' Mitglied(er)'}
                                tertiaryText={'Von ' + invitation.createdByContact?.name}
                            //hidePersonaDetails={!renderDetails}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <PrimaryButton
                                text="Weiter zur Aktivität"
                                onClick={() => acceptInvitation()} />
                        </Stack.Item>
                    </Stack>

                </Card >
        );
    } else {
        return (
            <Card
                aria-label="Clickable horizontal card "
                vertical
                tokens={cardTokens}
                styles={{
                    root: {
                        height: '100%',
                        backgroundColor: 'white'
                    }
                }}>
                <Text variant="xxLarge" styles={{
                    root: {
                        margin: 'auto',
                        color: 'lightGray',
                    }
                }}>
                    {'Aktivität nicht gefunden'}
                </Text>
            </Card>
        )
    }

}

export default Activity