const listCards = `query listCards ($activityId: ID, $stackId: ID, $limit: Int, $startAt: AWSDateTime, $endAt: AWSDateTime, $nextToken: String, $assigneeId: ID) {
  listCards (activityId: $activityId, stackId: $stackId, limit: $limit,  startAt: $startAt, endAt: $endAt, nextToken: $nextToken, assigneeId: $assigneeId) {
      items {
        id
        name
        content
        parentId
        createdAt
        updatedAt
        createdBy
        remindAt
        guids
        status
        open
        appendix
        news
        fileStatus
        type
        startAt
        assigneeId
        viewedByAssignee
        stackId
        parameters
        counterId
        viewedByMe
        files {
          key
          status
      }
        location
      }
      nextToken
    }
  }`

const listStacks = `query listStacks($activityId: ID!){
  listStacks(activityId: $activityId){
    items {
      id
      name
      content
    }
  }
}`

const deactivateStack = `mutation deactivateStack($stackId: ID!) {
  deactivateStack(stackId: $stackId) {
      id
  }
}`

const getData = `query getCard($id: ID!) {
  getCard(id: $id) {
      id
      activityId
      name
      content
      createdAt
      createdBy
      updatedAt
      updatedBy
      parentId
      startAt
      stackId
      assigneeId
      open
      viewedByAssignee
      counterId
      timeKey
      viewedByMe
      parameters
      files {
          key
          status
      }
      location
  }
}`


const getProfile = `query getProfile {
  getProfile {
      id
      name
      appendix
      pro
      updatedAt
  }
}`

const getActivityRoot = `query getActivityRoot {
  getActivityRoot {
    id
    name
    createdBy
  }
}`

const listActivities = `query listActivities {
  listActivities {
    items {
      id
      name
      type
      createdBy
      updatedAt
      activityId
      pro
      counterId
      createdByContact {
        id
        pro
        name
        appendix
      }
      contacts {
        items {
            id
            name
            appendix
            pro
            file {
                key
            }
        }
      }
      unreadAssignedCards
      unreadCards
      appendix
    },
    invitations {
      id
      name
      type
      content
      createdBy
      contacts {
          items {
              id
              name
              appendix
          }
      }
      createdByContact {
        id
        pro
        name
        appendix
      }
      createdAt
      updatedAt
      pro
  }
  }
}`

const getActivity = `query getActivity($id: ID!) {
  getActivity(id: $id) {
    id
    name
    type
    createdBy
    updatedAt
    activityId
    pro
    counterId
    createdByContact {
      id
      pro
      name
      appendix
    }
    contacts {
      items {
          id
          name
          appendix
          pro
          file {
              key
          }
      }
    }
    unreadCards
    unreadAssignedCards
    appendix
  }
}`

const listTeamActivities = `query listTeamActivities {
  listTeamActivities {
      items {
        id
        name
        type
        pro
          contacts {
            items {
              id
              name
              appendix
            }
            invitations {
              parentId
              id
              name
            }
          }
      }
  }
}`

const listContacts = `query listContacts($activityId: ID!){
  listContacts(activityId: $activityId){
    items {
      id
      name
      appendix
    }
    invitations {
      parentId
      id
      name
      appendix
    }
  }
}`

const listCardFiles = `query listCardFiles($cardId: ID!) {
  listCardFiles(cardId: $cardId) {
    signedUrl
    key
  }
}`

const createCard = `mutation createCard($input: CreateCardInput!) {
  createCard(input: $input) {
    id
    parentId
    name
    content
    createdAt
    status
    file {
         localUri
         key
         bucket
         region
         mimeType
    }
    guids
    startAt
    counterId
  }
  }`

const updateCard = `mutation updateCard($cardId: ID!, $name: String, $content: String, $assigneeId: ID, $parameters: AWSJSON) {
  updateCard(cardId: $cardId, name: $name, content: $content, assigneeId: $assigneeId, parameters: $parameters) {
      id
      name
      content
      counterId
  }
}`

const updateCardDate = `mutation updateCardDate($cardId: ID!, $startAt: AWSDateTime) {
  updateCardDate(cardId: $cardId, startAt: $startAt) {
    name
    id
    startAt
  }
}`

const updateCardLocation = `mutation updateCardLocation($cardId: ID!, $location: AWSJSON) {
  updateCardLocation(cardId: $cardId, location: $location) {
      id
      name
      content
  }
}`

const closeCard = `mutation closeCard($cardId: ID!) {
  closeCard(cardId: $cardId) {
    id
    name
    open
    counterId
  }
}`
const openCard = `mutation openCard($cardId: ID!) {
  openCard(cardId: $cardId) {
    id
    name
    open
    counterId
  }
}`

const changeCardStack = `mutation changeCardStack($activityId: ID!, $cardId: ID!, $from: ID!, $to: ID!) {
    changeCardStack(activityId: $activityId, cardId: $cardId, from: $from, to: $to) {
      name
      id
      counterId
    }
  }`

const createStack = `mutation createStack($activityId: ID!, $name: String!) {
  createStack(activityId: $activityId, name: $name) {
    id
  }
}`

const updateStack = `mutation updateStack($stackId: ID!, $name: String!) {
  updateStack(stackId: $stackId, name: $name) {
    	id
    name
    content
  }
}`

const deactivateCard = `mutation deactivateCard($cardId: ID!) {
  deactivateCard(cardId: $cardId) {
    name
    id
    counterId
  }
}`
const createActivity = `mutation createActivity($name: String!) {
  createActivity(name: $name){
      id
      name
      type
    }
  }`

const createActivityTeam = `mutation createActivityTeam($name: String!) {
  createActivityTeam(name: $name){
        id
        name
        type
      }
    }`

const businessCreateActivity = `mutation businessCreateActivity($name: String!, $type: String!, $activityId: ID!) {
    businessCreateActivity(name: $name, type: $type, activityId: $activityId) {
      id
      name
      type
    }
  }`

const disinviteContact = `mutation disinviteContact($activityId: ID!, $email: AWSEmail!) {
    disinviteContact(activityId: $activityId, email: $email) {
        id
    }
}`

const removeContactFromActivity = `mutation removeContactFromActivity($activityId: ID!, $contactId: ID!) {
  removeContactFromActivity(activityId: $activityId, contactId: $contactId) {
    id
  }
}`

const inviteContact = `mutation inviteContact($activityId: ID!, $email: AWSEmail!) {
  inviteContact(activityId: $activityId, email: $email) {
    id
  }
} `

const listContactsInvited = `query listContactsInvited($activityId: ID!){
  listContactsInvited(activityId: $activityId){
    items {
      parentId
      id
    }
  }
} `

const updateActivityToPremium = `mutation updateActivityToPremium($activityId: ID!) {
  updateActivityToPremium(activityId: $activityId) {
    id
    pro
  }
} `
const updateActivityToFree = `mutation updateActivityToFree($activityId: ID!) {
  updateActivityToFree(activityId: $activityId) {
    id
    pro
  }
} `


const getStripeSession = `query getStripeSession {
  getStripeSession
} `


const getStripePrice = `query getStripePrice ($country: String){
  getStripePrice(country: $country)
}`

const getStripeCheckoutSession = `query getStripeCheckoutSession {
  getStripeCheckoutSession
} `

const getStripeCustomer = `query getStripeCustomer {
  getStripeCustomer
} `

const createStripeSubscription = `mutation createStripeSubscription($paymentMethodId: String!, $street: String, $postal_code: String, $city:String, $country:String) {
  createStripeSubscription(paymentMethodId: $paymentMethodId, street: $street, postal_code: $postal_code, city: $city, country: $country)
} `

const updateActivityParameters = `mutation updateActivityParameters($activityId: ID!, $parameters: AWSJSON) {
  updateActivityParameters(activityId: $activityId, parameters: $parameters) {
    id
  }
}`

const updateActivity = `mutation updateActivity($activityId: ID!, $name: String, $stackIds: [ID]) {
  updateActivity(activityId: $activityId, name: $name, stackIds: $stackIds) {
      id
  }
}`

const listComments = `query listComments($dataId: ID!) {
  listComments (dataId: $dataId) {
    items {
      dataId
      id
      content
      createdAt
      receivedAt
      createdBy
      type
      contact {
        name
        appendix
      }
      appendix
    }
  }
}`

const commentOnData = `mutation commentOnData( $dataId: ID!,  $activityId: ID, $id: ID!, $content: String, $createdAt: AWSDateTime!, $appendix: AWSJSON) {
  commentOnData(dataId: $dataId, activityId: $activityId,  id: $id, content: $content, createdAt: $createdAt, appendix: $appendix) {
    content
    createdAt
    createdBy
    activityId
    type
    id
    dataId
    contact {
      name
      id
      appendix
    }
    appendix
  }
}`


const subscribeToDataComments = `subscription subscribeToDataComments($dataId: ID!){
  subscribeToDataComments(dataId: $dataId) {
      id
      content
      createdAt
      createdBy
      dataId
      type
      contact {
        name
        id
        appendix
      }
      appendix
  }
}`

const subscribeToActivityComments = `subscription subscribeToActivityComments($activityId: ID!){
  subscribeToActivityComments(activityId: $activityId) {
      id
      content
      createdAt
      createdBy
      dataId
      contact {
        name
        id
        appendix
      }
      appendix
  }
}`

const acceptInvitation = `mutation acceptInvitation($activityId: ID!) {
  acceptInvitation(activityId: $activityId) {
      id
  }
}`

const updateProfile = `mutation updateProfile($name: String, $file: FileInput) {
  updateProfile(name: $name, file: $file) {
      id
      name
      appendix
      file {
          key
      }
  }
}`

const addCardFiles = `mutation updateCardFiles($cardId: ID!, $files: [FileInput]) {
  addCardFiles(cardId: $cardId, files: $files) {
      id
      name
      content
  }
}`

export {
  createCard,
  listCards,
  updateCard,
  updateCardDate,
  updateCardLocation,
  addCardFiles,
  listCardFiles,
  closeCard,
  openCard,
  changeCardStack,
  listStacks,
  getData,
  getProfile,
  getActivityRoot,
  listActivities,
  getActivity,
  listContacts,
  listContactsInvited,
  listTeamActivities,
  createActivity,
  createActivityTeam,
  createStack,
  updateStack,
  deactivateStack,
  businessCreateActivity,
  inviteContact,
  deactivateCard,
  updateActivityToPremium,
  updateActivityToFree,
  updateActivityParameters,
  updateActivity,
  removeContactFromActivity,
  disinviteContact,
  getStripeCheckoutSession,
  getStripeSession,
  getStripeCustomer,
  createStripeSubscription,
  getStripePrice,
  listComments,
  commentOnData,
  subscribeToDataComments,
  subscribeToActivityComments,
  acceptInvitation,
  updateProfile
};