import * as React from "react";
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { DefaultButton, PrimaryButton, CompoundButton } from 'office-ui-fabric-react/lib/Button';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Facepile, IFacepilePersona, OverflowButtonType } from 'office-ui-fabric-react/lib/Facepile';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Persona, PersonaSize } from 'office-ui-fabric-react/lib/Persona';
import { Separator } from 'office-ui-fabric-react/lib/Separator';
import {
    MessageBarButton,
    Link,
    MessageBar,
    MessageBarType,
} from 'office-ui-fabric-react';

const ProUpgrade = (props) => {

    const dismissPanel = async () => props.onClose();
    const upgrade = async () => {
        props.onUpgrade();
        dismissPanel();
    }
    const downgrade = async () => {
        props.onDowngrade();
        dismissPanel();
    }

    const modalPropsStyles = {
        main: {
            minHeight: 400,
        }
    };
    const dialogContentProps = {
        type: DialogType.normal,
        title: 'PRO Aktivität. Unbegrenzt nutzen.',
        //subText: 'Erstelle unbegrenzt Cards und organisiere dein Team ganz leicht an einer Stelle.',
    };
    const modalProps = {
        isBlocking: true,
        styles: modalPropsStyles,
    };

    return (
        <Dialog
            modalProps={modalProps}
            minWidth={400}
            dialogContentProps={dialogContentProps}
            hidden={!props.show}
            modalProps={modalProps}
            onDismiss={props.onHide}>
            <MessageBar
                messageBarType={MessageBarType.success}
                isMultiline={false}
                styles={{ root: { backgroundColor: 'white' } }}>
                Unbegrenzt Cards erstellen
            </MessageBar>
            <MessageBar
                messageBarType={MessageBarType.success}
                isMultiline={false}
                styles={{ root: { backgroundColor: 'white' } }}>
                Organisiere dein Team
                <Link href="" target="_blank">
                    Mehr erfahren
                </Link>
            </MessageBar>
            <Stack verticalAlign="space-between" >
                {props.teamDifference && props.teamDifference.length > 0 &&
                    <div>
                        <Separator />
                        <Facepile
                            personaSize={PersonaSize.size24}
                            personas={props.personas}
                            overflowButtonType={OverflowButtonType.descriptive}
                            maxDisplayablePersonas={20}
                        />
                        <Text
                            block
                            variant="mediumPlus"
                            styles={{ root: { fontWeight: 500, paddingTop: 10, paddingBottom: 10 } }}>
                            {props.teamDifference.length +
                                ((props.teamDifference.length == 1 ? " Teammitglied" : " Teammitglieder") +
                                    (!props.pro ? " hinzufügen" : " entfernen"))}
                        </Text>
                        <MessageBar
                            messageBarType={MessageBarType.info}
                            isMultiline={true}
                            styles={{ root: { backgroundColor: 'white', color: 'gray' } }}>
                            {props.pro ?
                                'Die Mitglieder werden automatisch aus deinem Team entfernt.' :
                                'Mitglieder einer PRO Aktivität werden automatisch deinem Team hinzugefügt.'}
                        </MessageBar>
                        <MessageBar
                            messageBarType={MessageBarType.info}
                            isMultiline={true}
                            styles={{ root: { backgroundColor: 'white', color: 'gray' } }}>
                            {props.pro ?
                                'Du bezahlst für diese Personen ab sofort keine Mitgliedergebühr mehr.' :
                                'Es wird eine anteilige Mitgliedergebühr berechnet. Teammitglieder können jederzeit wieder entfert werden.'}
                        </MessageBar>
                    </div>
                }
            </Stack>
            <DialogFooter>
                {props.teamDifference && (!props.pro ?
                    <PrimaryButton
                        text={"Auf PRO wechseln"}
                        onClick={() => props.upgrade()}
                        allowDisabledFocus
                        styles={{
                            root: {
                                borderRadius: 50
                            }
                        }} />
                    :
                    <DefaultButton
                        text={"PRO entfernen"}
                        onClick={() => props.downgrade()}
                        allowDisabledFocus
                        styles={{
                            root: {
                                borderRadius: 50
                            }
                        }} />
                )}
            </DialogFooter>
        </Dialog>
    );

}

export default ProUpgrade