import { CommandButton } from 'office-ui-fabric-react/lib/Button';
import {
    DetailsList,
    SelectionMode
} from 'office-ui-fabric-react/lib/DetailsList';
import { Persona, PersonaSize } from 'office-ui-fabric-react/lib/Persona';
import { TeachingBubble } from 'office-ui-fabric-react/lib/TeachingBubble';
import { Text } from 'office-ui-fabric-react/lib/Text';
import React, { useState } from 'react';
import { DataServices } from '../../shared/data-services/DataServices';

const Team = (props) => {


    const [toggleInfo, setToggleInfo] = useState(false);
    const activityContacts = props.activitiyContacts;

    const activityItems = [];
    activityContacts.forEach(activity => {
        activity.contacts.items.forEach(contact => {
            const activityItem = {};
            activityItem.contactId = contact.id;
            activityItem.contactName = contact.name;
            activityItem.contactAvatarUrl = DataServices.ContactsService.getAvatar(contact.id, contact.name);
            activityItem.contactEmail = JSON.parse(contact.appendix).email;
            activityItem.contactStatus = "aktiv";
            activityItem.id = activity.id;
            activityItem.name = activity.name;
            activityItems.push(activityItem);
        });
        activity.contacts.invitations.forEach(contact => {
            const activityItem = {};
            activityItem.contactId = contact.parentId;
            activityItem.contactEmail = contact.parentId.replace("email_", "");
            activityItem.contactStatus = "eingeladen";
            activityItem.id = activity.id;
            activityItem.name = activity.name;
            activityItems.push(activityItem);
        });
    });
    activityItems.sort((a, b) => a.contactId.localeCompare(b.contactId));
    console.log(activityItems)

    const _items = [];
    activityItems.forEach(a =>
        _items.push({
            key: a.id + a.contactId,
            name: a.name,
            value: a.contactStatus,
        })
    );
    console.log(_items)

    // groups
    const _groups = [];
    var currentContactId = null;
    for (var i = 0; i < activityItems.length; i++) {
        const item = activityItems[i];
        if (currentContactId == item.contactId) {
            // do not add group

        } else {
            // add group
            currentContactId = item.contactId;
            _groups.push({
                avatarUrl: item.contactAvatarUrl,
                key: item.contactId,
                name: item.contactName,
                email: item.contactEmail,
                startIndex: i,
                count: activityItems.filter(i => i.contactId == item.contactId).length,
                level: 0,
                isCollapsed: true
            });
        }
    }

    const _columns = [
        { key: 'name', name: 'Teammitglieder', fieldName: 'name', minWidth: 100, maxWidth: 200, isResizable: true },
        { key: 'value', name: '', fieldName: 'value', maxWidth: 200, isResizable: true },
    ];

    const _onRenderGroupHeader = props => {
        if (props) {
            return (
                <Persona
                    styles={{ root: { 
                        padding: 24
                    } }}
                    text={props.group.name}
                    secondaryText={props.group.email}
                    size={PersonaSize.size32}
                    showSecondaryText={true}
                    imageUrl={props.group.avatarUrl}
                    onClick={() => props.onToggleCollapse(props.group)}
                />
            );
        }
    
        return null;
    };

    return (
        <div>

            {toggleInfo && (
                <TeachingBubble
                    target="#targetButton"
                    onDismiss={() => setToggleInfo(false)}
                    headline="Alle Mitglieder deiner PRO Aktivitäten gehören zu deinem Team!"
                >
                    Du kannst also ein Team bilden, indem du in einer PRO Aktivität Leute hinzufügst oder eine Aktivität mit bestehenden Mitgliedern auf PRO setzt.
                </TeachingBubble>
            )}
            <Text styles={{ root: {} }} variant="medium">
                {}
            </Text>
            <CommandButton
                id="targetButton"
                iconProps={{ iconName: 'Contact' }}
                onClick={() => setToggleInfo(true)}
                text={_groups.length == 0 ? "Keine Teammitglieder" : (_groups.length + " Teammitglieder aus " + activityContacts.length + " Aktivitäten")}
                styles={{
                    root: {
                        fontWeight: 500,
                        paddingLeft: 50,
                        paddingBottom: 30
                    }
                }}
            />
            <DetailsList
                styles={{
                    root: {
                        maxWidth: '100vw'
                    }
                }}
                items={_items}
                groups={_groups}
                groupProps={{
                    onRenderHeader: _onRenderGroupHeader,
                }}
                columns={_columns}
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                ariaLabelForSelectionColumn="Toggle selection"
                checkButtonAriaLabel="Row checkbox"
                compact={true}
                isHeaderVisible={false}
                selectionMode={SelectionMode.none}
            />
        </div>
    );

}


export default Team