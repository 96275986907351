import React, { useState, useEffect } from 'react';
import {
  useHistory,
  useParams
} from "react-router-dom";
import { Link } from 'office-ui-fabric-react/lib/Link';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { CommandBar, ICommandBarStyles } from 'office-ui-fabric-react/lib/CommandBar';
import { Announced } from 'office-ui-fabric-react/lib/Announced';
import {
  IContextualMenuProps,
  IContextualMenuItem,
  DirectionalHint,
  ContextualMenu,
} from 'office-ui-fabric-react/lib/ContextualMenu';
import {
  CheckboxVisibility,
  ColumnActionsMode,
  ConstrainMode,
  DetailsList,
  DetailsRow,
  DetailsRowFields,
  DetailsListLayoutMode,
  IColumn,
  IGroup,
  Selection,
  SelectionMode,
  buildColumns,
  IDetailsColumnProps,
} from 'office-ui-fabric-react/lib/DetailsList';
import { createListItems, isGroupable, IExampleItem } from '@uifabric/example-data';
import { memoizeFunction } from 'office-ui-fabric-react/lib/Utilities';
import { getTheme, mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import { Icon } from '@fluentui/react/lib/Icon';
import moment from 'moment';
import 'moment/locale/de'  // without this line it didn't work
import { DataServices } from "../../shared/data-services/DataServices";
import awsconfig from '../../shared/data-services/aws-exports';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { Persona, PersonaSize, PersonaPresence } from "office-ui-fabric-react/lib/Persona";
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import CardInput from './CardInput';
import { prettyDate } from '../../shared/Date';
import { IdealBankElement } from '@stripe/react-stripe-js';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import Linkify from './Linkify';

const theme = getTheme();
const headerDividerClass = 'DetailsListAdvancedExample-divider';
const classNames = mergeStyleSets({
  headerDivider: {
    display: 'inline-block',
    height: '100%',
  },
  headerDividerBar: [
    {
      display: 'none',
      background: theme.palette.themePrimary,
      position: 'absolute',
      top: 16,
      bottom: 0,
      width: '1px',
      zIndex: 5,
    },
    headerDividerClass,
  ],
  linkField: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  },
  root: {
    selectors: {
      [`.${headerDividerClass}:hover + .${headerDividerClass}`]: {
        display: 'inline',
      },
    },
  },
});

const commandBarStyles = { root: { marginBottom: '40px' } };

const DEFAULT_ITEM_LIMIT = 5;
const PAGING_SIZE = 10;
const PAGING_DELAY = 2000;
const ITEMS_COUNT = 5000;

var allItems = [];




const DetailsListAdvancedExample = (props) => {

  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const searchParam = params.get("search");
  const [items, setItems] = useState([]);
  var searchText = searchParam ? searchParam : '';

  const _isFetchingItems = null;

  let { id } = useParams();
  const activityId = history.location.pathname.substring(
    history.location.pathname.lastIndexOf('/') + 1);

  useEffect(() => {
    listen();
  }, []);

  const toUrl = (itemId) => {
    history.push({
        pathname: itemId,
        search: history.location.search ? history.location.search : ''
    })
  }

  const listen = async () => {
    history.listen(async (location, action) => {

      const params = new URLSearchParams(location.search);
      const search = params.get("search");
      console.log(search)
      searchText = search;
      setItems(search ?
        allItems.filter(i => {
          return search.trim().split(/[\s,]+/).some(w => i.search?.toLowerCase().includes(w.toLowerCase()))
        })
        : allItems);

    });
  }

  const fetchData = async () => {

    console.log(history)

    const activityId = history.location.pathname.substring(
      history.location.pathname.lastIndexOf('/') + 1);

    console.log(activityId)

    const result = await DataServices.CardsService
      .getCardsForActivity(activityId);
    const stacks = await DataServices.StacksService
      .getStacksForActivity(activityId);
    const contacts = await DataServices.ContactsService
      .getContactsForActivity(activityId);

    console.log(result)

    const _items = [];
    result.forEach(a => {
      var news = a.news;
      if (!news) news = a.content && a.content.length > 50 ? (a.content.substring(0, 50) + '...') : a.content;
      const assignee = contacts.find(c => c.id == a.assigneeId);

      _items.push({
        key: a.id,
        id: a.id,
        name: a.name,
        content: news,
        news: a.news,
        value: a.createdAt,
        counterId: a.counterId,
        updatedAt: a.updatedAt,
        files: a.files,
        location: a.location,
        open: a.open,
        stack: stacks.find(s => s.id == a.stackId)?.name,
        assignee: assignee?.email,
        assigneeId: a.assigneeId,
        avatarUrl: DataServices.ContactsService.getAvatar(assignee?.id, assignee?.updatedAt),
        unread: !a.viewedByMe,
        //unread: a.assigneeId && !a.viewedByAssignee && a.assigneeId == props.profile.id,
        unreadByAssignee: a.assigneeId && !a.viewedByAssignee,
        search: a.search
      });
    });

    _selection.setItems(_items, true);
    _selection.setAllSelected(false);
    /*     _selection.setAllSelected(false);
        _selection.setItems(_items, true);
        _items.forEach((i, index) => {
          if (!i.open) _selection.setKeySelected(i.key, true, false);
        });
        _selection.setChangeEvents(true, true); */
    setItems(searchText ?
      _items.filter(i => {
        return i.search?.toLowerCase().includes(searchText.toLowerCase())
      })
      : _items);
    allItems = _items;
  }

  useEffect(() => {
    //setItems([]);
    fetchData();
  }, [activityId]);

  const classNames = mergeStyleSets({
    fileIconCell: {
      textAlign: 'center',
      margin: 'auto',
      selectors: {
        '&:before': {
          content: '.',
          display: 'inline-block',
          verticalAlign: 'middle',
          height: '100%',
          width: '0px',
          visibility: 'hidden',
        },
      },
    },
    fileIconImg: {
      verticalAlign: 'middle',
      maxHeight: '16px',
      maxWidth: '16px',
      fontSize: 16
    },
  });


  const _columns = [
    {
      key: 'content',
      name: 'Beschreibung',
      fieldName: 'content',
      minWidth: 150,
      maxWidth: 250,
      isResizable: true,
      isRowHeader: true,
      isResizable: true,
      isMultiline: true,
      isSorted: true,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      onColumnClick: () => { },
      data: 'string',
      isPadded: false,
    },
    {
      key: 'info',
      name: 'Id',
      fieldName: 'info',
      minWidth: 50,
      isMultiline: true,
      isResizable: false,
      isRowHeader: true,
      isSorted: true,
      isSortedDescending: false,
      onColumnClick: () => { },
      data: 'string',
      isPadded: false
    },
    {
      key: 'column1',
      name: 'File Type',
      className: classNames.fileIconCell,
      iconClassName: classNames.fileIconHeaderIcon,
      ariaLabel: 'Column operations for File type, Press to sort on File type',
      iconName: 'Page',
      isIconOnly: true,
      fieldName: 'action',
      minWidth: 24,
      maxWidth: 24,
      isPadded: false,
      isResizable: true,
      onRender: (item) => (
        <div>
          <TooltipHost content={item.assignee + (item.unreadByAssignee ? ', zugewiesen' : ', gesehen')}>
            {item.assignee &&
              <Persona
                text={item.assignee}
                size={PersonaSize.size24}
                presence={item.unreadByAssignee ? PersonaPresence.offline : PersonaPresence.none}
                styles={{
                  root: {
                    height: 'auto'
                  }
                }}
                imageUrl={item.avatarUrl}
              />}
          </TooltipHost>
        </div>
      ),
    }
  ];




  const _onItemInvoked = (item) => {
    toUrl(item.key)
  }

  const _onItemsSelectionChanged = async () => {

    const item = _selection.getSelection()[0];
    if (item) {
      for (let i of items) {
        if (i.id == item.key) {
          item.open = !item.open;
        }
      }
      // setItems(items);
      // _selection.setItems(items, true)
      const result = await DataServices.CardsService.openCard(
        item.key, !item.open);
      fetchData();
    }

  };

  const _selection = new Selection({
    onSelectionChanged: _onItemsSelectionChanged,
    items,
  });

  /*   const _onDeleteRow = () => {
      if (_selection.getSelectedCount() > 0) {
        setItems((previousState) => {
          return {
            items: previousState.items.filter((item, index) => !_selection.isIndexSelected(index)),
          };
        });
      } else {
        setItems({
          items: this.state.items.slice(1),
        });
      }
    }; */

  /*   const _onAddRow = () => {
   
    }; */

  /*   const _getCommandItems = () => {
      return [
        {
          key: 'addRow',
          text: 'Insert row',
          iconProps: { iconName: 'Add' },
          onClick: _onAddRow,
        },
        {
          key: 'deleteRow',
          text: 'Delete row',
          iconProps: { iconName: 'Delete' },
          onClick: _onDeleteRow,
        },
        {
          key: 'configure',
          text: 'Configure',
          iconProps: { iconName: 'Settings' },
          subMenuProps: {
            items: [],
          },
        },
      ];
    }; */

  const onRenderItemColumn = (item, index, column) => {
    const fieldContent = item[column.fieldName];

    switch (column.key) {
      case 'info':
        return <span
          data-selection-disabled={true}
          style={{
            display: 'block',
            textAlign: 'right',
          }}
        >
          <div>
            {item.files && <Icon iconName="Attach" />}
            {item.location && <Icon iconName="POISolid" />}
            {' #'}
            {item.counterId}
          </div>
          <div>
            {moment(item.updatedAt).format('LL')}
            {', '}
            {moment(item.updatedAt).format('LT')}
          </div>
          <div>
            <b>{item.stack}</b>
          </div>
        </span>;

      case 'content':
        return <span
          style={{
            textDecoration: !item.open ? 'line-through' : 'undefined'
          }}
        >
          <p
            style={{
              fontWeight: item.unread ? 'bold' : 'normal',
              color: item.name ? 'black' : 'lightgray'
            }}>
            <Linkify value={item.name ? item.name : "Titel"} />
          </p>
          {item.news && <Icon
            iconName={"ChatSolid"}
            styles={{
              root: {
                width: 20,
                color: item.unread ? '#3771C8' : 'lightgray',
                fontSize: 'smaller',
                float: 'left',
                marginTop: 5
              }
            }}
          />}
          <div>{fieldContent}</div>
        </span>;

      default:
        return <span>{fieldContent}</span>;
    }

  }

  const _onRenderRow = (props) => {

    props.onRenderDetailsCheckbox = (propsCheck) => {
      return onRenderCheckbox(propsCheck, props.item.open);
    };

    const customStyles = {};
    if (!props.item.open) {
      customStyles.root = {
        filter: 'grayscale(1) opacity(0.5)'
      };
    }
    return <DetailsRow
      rowFieldsAs={renderRowFields}
      {...props}
      styles={customStyles}
    />;
  }

  const renderRowFields = (props) => {
    return (
      <span
        onClick={() => toUrl(props.item.key)}
        data-selection-disabled={true}>
        <DetailsRowFields

          {...props}
        />
      </span>
    );
  }
  const overrideColorForSelectedRow = '#FF00FF';

  const stylesForRow = detailsRowStyleProps => {
    const rootStyle = {
      color: detailsRowStyleProps.isSelected ? '#000000' : undefined,
      selectors: {
        ':hover': {
          background: '#3F3F40',
          color: '#FFFFFF',
          selectors: detailsRowStyleProps.isSelected ? overrideColorForSelectedRow : undefined
        },
        ':focus': {
          background: '#FFAACC',
          color: '#FFFFFF',
          selectors: detailsRowStyleProps.isSelected ? overrideColorForSelectedRow : undefined
        },
        ':focus:hover': {
          background: '#007ACC',
          color: '#FFFFFF',
          selectors: detailsRowStyleProps.isSelected ? overrideColorForSelectedRow : undefined
        }
      }
    };

    return { root: rootStyle };
  };

  const deleteCard = async item => {
    await DataServices.CardsService.deleteCard(item.id);
    fetchData();
  }

  const onRenderCheckbox = (props, open) => {
    return (
      <div style={{}}>
        <TooltipHost content={open ? 'Erledigen' : 'Öffnen'}>
          <Checkbox
            indeterminate={!open}
            checked={props.checked}
            styles={{
              checkbox: {
                borderRadius: 20,
                borderColor: 'gray'
              }
            }}
          />
        </TooltipHost>
      </div>
    );
  }

  return (
    <div className={classNames.root}>
      {/*       <CommandBar
        styles={commandBarStyles}
        items={_getCommandItems()}
      /> */}
      <CardInput
        activityId={activityId}
        fetchData={() => fetchData()}
      />
      <DetailsList
        setKey="items"
        items={items}
        selection={_selection}
        columns={_columns}
        checkboxVisibility={CheckboxVisibility.always}
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible={false}
        selectionMode={SelectionMode.single}
        constrainMode={ConstrainMode.horizontalConstrained}
        enterModalSelectionOnTouch={false}
        /*         selectionZoneProps={{
                  selection: _selection,
                  disableAutoSelectOnInputElements: true
                }} */
        enableTouchInvocationTarget={false}
        onRenderRow={_onRenderRow}
        onItemInvoked={_onItemInvoked}
        onRenderItemColumn={onRenderItemColumn}
        ariaLabelForListHeader="Column headers. Click to sort."
        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
        ariaLabelForSelectionColumn="Toggle selection"
        checkButtonAriaLabel="select row"
        selectionPreservedOnEmptyClick={true}
      />

    </div>
  );
}



export default DetailsListAdvancedExample
