import { Image } from '@fluentui/react/lib/Image';
import React, { useEffect, useRef } from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { DataServices } from "../../shared/data-services/DataServices";
import "./image-gallery-custom.css";
import { useParams } from "react-router-dom";
import ImageLoader from './ImageLoader';

const ImageGalleryWrapper = (props) => {

    let { id } = useParams();
    const [images, setImages] = React.useState([]);


    useEffect(() => {
        loadFiles()
    }, [props.signedFiles]);

    const loadFiles = async () => {

        const initialImages = [];
        for (let file of props.files) {
            initialImages.push({ original: file.key, url: '' });
        }
        setImages(initialImages);
        if (props.signedFiles) {
            for (var i = 0; i < initialImages.length; i++) {
                initialImages[i].url = props.signedFiles.find(f => f.key == initialImages[i].original)?.signedUrl;
            }
            setImages(initialImages);
        }

    }

    const renderItem = (item) => {
        return <ImageLoader
            minHeight={300}
            file={item.url}
        />
    }

    const Gallery = () => {
        return (

            <ImageGallery
                items={images}
                showPlayButton={false}
                showThumbnails={false}
                showFullscreenButton={false}
                //onScreenChange={(fullscreen) => { if (!fullscreen) props.reset() }}
                showIndex={true}
                showBullets={false}
                renderItem={renderItem}
                lazyLoad={true}
                show
                showIndex={true}
            >
            </ImageGallery>
        );
    }

    return <Gallery />


}


export default ImageGalleryWrapper