import { mergeStyles } from '@fluentui/react/lib/Styling';
import { initializeIcons } from '@uifabric/icons';
import { Card } from '@uifabric/react-cards';
import { API, graphqlOperation } from 'aws-amplify';
import { CommandBarButton } from 'office-ui-fabric-react';
import { Persona, PersonaSize } from 'office-ui-fabric-react/lib/Persona';
import { Separator } from 'office-ui-fabric-react/lib/Separator';
import { Text } from 'office-ui-fabric-react/lib/Text';
import * as React from "react";
import {
    getProfile, listContacts, listTeamActivities, updateActivityToFree, updateActivityToPremium
} from "../../shared/data-services/graphql";
import ProUpgrade from "./WebAppProUpgrade";
import Stripe from "./WebAppStripe";
import Team from "./WebAppTeam";
import Dropzone from "./Dropzone";
import { DataServices } from "../../shared/data-services/DataServices";
import { Link } from '@fluentui/react';
import TextFieldDialog from "./TextFieldDialog";

initializeIcons();

const buttonStyles = { root: { marginRight: 8 } };

const customCoinClass = mergeStyles({
    borderRadius: 20,
    display: 'block',
});

export default class Premium extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            name: props.profile.name,
            loading: true,
            company: false,
            hidden: true,
            teamDifference: null,
            showUpgradeDialog: false,
            teamActivities: [],
            avatarUrl: DataServices.ContactsService.getMyAvatar(),
            editName: false
        };
        this.loadTeamActivities();

    }




    hideUpgrade = () => this.setState({ showUpgradeDialog: false, teamDifference: null });
    showUpgrade = () => {
        this.setState({ showUpgradeDialog: true });
        this.loadTeamActivities();
    }

    upgradePremium = async () => {
        try {
            this.hideUpgrade();
            const result = await API.graphql(graphqlOperation(updateActivityToPremium, { activityId: this.props.activityToUpgrade.id }));
            this.props.onUpdate();
        } catch (e) {
            console.log(e);
        }
    }

    downgradePremium = async () => {
        try {
            this.hideUpgrade();
            const result = await API.graphql(graphqlOperation(updateActivityToFree, { activityId: this.props.activityToUpgrade.id }));
            this.props.onUpdate();
        } catch (e) {
            console.log(e);
        }
    }



    loadTeamActivities = async () => {
        try {
            const resultContacts = await API.graphql(graphqlOperation(listTeamActivities));
            if (resultContacts && resultContacts.data.listTeamActivities) {
                const teamActivities = resultContacts.data.listTeamActivities.items;
                this.setState({ teamActivities });
                console.log(teamActivities);
                if (this.props.activityToUpgrade) this.loadActivityContacts(teamActivities);
            }
        } catch (e) {
            console.log(e);
        };
    }

    loadActivityContacts = async (teamActivities) => {
        try {
            const resultContacts = await API.graphql(graphqlOperation(listContacts, {
                activityId: this.props.activityToUpgrade.id
            }));
            if (resultContacts && resultContacts.data.listContacts) {
                const activitiyContacts = [...resultContacts.data.listContacts.items,
                ...resultContacts.data.listContacts.invitations];
                this.contactDifference(activitiyContacts, teamActivities)
            }
        } catch (e) {
            console.log(e);
        };
    }

    contactDifference = async (activitiyContacts, currentTeamActivities) => {
        const currentContacts = [];
        currentTeamActivities.forEach(act => {
            act.contacts.items.forEach(contact => currentContacts.push(contact));
            act.contacts.invitations.forEach(contact => currentContacts.push(contact));
        });

        var teamDifference = [];
        if (!this.props.activityToUpgrade.pro)
            teamDifference = activitiyContacts.filter(actContact =>
                !currentContacts.some(c => actContact.id == c.id));
        else
            teamDifference = activitiyContacts.filter(actContact =>
                currentContacts.filter(c => actContact.id == c.id).length == 1);

        console.log("teamDifference, currentContacts", teamDifference, currentContacts);

        this.setState({ teamDifference });

    }

    openPanel = async () => { this.setState({ hidden: false }) }
    dismissPanel = async () => { this.setState({ hidden: true }) }



    _onRenderCoin = (props) => {
        const { coinSize, imageAlt, imageUrl } = props;
        return <img
            {...props}
            onClick={() => { }}
            src={imageUrl}
            alt={imageAlt}
            width={coinSize}
            height={coinSize}
            className={customCoinClass} />;
    }

    render() {

        if (this.props.activityToUpgrade) {

            const isProActivity = this.props.activityToUpgrade.pro;
            const isRootActivity = this.props.activityToUpgrade.type == "ROOT";
            const createdByMe = this.props.activityToUpgrade.createdBy == this.props.profile.id;
            const createdByPro = isRootActivity ? false : this.props.activityToUpgrade.createdByContact.pro;

            if (this.state.teamDifference)
                var personas = this.state.teamDifference.map(contact => {
                    var invitation = contact.id.startsWith('email');
                    var email = "";
                    if (!invitation)
                        email = JSON.parse(contact.appendix).email;
                    else
                        email = contact.parentId.replace('email_', '')
                    return { personaName: email }
                });

            return (
                <div>
                    {isProActivity && !isRootActivity &&
                        <CommandBarButton
                            text={createdByPro ? 'PRO' : 'PRO inaktiv'}
                            iconProps={createdByMe && { iconName: 'Edit' }}
                            onClick={() => this.showUpgrade()}
                            disabled={!createdByMe}
                            styles={{
                                label: {
                                    //fontWeight: 'bold'
                                },
                                root: {
                                    minHeight: 28,
                                    //backgroundColor: '#fafafa',
                                    //borderRadius: 20,
                                }
                            }} />
                    }
                    {!isProActivity && !isRootActivity &&
                        <CommandBarButton
                            text={createdByMe ? 'Auf PRO wechseln' : ''}
                            iconProps={createdByMe && { iconName: 'Edit' }}
                            disabled={!createdByMe}
                            onClick={() => this.showUpgrade()}
                            styles={{
                                root: {
                                    minHeight: 28,
                                    //backgroundColor: '#fafafa',
                                    //borderRadius: 20,
                                },
                                label: {
                                    //fontWeight: 600
                                }
                            }} />
                    }
                    <ProUpgrade
                        personas={personas}
                        pro={isProActivity}
                        show={this.state.showUpgradeDialog}
                        onHide={this.hideUpgrade}
                        teamDifference={this.state.teamDifference}
                        upgrade={this.upgradePremium}
                        downgrade={this.downgradePremium}
                    />
                </div>
            )
        }

        return (
            <Card
                vertical
                tokens={{ childrenGap: 30, width: '100%', maxWidth: '100%' }}
                styles={{
                    root: {
                        height: '100%',
                        backgroundColor: 'white',
                        overflowY: 'scroll'
                    }
                }}>
                <Card.Item styles={{ root: { paddingLeft: 50, paddingRight: 50, paddingTop: 50 } }}>
                    <Text block variant="xxLarge">
                        {"Mein Konto"}
                    </Text>
                </Card.Item>
                <Card.Item styles={{ root: { paddingLeft: 50 } }}>
                    <Persona
                        text={this.state.name}
                        size={PersonaSize.size72}
                        secondaryText={
                            this.props.profile.email ? this.props.profile.email : ''}
                        tertiaryText={
                            <div>
                                <Link onClick={() => this.setState({ editName: true })}>Name ändern</Link>
                                <Dropzone
                                    maxWidth={128}
                                    text='Bild ändern'
                                    onLoad={async (file) => {
                                        const result = await DataServices.ContactsService.updateAvatar(this.props.profile.id, file);
                                        await DataServices.ContactsService.getProfile();
                                        this.setState({ avatarUrl: DataServices.ContactsService.getMyAvatar() })
                                    }} />
                            </div>
                        }
                        imageUrl={this.state.avatarUrl}
                    />
                    <TextFieldDialog
                        show={this.state.editName}
                        defaultValue={this.props.profile.name}
                        title={'Profilname ändern'}
                        onSave={async (text) => {
                            this.setState({ editName: false, name: text });
                            const result = await DataServices.ContactsService.updateProfile(text, null);
                        }}
                        onDismiss={() => this.setState({ editName: false })}
                    />
                </Card.Item>
                <Separator />
                <Card.Item styles={{ root: { paddingLeft: 50, paddingRight: 50 } }}>
                    <Text block variant="xLarge" styles={{ root: { paddingBottom: 30 } }}>
                        {"Flinker PRO"}
                    </Text>
                    <Stripe profile={this.props.profile} />
                </Card.Item>
                <Separator />
                <Card.Item>
                    <Text
                        block
                        variant="xLarge"
                        styles={{ root: { paddingLeft: 50, paddingRight: 50, paddingBottom: 30 } }}>
                        {"Team"}
                    </Text>
                    <Team activitiyContacts={this.state.teamActivities}></Team>
                </Card.Item>
            </Card >
        );
    }
}