import React from 'react';
import Linkify from 'react-linkify';



const LinkifyWrapper = (props) => {


    var truncate = function (fullStr, strLen, separator) {
        if (fullStr.length <= strLen) return fullStr;

        // Remove protocol http...
        fullStr = fullStr.replace(/(^\w+:|^)\/\//, '');

        separator = separator || '...';

        var sepLen = separator.length,
            charsToShow = strLen - sepLen,
            frontChars = Math.ceil(charsToShow / 2),
            backChars = Math.floor(charsToShow / 2);

        return fullStr.substr(0, frontChars) +
            separator +
            fullStr.substr(fullStr.length - backChars);
    };

    return <Linkify
        componentDecorator={(decoratedHref, decoratedText, key) => (
            <a
                style={{
                    //width: '300px',
                    //textOverflow: 'ellipsis',
                    //display: 'block',
                    //overflow: 'hidden',
                    //height: 20,
                    //lineBreak: 'anywhere',
                    color: '#3771c8'
                }}
                target="blank"
                href={decoratedHref}
                key={key}>
                {truncate(decoratedText, 50)}
            </a>
        )}>
        {props.value}
    </Linkify>
}

export default LinkifyWrapper