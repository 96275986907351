import { Persona, PersonaSize } from "office-ui-fabric-react/lib/Persona";
import { Separator } from "office-ui-fabric-react/lib/Separator";
import { Link } from "react-router-dom";
import { CommandBarButton, ActionButton } from "office-ui-fabric-react";
import Activities from "./WebAppActivities";
import { Panel } from "office-ui-fabric-react/lib/Panel";
import React from "react";
import { propStyle } from "aws-amplify-react";
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './data-services/aws-exports';
import { DataServices } from "./data-services/DataServices";
import { Scrollbars } from 'react-custom-scrollbars';
import { useHistory } from "react-router-dom";

function ActivitiesPanel({ web, activities, name, email, profileId, selectActivityById, selectPremium, visible, hide, onCreate, onAccept, onSignOut, onLoad }) {

    return (
        <>
            <Panel
                isBlocking={false}
                isHiddenOnDismiss={true}
                headerText=""
                isOpen={visible}
                onDismiss={() => hide()}
                closeButtonAriaLabel="Close"
                customWidth={'300px'}
                type={8}
                styles={{
                    scrollableContent: {
                        //overflow: "visible"
                        height: '100%',
                    },
                    content: {
                        //overflow: "visible",
                        padding: 0,
                        height: '100%',
                    },
                    main: {
                        boxShadow: '0 1.6px 3.6px 0 rgba(0,0,0,.132), 0 0.3px 0.9px 0 rgba(0,0,0,.108)'
                    }
                }}
            >
                <Scrollbars autoheight>
                    <Persona
                        text={name}
                        size={PersonaSize.size40}
                        secondaryText={email ?? ''}
                        optionalText={''}
                        styles={{
                            root: {
                                paddingLeft: 20
                            }
                        }}
                        imageUrl={DataServices.ContactsService.getMyAvatar()}
                    />
                    <Link to="/">
                        <ActionButton
                            text="Mein Konto"
                            onClick={selectPremium}
                            styles={{
                                root: {
                                    height: 24,
                                    marginLeft: 70,
                                    paddingLeft: 0,
                                    display: 'block',
                                    textDecoration: 'none'
                                },
                                label: {
                                    marginLeft: 0
                                }
                            }} />
                    </Link>
                    <ActionButton
                        text="Abmelden"
                        onClick={() => onSignOut()}
                        styles={{
                            root: {
                                height: 24,
                                marginLeft: 70,
                                paddingLeft: 0
                            },
                            label: {
                                marginLeft: 0
                            }
                        }}
                    />
                    <Separator />
                    <ActionButton
                        text={'Aktivitäten'}
                        iconProps={{ iconName: 'Sync' }}
                        onClick={() => onLoad()}
                        styles={{
                            root: {
                                height: 24,
                                marginLeft: 70,
                                paddingLeft: 0
                            },
                            label: {
                                marginLeft: 0
                            }
                        }}
                    />
                    <Activities
                        web={web}
                        activities={activities}
                        profileId={profileId}
                        onCreate={onCreate}
                        onAccept={onAccept}
                        onClick={selectActivityById}
                    />
                </Scrollbars>
            </Panel>
        </>
    );
}

export default ActivitiesPanel;
