import { PrimaryButton, TextField } from 'office-ui-fabric-react';
import { Dialog, DialogFooter, DialogType } from 'office-ui-fabric-react/lib/Dialog';
import React, { useState } from 'react';

const CustomDialog = (props) => {

    const [text, setText] = useState(props.defaultValue);

    return <Dialog
        hidden={!props.show}
        onDismiss={() => props.onDismiss()}
        dialogContentProps={{
            type: DialogType.normal,
            title: props.title ? props.title : '',
            closeButtonAriaLabel: 'Close'
        }}>
        <TextField
            defaultValue={text}
            onChange={(ev, t) => setText(t || "")} />
        <DialogFooter>
            <PrimaryButton
                onClick={() => props.onSave(text)}
                text="Speichern"
                styles={{
                    root: {
                        borderRadius: 20
                    }
                }} />
        </DialogFooter>
    </Dialog >
}

export default CustomDialog