import React, { useState, useEffect } from 'react';
import { Icon, Image, Stack, Text, CommandBarButton, IconButton } from 'office-ui-fabric-react'
import flinkerLogo from "./images/logo512.png";

const WebAppLoading = (props) => {

    /*     const [contacts, setContacts] = useState([]);
        const [invitations, setInvitations] = useState([]);
        const [showContacts, setShowContacts] = useState(false); */

    useEffect(() => {

    }, []);

    return (
        <Image
            src={flinkerLogo}
            alt="Flinker"
            width={48}
            height={48}
            styles={{ root: { margin: 'auto' } }}
        />
    );

}

export default WebAppLoading;