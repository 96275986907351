import { ActivityItem, Link } from '@fluentui/react';
import moment from "moment";
import React, { useEffect } from 'react';
import {
    useHistory,
    useParams
} from "react-router-dom";
import { toast, ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DataServices } from "../../shared/data-services/DataServices";

export const Toasts = (props) => {

    let { id } = useParams();

    useEffect(() => {
        if (props.comment && props.profile && (props.comment?.contact?.id != props.profile?.id) &&
            id != props.comment.dataId) {
            createToast();
        }
    }, [props.comment]);

    const history = useHistory();

    const Msg = ({ comment }) => (
        <Link onClick={() => history.push('/' + props.comment.dataId)}>
            <ActivityItem
                key={1}
                activityDescription={[
                    <span key={1} >{comment.contact.name}</span>,
                    <span key={2} >, </span>,
                    <span key={3} >{comment.contact ? JSON.parse(comment.contact.appendix).email : ''}</span>,
                ]}
                activityPersonas={[{ imageUrl: DataServices.ContactsService.getAvatar(comment.createdBy, '') }]}
                comments={comment.content}
                timeStamp={moment(comment.createdAt).format('LT')}
                //className={classNames.exampleRoot}
                styles={{
                    root: {
                        width: '100%'
                    }
                }}
            />
        </Link>
    )

    const createToast = () => {
        if (props.comment) toast(<Msg comment={props.comment} />);
    }

    return (
        <ToastContainer
            position="top-right"
            //autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={true}
            rtl={false}
            limit={2}
            transition={Zoom}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
        />
    );

};

