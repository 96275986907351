import { API, Auth, graphqlOperation } from 'aws-amplify';
import { IconButton, PrimaryButton } from 'office-ui-fabric-react';
import 'office-ui-fabric-react/dist/css/fabric.css';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  BrowserRouter as Router, Route, Switch
} from "react-router-dom";
import ActivitiesPanel from "../../shared/ActivitiesPanel";
import { bootstrapDataServices, DataServices } from "../../shared/data-services/DataServices";
import Loading from "../../shared/Loading";
import './App.css';
import Card from "./Card";
import Map from "./Map";
import { Toasts } from './Notifications';
import Activity from "./WebAppActivity";
import Premium from "./WebAppPremium";
import Appstream from "./Appstream";
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { Cache } from 'aws-amplify';

bootstrapDataServices({ cardLimit: 200 });

var subscribing = false;

const App = (props) => {

  const handleMediaQueryChange = (matches) => {
    // matches will be true or false based on the value for the media query
    if (matches) setShowMap(false);
  }

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const isRoot = window.location.pathname == "/";
  const isStream = window.location.search.includes("applicationId");

  const [profile, setProfile] = useState(null);
  const [activities, setActivities] = useState([]);
  const [showPanel, setShowPanel] = useState((isTabletOrMobile || !isRoot) ? false : true);
  const [showMap, setShowMap] = useState(isTabletOrMobile && isStream);
  const [comment, setComment] = useState(null);
  const [showContent, setShowContent] = useState(isStream && !isTabletOrMobile ? false : true);
  const [appstreamCounter, updateAppstream] = useState(0);

  useEffect(() => {
    loadActivities()
  }, []);


  const onCreateActivity = async (name) => {
    const result = await DataServices.ActivitiesService.createActivityWithName(name);
    loadActivities();
  }

  const acceptInvitation = async (activityId) => {
    await DataServices.ActivitiesService.acceptInvitation(activityId);
    loadActivities();
    //fetchData();
    //props.onUpdate();
  }

  const createSubscriptions = async (act, pro) => {
    if (!subscribing) {
      subscribing = true;
      for (let activity of act) {
        const subscription = await DataServices.ActivitiesService
          .subscribe(activity.id, createNotifications);
      }
    }
  }

  const createNotifications = async (comment) => {
    setComment(comment);
    //loadActivities();
  }


  const loadActivities = async () => {
    try {

      //  const currentSession = await Auth.currentSession();
      // const currentAuthenticatedUser = await Auth.currentAuthenticatedUser()
      // console.log('currentSession', currentSession)
      // console.log('currentAuthenticatedUser', currentAuthenticatedUser)
/*       const federatedInfo = Cache.getItem('federatedInfo');
      const { token } = federatedInfo;
      console.log('token', token)  */

      const result = await DataServices.ActivitiesService.getRootActivity();
      const resultActivities = await DataServices.ActivitiesService.getAllActivities();
      if (resultActivities && resultActivities.length > 0) {
        setActivities(resultActivities);
      }
      const resultProfile = await DataServices.ContactsService.getProfile();
      const now = new Date();
      if (resultProfile.expiresOn > now.toISOString())
        resultProfile.premium = true;
      var expiresOnDate = new Date(resultProfile.expiresOn);
      resultProfile.expiresOnDate = expiresOnDate.toLocaleDateString();
      setProfile(resultProfile);
      updateAppstream(appstreamCounter + 1);
      createSubscriptions(resultActivities, resultProfile);
    } catch (e) {
      console.log(e);
    }
  }

  const selectPremium = () => {
    if (isTabletOrMobile) setShowPanel(false);
    setShowContent(true);
    //setShowPanel(false);

  }

  const selectActivity = (id) => {
    if (isTabletOrMobile) setShowPanel(false);
    setShowContent(true);
    //setShowPanel(false);
  }

  const renderView = (routeProps) => {
    if (!isTabletOrMobile || showMap) {
      if (routeProps.location.search.includes("applicationId")) {
        return <Appstream
          showContent={showContent}
          update={appstreamCounter} />
      } else {
        return <Map
          fullscreen={showMap}
          showContent={showContent}
          closeMap={() => setShowMap(false)} />
      }
    } else {
      return <div />
    }
  }

  const MapMemo = React.memo(props => { return <Map></Map> });

  return (
    <Stack
      verticalFill
      styles={{
        root: { flex: 'auto', height: '100vh', overflowX: 'hidden', }
      }}>
      {profile
        ?
        <Router>
          <IconButton
            iconProps={{ iconName: 'GlobalNavButton' }}
            onClick={() => setShowPanel(true)}
            styles={{
              root: {
                zIndex: 2,
                border: 'none',
                position: 'absolute',
                borderRadius: 0,
                padding: 25,
                backgroundColor: 'white'

              }
            }} />
          {isTabletOrMobile &&
            <IconButton
              iconProps={{ iconName: showMap ? 'back' : 'Nav2DMapView' }}
              onClick={() => setShowMap(!showMap)}
              styles={{
                root: {
                  zIndex: 2,
                  border: 'none',
                  position: 'absolute',
                  left: 50,
                  top: 0,
                  borderRadius: 0,
                  padding: 25,
                  backgroundColor: 'white'
                }
              }} />
          }
          <Route path={`/:id`}>
            <Toasts comment={comment} profile={profile}></Toasts>

          </Route>

          <ActivitiesPanel
            web={true}
            activities={activities}
            hide={() => setShowPanel(false)}
            profileId={profile.id}
            name={profile.name}
            email={profile.email ?? ""}
            selectActivityById={(id) => selectActivity(id)}
            selectPremium={() => selectPremium()}
            visible={showPanel}
            onCreate={onCreateActivity}
            onAccept={() => { }}
            onLoad={loadActivities}
            onSignOut={() => Auth.signOut()}
          />
          <Stack
            styles={{ root: { height: '100%' } }}
            horizontal
            verticalFill
            tokens={{
              padding: isTabletOrMobile ? 0 : (showPanel ? 0 : 0)
            }}>

            {showPanel && !isTabletOrMobile &&
              <Stack.Item disableShrink styles={{ root: { width: 300 } }}>
                <div></div>
              </Stack.Item>}
            {!showMap && <Stack.Item
              grow
              styles={{
                root: {
                  maxWidth: isTabletOrMobile ? 1024 : 500,
                  position: 'relative',
                  zIndex: 1,
                  height: '100%',
                  flexGrow: showContent ? 1 : 0
                }
              }}>
              {!isTabletOrMobile && <IconButton
                title="Collapse"
                ariaLabel="Collapse"
                iconProps={{ iconName: showContent ? 'ChevronLeft' : 'ChevronRight' }}
                onClick={() => {
                  setShowContent(!showContent);
                }}
                styles={{
                  root: {
                    position: 'absolute',
                    right: 0,
                    left: showContent ? 'unset' : 0,
                    top: 0,
                    bottom: 0,
                    margin: 'auto 0',
                    zIndex: 10,
                    background: '#00000016',
                    width: 20,
                    height: 80
                  }
                }}
              />}
              <Switch >
                <Route
                  path={`/:id`}
                  render={routeProps => {
                    const currentId = routeProps.match.params.id;
                    const isInvitation = activities.some(a => a.id == currentId && a.type == 'INVITATION');
                    if (isInvitation) setShowContent(true);

                    if (routeProps.location.pathname.includes("card_") && showContent) {
                      return <Card
                        profile={profile}
                        onUpdate={loadActivities}
                      />
                    } else if (routeProps.location.pathname.includes("activity_") && showContent) {
                      return <Activity
                        profile={profile}
                        onUpdate={loadActivities}
                        hide={() => {
                          setShowContent(false);
                        }}
                      />
                    } else {
                      return <div></div>
                    }
                  }}
                />
                <Route exact path={""}>
                  {showContent && <Premium profile={profile} />}
                </Route>
              </Switch>
            </Stack.Item>
            }
            <Route
              path={`/:id`}
              render={renderView}
            />
          </Stack>
        </Router>
        :
        <Loading />}
    </Stack >
  );
}

export default App